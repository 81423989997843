import React, { useState, useEffect } from 'react';
import { HStack } from '@chakra-ui/react';
import TriviaSidebar from './TriviaSidebar';
import TriviaQuestion from './TriviaQuestion';
import TriviaEnd from './TriviaEnd';

const TriviaLayout = ({ questions, xp, xpForQuiz, onAnswer, onComplete }) => {
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [score, setScore] = useState(0);
  const [xpEarned, setXpEarned] = useState(0);

  const totalQuestions = questions.length;
  const xpPerQuestion = xpForQuiz / totalQuestions;

  useEffect(() => {
    setXpEarned(score * xpPerQuestion);
  }, [score, xpPerQuestion]);

  if (!questions || questions.length === 0) {
    return <div>No questions available</div>;
  }

  const question = questions[currentQuestion];

  const handleAnswerClick = async (questionId, answer) => {
    const isCorrect = await onAnswer(questionId, answer);
    console.log('isCorrect: Trivia Layout', isCorrect);
    if (isCorrect) {
      setScore(score + 1);
    }
    if (currentQuestion >= totalQuestions - 1) {
      await onComplete();
    } else {
      setCurrentQuestion(currentQuestion + 1);
    }
    return isCorrect;
  };
  

  if (currentQuestion >= totalQuestions) {
    return (
      <TriviaEnd
        totalScore={xpEarned}
        totalQuestions={totalQuestions}
        totalXp={xp}
        correctAnswers={score}
      />
    );
  }

  return (
    <HStack justify="stretch" align="stretch" h="100vh" gap={0}>
      <TriviaSidebar
        currentQuestion={currentQuestion}
        totalXp={xpForQuiz}
        xpEarned={xpEarned}
        totalQuestions={totalQuestions}
      />
      {question && (
        <TriviaQuestion
          question={question}
          answers={question.answers}
          currentQuestion={currentQuestion}
          setCurrentQuestion={setCurrentQuestion}
          setScore={setScore}
          score={score}
          xpPerQuestion={xpPerQuestion}
          totalQuestions={totalQuestions}
          onAnswerClick={handleAnswerClick}
        />
      )}
    </HStack>
  );
};

export default TriviaLayout;
