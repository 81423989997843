import { useState, useEffect, useCallback } from 'react';
import { getAvailableSongs } from '../services/music.service';
import { useUserContext } from '../providers/UserProvider';

const useGetAvailableSongs = () => {
  const { token } = useUserContext();
  const [songs, setSongs] = useState([]);
  const [songsLoading, setSongsLoading] = useState(true);
  const [songsError, setSongsError] = useState(null);

  const fetchSongs = useCallback(async () => {
    if (!token) {
      return;
    }

    setSongsLoading(true);
    setSongsError(null);

    try {
      const data = await getAvailableSongs(token);

      setSongs(data);
    } catch (error) {
      setSongsError(error);
    } finally {
      setSongsLoading(false);
    }
  }, [token]);

  useEffect(() => {
    fetchSongs();
  }, [fetchSongs]);

  return { songs, songsLoading, songsError };
};

export default useGetAvailableSongs;
