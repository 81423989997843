import api from '../utils/api.utils';
import { handleResponse } from '../utils/app.utils';

export const getUserNotifications = async ({ userId, token }) => {
    try {
        const response = await api.get(`/notifications/user/${userId}`, {
            customHeaders: { token },
        });
        return handleResponse(response, 'Error fetching user notifications');
    } catch (error) {
        console.error('Error fetching user notifications:', error.message);
        throw new Error('Failed to fetch user notifications');
    }
};

export const toggleNotificationReadStatus = async ({ notificationId, token }) => {
    try {
        const response = await api.put(`/notifications/${notificationId}/read`, {}, {
            customHeaders: { token },
        });
        return handleResponse(response, 'Error marking notification as read');
    } catch (error) {
        console.error('Error marking notification as read:', error.message);
        throw new Error('Failed to mark notification as read');
    }
};
