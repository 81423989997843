import { useState } from 'react';
import {
  generateInviteCode,
  validateInviteCode,
} from '../services/invite.service';
import { useUserContext } from '../providers/UserProvider';
import { useToast } from '@chakra-ui/react';

const useInvite = () => {
  const toast = useToast();
  const [inviteCode, setInviteCode] = useState('');
  const [maxUses, setMaxUses] = useState(1);
  const [isGenerating, setIsGenerating] = useState(false);
  const [isValidating, setIsValidating] = useState(false);
  const [error, setError] = useState(null);
  const { token } = useUserContext();

  const generateCode = async email => {
    setIsGenerating(true);
    try {
      const response = await generateInviteCode(email, token);
      setInviteCode(response.code);
      setError(null);
      toast({
        title: 'Invite Code Generated',
        description: 'Your invite code has been generated successfully.',
        status: 'success',
        duration: 5000,
        isClosable: true,
      });
    } catch (err) {
      setError(err.message);
      toast({
        title: 'Invite Code Generation Failed',
        description: 'Failed to generate invite code. Please try again.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setIsGenerating(false);
    }
  };

  const validateCode = async code => {
    setIsValidating(true);
    console.log('validateCode', code);
    try {
      const response = await validateInviteCode(code, token);
      if (response.success) {
        setError(null);
        toast({
          title: 'Invite Code Validated',
          description: 'Your invite code has been validated successfully.',
          status: 'success',
          duration: 5000,
          isClosable: true,
        });
        return true;
      } else {
        setError(response.error);
        toast({
          title: 'Validation Error',
          description:
            response.error || 'The invite code is invalid. Please try again.',
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
        return false;
      }
    } catch (err) {
      setError(err.message);
      toast({
        title: 'Validation Error',
        description:
          err.message || 'An unexpected error occurred. Please try again.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
      return false;
    } finally {
      setIsValidating(false);
    }
  };

  return {
    inviteCode,
    maxUses,
    setMaxUses,
    generateCode,
    validateCode,
    isGenerating,
    isValidating,
    error,
  };
};

export default useInvite;
