import React from 'react';
import { Box, Heading, Text, Link } from '@chakra-ui/react';

const TermsOfUse = () => {
  return (
    <Box p={6}>
      <Heading as="h1" size="xl" mb={4}>
        Terms of Use
      </Heading>

      <Text mb={4}>
        Welcome to Epicly AI, Inc. These Terms of Use ("Terms") govern your use
        of our end-to-end guided content creation platform using generative AI.
        By accessing or using our services, you agree to be bound by these
        Terms. If you do not agree to these Terms, please do not use our
        services.
      </Text>

      <Heading as="h2" size="lg" mb={4}>
        1. Acceptance of Terms
      </Heading>
      <Text mb={4}>
        By checking the box during registration, you agree to be bound by these
        Terms and our Privacy Policy. If you do not agree to these Terms, you
        may not use our services.
      </Text>

      <Heading as="h2" size="lg" mb={4}>
        2. Description of Service
      </Heading>
      <Text mb={4}>
        Epicly AI, Inc. provides an end-to-end guided content creation platform
        using generative AI. Our services are designed to assist users in
        creating scripts, audio, music, and visuals. Users must not use our
        services to create hate speech, racism, sexism, calls for physical harm,
        or any illegal content.
      </Text>

      <Heading as="h2" size="lg" mb={4}>
        3. User Responsibilities
      </Heading>
      <Text mb={4}>
        Users are expected to:
        <ul>
          <li>Comply with all applicable laws and regulations.</li>
          <li>
            Not attempt to hack or game the AI into producing results that do
            not conform with the intent of the app.
          </li>
          <li>
            Respect the rights and dignity of others in their use of the
            service.
          </li>
        </ul>
      </Text>

      <Heading as="h2" size="lg" mb={4}>
        4. User Content
      </Heading>
      <Text mb={4}>
        For free plans, users are granted the right to use the content for
        personal use, but Epicly AI retains the copyright to any generated
        scripts, audio, music, and visuals. For paid plans, users retain the
        copyright for all produced media and individual parts, even after they
        no longer subscribe.
      </Text>

      <Heading as="h2" size="lg" mb={4}>
        5. Intellectual Property
      </Heading>
      <Text mb={4}>
        All content and materials available on our platform, including but not
        limited to text, graphics, website name, code, images, and logos are the
        intellectual property of Epicly AI, Inc. and are protected by applicable
        copyright and trademark law. Any unauthorized use of these materials is
        prohibited.
      </Text>

      <Heading as="h2" size="lg" mb={4}>
        6. Privacy
      </Heading>
      <Text mb={4}>
        Please refer to our{' '}
        <Link href="https://app.epicly.ai/privacy-policy" color="teal.500">
          Privacy Policy
        </Link>{' '}
        for information on how we collect, use, and disclose information from
        our users.
      </Text>

      <Heading as="h2" size="lg" mb={4}>
        7. Account Termination
      </Heading>
      <Text mb={4}>
        We reserve the right to terminate or suspend your account if you violate
        these Terms or if we suspect any fraudulent, abusive, or illegal
        activity. Upon termination, your right to use the services will
        immediately cease.
      </Text>

      <Heading as="h2" size="lg" mb={4}>
        8. Disclaimers and Limitation of Liability
      </Heading>
      <Text mb={4}>
        Our services are provided "as is" without any warranties, express or
        implied. Epicly AI, Inc. does not guarantee that the services will be
        available at all times or free from errors. Epicly AI, Inc. will not be
        liable for any damages arising from the use or inability to use our
        services.
      </Text>

      <Heading as="h2" size="lg" mb={4}>
        9. Governing Law
      </Heading>
      <Text mb={4}>
        These Terms are governed by the laws of Connecticut, USA. Any disputes
        arising from these Terms or the use of our services will be resolved in
        the courts of Connecticut, USA.
      </Text>

      <Heading as="h2" size="lg" mb={4}>
        10. Changes to Terms
      </Heading>
      <Text mb={4}>
        We may update these Terms from time to time. Users will be notified of
        changes through a popup modal window in the app that they will need to
        acknowledge.
      </Text>

      <Heading as="h2" size="lg" mb={4}>
        11. Contact Information
      </Heading>
      <Text mb={4}>
        If you have any questions or concerns about these Terms, please contact
        us at{' '}
        <Link href="mailto:contact@epicly.ai" color="teal.500">
          contact@epicly.ai
        </Link>
        .
      </Text>
    </Box>
  );
};

export default TermsOfUse;
