import { useState, useCallback } from 'react';
import { useUserContext } from '../providers/UserProvider';
import { fetchAdPreview as fetchAdPreviewService, downloadAd as downloadAdService } from '../services/ad.service';

const useManageAd = () => {
  const { token } = useUserContext();
  const [loading, setLoading] = useState(false);
  const [downloadLoading, setDownloadLoading] = useState(false);

  const fetchAdPreview = useCallback(
    async (scriptId, voiceoverUrl, musicUrl, format) => {
      try {
        setLoading(true);
        const response = await fetchAdPreviewService(scriptId, voiceoverUrl, musicUrl, format, token);
        return response;
      } catch (error) {
        console.error('Error fetching ad preview:', error);
      } finally {
        setLoading(false);
      }
    },
    [token]
  );

  const downloadAd = useCallback(
    async (scriptId, format) => {
      try {
        setDownloadLoading(true);
        const response = await downloadAdService(scriptId, format, token);
        return response;
      } catch (error) {
        console.error('Error downloading ad:', error);
        throw error;
      } finally {
        setDownloadLoading(false);
      }
    },
    [token]
  );

  return { fetchAdPreview, loading, setLoading, downloadAd, downloadLoading };
};

export default useManageAd;
