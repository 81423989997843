import { useCallback, useState } from 'react';
import { useToast } from '@chakra-ui/react';
import { useUserContext } from '../providers/UserProvider';
import { updateUser as updateUserService } from '../services/user.service';

const useUpdateUser = updatedUser => {
  const { token, setUser } = useUserContext();
  const [hookLoading, setHookLoading] = useState(false);
  const toast = useToast();

  const updateUserData = useCallback(async () => {
    setHookLoading(true);

    try {
      const userDetails = await updateUserService(
        updatedUser._id,
        updatedUser,
        token
      );

      // Update user details in Context
      setUser(userDetails);

      // Show success toast
      toast({
        title: 'User updated.',
        description: `User has been updated.`,
        status: 'success',
        duration: 9000,
        isClosable: true,
      });
    } catch (error) {
      // Show error toast
      toast({
        title: 'An error occurred.',
        description: `Unable to update user. ${error.message}`,
        status: 'error',
        duration: 9000,
        isClosable: true,
      });
    } finally {
      setHookLoading(false);
    }
  }, [updatedUser, token, setUser, toast]);

  return { updateUserData, hookLoading };
};

export default useUpdateUser;
