import React, { createContext, useContext, useState } from 'react';

const AdGenerationContext = createContext();

export const useAdGeneration = () => {
  return useContext(AdGenerationContext);
};

export const AdGenerationProvider = ({ children }) => {
  const [currentStep, setCurrentStep] = useState(1);
  const totalSteps = 3;

  const [adType, setAdType] = useState(null);
  const [projectDetails, setProjectDetails] = useState({});
  const [scriptDetails, setScriptDetails] = useState({
    productName: '',
    brandName: '',
    callToAction: '',
    duration: '',
    industry: null,
    targetAudience: [],
  });
  const [scriptIdea, setScriptIdea] = useState(null);
  const [voiceActor, setVoiceActor] = useState(null);
  const [currentScript, setCurrentScript] = useState([]);

  const value = {
    currentStep,
    setCurrentStep,
    totalSteps,
    adType,
    setAdType,
    projectDetails,
    setProjectDetails,
    scriptDetails,
    setScriptDetails,
    scriptIdea,
    setScriptIdea,
    voiceActor,
    setVoiceActor,
    currentScript,
    setCurrentScript,
  };

  return (
    <AdGenerationContext.Provider value={value}>
      {children}
    </AdGenerationContext.Provider>
  );
};