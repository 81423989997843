import { useState } from 'react';
import { useToast } from '@chakra-ui/react';
import { useUserContext } from '../providers/UserProvider';
import { addAudioToScript as addAudioToScriptService } from '../services/audio.service';
import { removeAudioFromScript as removeAudioFromScriptService } from '../services/audio.service';

const useManageVoiceover = () => {
  const { token } = useUserContext();
  const toast = useToast();

  const [manageVoiceoverLoading, setManageVoiceoverLoading] = useState(false);

  const addAudioToScript = async ({ scriptId, audioData }) => {
    setManageVoiceoverLoading(true);

    try {
      const response = await addAudioToScriptService({
        scriptId,
        audioData,
        token,
      });
      return response;
    } catch (error) {
      console.error('Error adding audio to ad:', error);
      toast({
        title: 'Error',
        description: 'Error adding audio to ad',
        status: 'error',
        duration: 9000,
        isClosable: true,
      });
      throw error;
    } finally {
      setManageVoiceoverLoading(false);
    }
  };

  const removeAudioFromScript = async ({ scriptId, audioId }) => {
    setManageVoiceoverLoading(true);

    try {
      const response = await removeAudioFromScriptService({
        scriptId,
        audioId,
        token,
      });
      return response;
    } catch (error) {
      console.error('Error removing audio from project:', error);
      throw error;
    } finally {
      setManageVoiceoverLoading(false);
    }
  };

  return { addAudioToScript, removeAudioFromScript, manageVoiceoverLoading };
};

export default useManageVoiceover;
