import { useCallback, useState } from 'react';
import { generateTTS11Labs } from '../services/ai.service';
import { useUserContext } from '../providers/UserProvider';
import { useToast } from '@chakra-ui/react';

const useGenerateVoiceover = () => {
  const { token, refreshUserData } = useUserContext();
  const [voiceoverLoading, setVoiceoverLoading] = useState(false);
  const toast = useToast();

  const generateVoiceover = useCallback(async (script) => {
    setVoiceoverLoading(true);
    
    try {
      const response = await generateTTS11Labs({
        title: script.title,
        script: script.script,
        voice: script.voiceActor,
        duration: script.duration,
      }, token);
      
      if (response && response.url) {

        await refreshUserData();

        return response.url;
      } else {
        throw new Error('Failed to generate voiceover.');
      }
    } catch (error) {
      console.error('Error generating voiceover:', error);
      console.error('Error response:', error.response?.data);
      toast({
        title: 'An error occurred.',
        description: `Unable to generate voiceover. ${error.message}`,
        status: 'error',
        duration: 9000,
        isClosable: true,
      });

      throw error; 
    } finally {
      setVoiceoverLoading(false); 
    }
  }, [toast, token]);

  return { generateVoiceover, voiceoverLoading };
};

export default useGenerateVoiceover;
