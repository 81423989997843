import React, { useEffect, useState, useCallback } from 'react';
import ProgressBar from '../components/ProgressBar/ProgressBar';
import ProfileSetupStart from '../components/ProfileSetup/ProfileSetupStart';
import ProfileSetupTypeOfContent from '../components/ProfileSetup/ProfileSetupTypeOfContent';
import ProfileSetupSkillLevel from '../components/ProfileSetup/ProfileSetupSkillLevel';
import ProfileSetupOptins from '../components/ProfileSetup/ProfileSetupOptins';
import useStripeSuccess from '../hooks/useStripeSuccess';
import { Flex, Button, useToast } from '@chakra-ui/react';
import { MotionFlex } from '../config/config';
import { useSignupFormContext } from '../providers/SignupFormProvider';
import { useUserContext } from '../providers/UserProvider';
import { useAuth0 } from '@auth0/auth0-react';
import { updateUser } from '../services/user.service';
import { useNavigate } from 'react-router-dom';

const SubscriptionConfirmation = () => {
  const toast = useToast();
  const { contentTypes, skillLevel, marketingOptIn, enhanceApp } = useSignupFormContext();
  const { user, setUser, token } = useUserContext();
  const { handleStripeSuccess } = useStripeSuccess();
  const navigate = useNavigate();
  const { getAccessTokenSilently } = useAuth0();

  const [currentStep, setCurrentStep] = useState(1);
  const [isProfileUpdated, setIsProfileUpdated] = useState(false);

  const isNextDisabled = useCallback(() => {
    switch (currentStep) {
      case 1:
        return false;
      case 2:
        return !contentTypes.some((contentType) => contentType.isChecked);
      case 3:
        return skillLevel === '';
      default:
        return true;
    }
  }, [currentStep, contentTypes, skillLevel]);

  const handleSuccess = useCallback(async () => {
    const params = new URLSearchParams(window.location.search);
    const sessionId = params.get('session_id');
    const isSuccess = params.get('success') === 'true';
    const isCanceled = params.get('canceled') === 'true';

    if (sessionId) {
      if (isSuccess) {
        try {
          const freshToken = await getAccessTokenSilently();

          setUser((prevUser) => ({ ...prevUser, token: freshToken }));

          const success = await handleStripeSuccess(sessionId, freshToken);

          if (success) {
            // Proceed to profile setup
            setCurrentStep(1);
          } else {
            toast({
              title: 'Error',
              description: 'Something went wrong while processing your subscription.',
              status: 'error',
              duration: 5000,
              isClosable: true,
            });
          }
        } catch (error) {
          console.error('Error obtaining new access token:', error);
          toast({
            title: 'Authentication Error',
            description: 'Please log in again.',
            status: 'error',
            duration: 5000,
            isClosable: true,
          });
        }
      } else if (isCanceled) {
        navigate('/subscription-pricing');
        toast({
          title: 'Order Canceled',
          description: 'Your subscription order was canceled.',
          status: 'warning',
          duration: 5000,
          isClosable: true,
        });
      }
    }
  }, [
    getAccessTokenSilently,
    handleStripeSuccess,
    navigate,
    setUser,
    toast,
    setCurrentStep,
  ]);

  useEffect(() => {
    handleSuccess();
  }, [handleSuccess]);

  const handleNextStep = () => setCurrentStep(prevStep => prevStep + 1);

  const handleNextStepClick = event => {
    event.preventDefault();
    event.stopPropagation();
    handleNextStep();
  };

  const handlePrevStep = () => setCurrentStep(prevStep => Math.max(prevStep - 1, 1));

  const handleSubmit = async (event) => {
    event.preventDefault();

    const contentTypeLabels = contentTypes
      .filter((item) => item.isChecked)
      .map((item) => item.label);

    try {
      const updatedUser = {
        ...user,
        contentTypes: contentTypeLabels,
        skillLevel,
        marketingOptIn,
        dataOptIn: enhanceApp || user.subscriptionStatus === 'free',
        profileSetupComplete: true,
      };

      setUser(updatedUser);

      const response = await updateUser(updatedUser._id, updatedUser, token);

      if (response) {

        toast({
          title: 'Profile Updated',
          description: 'Your profile has been successfully updated.',
          status: 'success',
          duration: 5000,
          isClosable: true,
        });

        navigate('/');
      }
    } catch (error) {
      toast({
        title: 'Error',
        description: 'There was an error updating your profile.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  // Navigate to home page after user state has updated
  useEffect(() => {

    if (user?.profileSetupComplete) {
      navigate('/');
    }
  }, [user?.profileSetupComplete, navigate]);

  const renderStepContent = step => {
    switch (step) {
      case 1:
        return <ProfileSetupStart />;
      case 2:
        return <ProfileSetupTypeOfContent step={step} />;
      case 3:
        return <ProfileSetupSkillLevel step={step} />;
      case 4:
        return <ProfileSetupOptins step={step} />;
      default:
        return null;
    }
  };

  const calculateProgress = useCallback(() => {
    if (currentStep === 1) return 0;
    const adjustedStep = currentStep - 1;
    const totalSteps = 3;
    return (adjustedStep / totalSteps) * 100;
  }, [currentStep]);

  const progressValue = calculateProgress();

  return (
    <Flex minHeight="100vh" align="center" justify="center" bg="gunmetal.550">
      <MotionFlex
        flexDirection={'column'}
        p={8}
        borderRadius="md"
        boxShadow="sm"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
      >
        <form onSubmit={handleSubmit}>
          {renderStepContent(currentStep)}
          <Flex mt={16} justify="center" gap={4}>
            {currentStep > 1 && (
              <Button
                colorScheme="teal"
                variant="ghost"
                px={16}
                py={8}
                fontSize={'xl'}
                rounded={'xl'}
                color={'white'}
                onClick={handlePrevStep}
              >
                Back
              </Button>
            )}
            {currentStep < 4 ? (
              <Button
                colorScheme="teal"
                px={16}
                py={8}
                onClick={handleNextStepClick}
                isDisabled={isNextDisabled()}
                fontSize={'xl'}
                textAlign={'center'}
                rounded={'xl'}
                type="button"
              >
                Next
              </Button>
            ) : (
              <Button
                colorScheme="teal"
                fontSize={'xl'}
                px={16}
                py={8}
                textAlign={'center'}
                rounded={'xl'}
                type="submit"
              >
                Finish
              </Button>
            )}
          </Flex>
        </form>

        <ProgressBar
          currentStep={currentStep}
          progressValue={progressValue}
          position="fixed"
        />
      </MotionFlex>
    </Flex>
  );
};

export default SubscriptionConfirmation;
