import React, { useEffect, useState } from 'react';
import {
  Box,
  Checkbox,
  Flex,
  Heading,
  Icon,
  Link,
  ListItem,
  Modal,
  ModalBody,
  ModalCloseButton,
  OrderedList,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
  SimpleGrid,
  useDisclosure,
  VStack,
  useColorModeValue,
  List,
  Divider,
} from '@chakra-ui/react';
import {
  FaCog,
  FaFileAlt,
  FaHeadphones,
  FaMusic,
  FaUpload,
} from 'react-icons/fa';

const FFBetaModal = () => {
  const bgColor = useColorModeValue('lightMode.bgSecondary', 'darkMode.bg');
  const textColor = useColorModeValue('lightMode.text', 'darkMode.text');
  const secondaryTextColor = useColorModeValue(
    'lightMode.textSecondary',
    'darkMode.textSecondary'
  );
  const iconBgColor = useColorModeValue(
    'lightMode.textSecondary',
    'gunmetal.500'
  );
  const iconColor = useColorModeValue('beige.200', 'gunmetal.300');
  const linkColor = useColorModeValue('lightMode.link', 'darkMode.link');

  const { isOpen, onOpen, onClose } = useDisclosure();
  const [showModal, setShowModal] = useState(false);
  const [dontShowAgain, setDontShowAgain] = useState(false);

  useEffect(() => {
    const modalPreference = localStorage.getItem('showFFBetaModal');
    if (modalPreference !== 'false') {
      setShowModal(true);
      onOpen();
    }
  }, [onOpen]);

  const handleCheckboxChange = () => {
    setDontShowAgain(!dontShowAgain);
  };

  const handleClose = () => {
    if (dontShowAgain) {
      localStorage.setItem('showFFBetaModal', 'false');
    }
    setShowModal(false);
    onClose();
  };

  return (
    <>
      <Modal
        isOpen={isOpen && showModal}
        onClose={handleClose}
        size="3xl"
        isCentered
      >
        <ModalOverlay />
        <ModalContent bg={bgColor} color={textColor} borderRadius="md" px={4}>
          <ModalHeader>
            <Flex direction="column" align="center" mt={8}>
              <Heading
                as="h1"
                size={{ base: 'md', md: 'md' }}
                textAlign={'center'}
              >
                🚀 Welcome to Epicly.AI’s Friends & Family <br/>
                Limited Beta Test! 🚀
              </Heading>
              <Text textAlign={'center'} mt={2} mb={4} fontSize={'md'} fontWeight={'medium'}>
              Thank you for joining our program. This limited beta will close
                on January 1, 2025, but you will enjoy an additional free month
                of Epicly Pro once we go live.
              </Text>
              <Box p={4} bg={'gunmetal.500'} borderRadius={'md'}>
                <Text
                  textAlign={'center'}
                mt={2}
                fontSize={'md'}
                fontWeight={'medium'}
              >
                The "Free" plan doesn't require a credit card, but in order to access more credits to generate ads, you’ll have to select a higher tier and enter the 
                following mock credit card details in order to get started.
              </Text>
              </Box>
            </Flex>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Flex direction="column" mt={0}>
              <Stack spacing={2} mb={4} flexDir={'column'} textAlign={'center'}>
                <Heading size={{ base: 'md', md: 'sm' }}>
                  Important Notes
                </Heading>
                <Text fontSize={'md'} fontWeight={'bold'}>
                  PLEASE AVOID USING YOUR REAL CREDIT CARD INFORMATION. <br />
                  <span style={{ fontWeight: 'normal', color: '#E5D3B8' }}>
                    This is a test account and credit card. No real charges or
                    refunds will occur.
                  </span>
                </Text>
              </Stack>
              <Divider />
              <Stack
                spacing={2}
                mt={2}
                mb={4}
                flexDir={'column'}
                textAlign={'center'}
              >
                <Heading
                  size={{ base: 'md', md: 'md' }}
                  mt={4}
                  textAlign={'center'}
                  mb={2}
                >
                  Mock Credit Card Information
                </Heading>
                <SimpleGrid
                  columns={2}
                  spacing={2}
                  justifyItems={'center'}
                  textAlign={'center'}
                >
                  <Box p={0}>
                    <Text fontSize={'md'} fontWeight={'bold'} color={'white'}>
                      Name on Card:
                    </Text>
                    <Text
                      fontSize={'md'}
                      fontWeight={'normal'}
                      color={'beige.200'}
                    >
                      Your name or "Epicly Test User"
                    </Text>
                  </Box>

                  <Box p={0}>
                    <Text fontSize={'md'} fontWeight={'bold'} color={'white'}>
                      Card Number:
                    </Text>

                    <Text
                      fontSize={'md'}
                      fontWeight={'normal'}
                      color={'beige.200'}
                    >
                      4242 4242 4242 4242
                    </Text>
                  </Box>

                  <Box p={0}>
                    <Text fontSize={'md'} fontWeight={'bold'} color={'white'}>
                      Expiration Date:
                    </Text>
                    <Text
                      fontSize={'md'}
                      fontWeight={'normal'}
                      color={'beige.200'}
                    >
                      Any future date
                    </Text>
                  </Box>

                  <Box p={0}>
                    <Text fontSize={'md'} fontWeight={'bold'} color={'white'}>
                      CVV:
                    </Text>
                    <Text
                      fontSize={'md'}
                      fontWeight={'normal'}
                      color={'beige.200'}
                    >
                      Any 3 digits
                    </Text>
                  </Box>
                </SimpleGrid>
              </Stack>
              <Divider />
              <Stack spacing={2} mt={4} flexDir={'column'} textAlign={'center'}>
                <Heading size={{ base: 'md', md: 'md' }}>
                  Instructions for testing
                </Heading>
                <OrderedList textAlign={'left'} spacing={2}>
                  <ListItem>
                    <Text fontSize={'md'} fontWeight={'normal'}>
                      Select the "Professional" plan.
                    </Text>
                  </ListItem>
                  <ListItem>
                    <Text fontSize={'md'} fontWeight={'normal'}>
                      Enter the mock credit card information when prompted.
                    </Text>
                  </ListItem>
                  <ListItem>
                    <Text fontSize={'md'} fontWeight={'normal'}>
                      The system will simulate a transaction, but no real
                      charges will occur.
                    </Text>
                  </ListItem>
                  <ListItem>
                    <Text fontSize={'md'} fontWeight={'normal'}>
                      If you encounter any issues, please report
                      them using the in-app feedback tool or email us at{' '}
                      <Link href="mailto:contact@epicly.ai">
                        contact@epicly.ai
                      </Link>
                    </Text>
                  </ListItem>
                  <ListItem>
                    <Text fontSize={'md'} fontWeight={'normal'}>
                      Enjoy the beta and have fun testing!  Make some ads, share with your friends, and help us make Epicly.ai the best it can be.
                    </Text>
                  </ListItem>
                </OrderedList>
              </Stack>
            </Flex>
          </ModalBody>
          <ModalFooter>
            <Checkbox
              colorScheme="teal"
              isChecked={dontShowAgain}
              onChange={handleCheckboxChange}
            >
              Don’t show this again
            </Checkbox>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default FFBetaModal;
