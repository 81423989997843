import React from 'react';
import {
  Flex,
  Badge,
  Heading,
  VStack,
  Text,
  Button,
} from '@chakra-ui/react';
import { motion } from 'framer-motion';

const MotionHeading = motion(Heading);
const MotionText = motion(Text);

const TriviaSidebar = ({ currentQuestion, totalXp, xpEarned, totalQuestions }) => {
  return (
    <Flex
      direction="column"
      justify="space-between"
      align="center"
      flex={1}
      h="100%"
      p={10}
      background="linear-gradient(270deg, rgba(0,0,0,0.10) 0%, rgba(89,199,218,0.25) 100%), #375566"
    >
      <Badge borderRadius="full" px="8" py={2} colorScheme="teal">
        Easy
      </Badge>

      <Heading
        as="h1"
        size="2xl"
        color="white"
        fontWeight="200"
        textAlign="center"
      >
        Artificial Intelligence
      </Heading>

      <VStack spacing={4} align="center">
        <Heading as="h3" color="white" size="lg" fontWeight="400">
          Question
        </Heading>

        <MotionHeading
          as="h2"
          color="white"
          size="2xl"
          fontWeight="200"
          initial={{ scale: 0.5 }}
          animate={{ scale: 1 }}
          transition={{ duration: 0.5 }}
        >
          {currentQuestion + 1} of {totalQuestions}
        </MotionHeading>
      </VStack>

      <MotionText
        color="white"
        fontWeight="bold"
        fontSize="2xl"
        initial={{ y: -10, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        transition={{ duration: 0.5 }}
        display="flex"
        alignItems="center"
      >
        🏆 {xpEarned} XP Earned
      </MotionText>

      <Text color="white" fontWeight="bold">
        🏆 {totalXp} XP Available to Win
      </Text>

      <Button
        borderRadius="md"
        bg="gunmetal.400"
        color="white"
        _hover={{ bg: 'gunmetal.300' }}
        _active={{ bg: 'gunmetal.200' }}
        w="full"
        mt={4}
        h="80px"
        fontSize="xl"
      >
        Leave Early
      </Button>
    </Flex>
  );
};

export default TriviaSidebar;
