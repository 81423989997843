import { switchAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(switchAnatomy.keys);

const baseStyle = definePartsStyle({
  container: {
    h: '32px',
    w: '64px',
    padding: '2px',
  },
  thumb: {
    bg: 'white',
    transition: 'transform 250ms ease, background-color 250ms',
  },
  track: {
    bg: 'red.500',
    _checked: {
      bg: 'green.500',
    },
    _hover: {
      _checked: {
        bg: 'green.600',
      },
      bg: 'red.600',
    },
    _focus: {
      boxShadow: 'outline',
    },
  },
});

// Define a custom variant
const custom = definePartsStyle({
  container: {
    padding: '2px',
  },
  thumb: {
    bg: 'white',
    _checked: {
      bg: 'white',
    },
  },
  track: {
    bg: 'beige.400',
    _checked: {
      bg: 'moonstone.400',
    },
    _hover: {
      bg: 'beige.300',
      _checked: {
        bg: 'moonstone.300',
      },
    },
  },
});

const muted = definePartsStyle({
  container: {
    h: '32px',
    w: '64px',
    padding: '2px',
  },
  thumb: {
    bg: 'white',
    transition: 'transform 250ms ease, background-color 250ms',
  },
  track: {
    bg: 'gray.500',
    _checked: {
      bg: 'green.500',
    },
    _hover: {
      _checked: {
        bg: 'green.600',
      },
      bg: 'gray.600',
    },
    _focus: {
      boxShadow: 'outline',
    },
  },
});

export const switchTheme = defineMultiStyleConfig({
  baseStyle,
  variants: { custom, muted },
});
