import React from 'react';
import { Button, Heading, Flex, Stack, useColorModeValue } from '@chakra-ui/react';
import { Link } from 'react-router-dom';

const Hero = ({ showContent, altTitle, bg }) => {
  const buttonBgColor = useColorModeValue('gunmetal.400', 'gunmetal.400');
  const buttonHoverBgColor = useColorModeValue('gunmetal.500', 'gunmetal.500');
  const buttonTextColor = useColorModeValue('white', 'white');
  const headingColor = useColorModeValue('white', 'white');
  const subheadingColor = useColorModeValue('beige.200', 'beige.200');  

  return (
    <Flex
      flexDir={'column'}
      justify={'center'}
      align={{ base: 'center', md: 'flex-start' }}
      className="gradient-overlay"
      position="relative"
      rounded={'25px'}
      backgroundImage={bg}
      backgroundRepeat={'no-repeat'}
      backgroundSize={'cover'}
      backgroundPosition={{ base: 'center', md: 'center' }}
      minH={{ base: '200px', sm: '250px', md: '300px', lg: '350px' }}
      p={{ base: '20px', sm: '30px', md: '40px', lg: '50px' }}
      overflow={'hidden'}
      mb={{ base: 6, sm: 8, md: 10 }}
      textAlign={{ base: 'center', md: 'left' }}
    >
      {showContent ? (
        <Flex
          flexDir={'column'}
          zIndex={1}
          align={{ base: 'center', md: 'flex-start' }}
          maxW={{ base: '100%', md: '80%', lg: '60%' }}
        >
          <Heading
            as="h2"
            size={{ base: 'md', sm: 'lg', md: 'xl', lg: '2xl' }}
            textAlign={{ base: 'center', md: 'left' }}
            mb={{ base: 2, sm: 3, md: 4 }}
            color={headingColor}
          >
            Get started
          </Heading>
          <Heading
            as="h3"
            size={{ base: 'sm', sm: 'md', md: 'md', lg: 'md' }}
            fontWeight={'300'}
            textAlign={{ base: 'center', md: 'left' }}
            mb={{ base: 4, sm: 5, md: 6 }}
            color={subheadingColor}
            maxW={'80%'}
          >
            Generate a full ad with a script, voiceover and background music.
          </Heading>
          <Stack
            direction={{ base: 'column', sm: 'row' }}
            justify={{ base: 'center', md: 'flex-start' }}
            w={'100%'}
            mt={{ base: 2, sm: 4, md: 6 }}
            mb={0}
            spacing={{ base: 2, sm: 4 }}
          >
            <Button
              as={Link}
              to="/generate-ad"
              size={{ base: 'sm', sm: 'md', md: 'lg' }}
              rounded={'full'}
              bg={buttonBgColor}
              color={buttonTextColor}
              px={{ base: 6, sm: 8, md: 10 }}
              py={{ base: 4, sm: 5, md: 6 }}
              _hover={{
                bg: buttonHoverBgColor,
              }}
            >
              Let's Start
            </Button>
          </Stack>
        </Flex>
      ) : (
        <Flex flexDir={'column'} zIndex={1} align={'center'} w={'100%'}>
          <Heading
            as="h2"
            size={{ base: 'lg', sm: 'xl', md: '2xl', lg: '3xl' }}
            textAlign="center"
            mb={{ base: 2, sm: 3, md: 4 }}
            mx={{ base: 2, sm: 4, md: 6 }}
            fontWeight={'700'}
            letterSpacing={'1px'}
          >
            {altTitle}
          </Heading>
        </Flex>
      )}
    </Flex>
  );
};

export default Hero;
