import React, { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Flex, Spinner } from '@chakra-ui/react';
import { useUserContext } from './providers/UserProvider';

const ProtectedRoute = ({ children }) => {
  const { user, loading, isAuthenticated } = useUserContext();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (!loading) {
      const path = location.pathname;

      if (!isAuthenticated) {
        if (path !== '/login') {
          navigate('/login');
        }
      } else {
        if (user?.subscriptionStatus !== 'active') {
          // Redirect to profile setup
          navigate('/profile-setup');
        } else if (
          user?.subscriptionStatus === 'active' &&
          !user?.profileSetupComplete
        ) {
          navigate('/subscription-success');
        }
      }
    }
  }, [
    isAuthenticated,
    loading,
    user?.subscriptionStatus,
    user?.profileSetupComplete,
    navigate,
    location.pathname,
  ]);

  if (loading) {
    return (
      <Flex minHeight="100vh" align="center" justify="center">
        <Spinner size="xl" />
      </Flex>
    );
  }

  return children;
};

export default ProtectedRoute;
