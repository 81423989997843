import React, { useEffect, useState } from 'react';
import {
  Box,
  Flex,
  Heading,
  Text,
  VStack,
  Tooltip,
  IconButton,
  useColorModeValue,
  HStack,
} from '@chakra-ui/react';
import { FaMicrophone, FaPen, FaVideo, FaCircleInfo } from 'react-icons/fa6';
import { useAdGeneration } from '../../providers/AdGenerationProvider';
import { useNavigate } from 'react-router-dom';

const adOptions = [
  {
    type: 'Audio Ad',
    icon: FaMicrophone,
    description:
      'This is a guided process that helps determine the type of project, theme, tone, and other details.',
    route: '/project-details',
    tooltip: 'Best for Podcast or Radio Ads, or to layer on to your own video.',
  },
  // {
  //   type: 'Start From Scratch',
  //   icon: FaPen,
  //   description: 'This will start you off with a blank slate.',
  //   route: '/script-editor',
  //   tooltip: 'Best for saving time by creating a script from scratch.',
  // },
  {
    type: 'Video Ad',
    icon: FaVideo,
    description:
      'This helps determine the type of project, theme, tone, and other details.',
    disabled: true,
    tooltip: 'Coming Soon!',
  },
];

const AdStart = () => {
  const bgColor = useColorModeValue('beige.100', 'gunmetal.700');
  const cardBgColor = useColorModeValue('beige.200', 'linear-gradient(180deg, rgba(156, 219, 255, 0.30) 0%, rgba(131, 183, 212, 0.17) 15.55%, rgba(37, 52, 60, 0.17) 100%), #375566');
  const cardHoverBgColor = useColorModeValue('beige.300', 'linear-gradient(180deg, rgba(156, 219, 255, 0.35) 0%, rgba(131, 183, 212, 0.22) 15.55%, rgba(37, 52, 60, 0.22) 100%), #4A6B7F');
  const headingColor = useColorModeValue('gray.800', 'white');
  const textColor = useColorModeValue('gray.600', 'gray.300');
  const borderColor = useColorModeValue('beige.100', 'gunmetal.300');
  const shadowColor = useColorModeValue(
    'rgba(0, 0, 0, 0.1)',
    'rgba(255, 255, 255, 0.1)'
  );

  const [projectId, setProjectId] = useState(null);

  const navigate = useNavigate();
  const { setAdType } = useAdGeneration();

  useEffect(() => {
    // If there are query params, make sure they are passed to the next page
    const urlParams = new URLSearchParams(window.location.search);
    const projectId = urlParams.get('projectId');
    if (projectId) {
      setProjectId(projectId);
    }
  }, []);

  const handleAdClick = (adType, route) => {
    setAdType(adType);
    // Set in session storage so we can access it in the next component
    sessionStorage.setItem('adType', adType); 
    navigate(route + (projectId ? `?projectId=${projectId}` : ''));
  };

  return (
    <Box
      p={8}
      bg={bgColor}
      color={textColor}
      borderRadius="md"
      maxW="1200px"
      mx="auto"
      w="full"
      textAlign="center"
    >
      <Heading size="xl" mb={4} color={headingColor}>
        Let's Get Creating
      </Heading>
      <Text fontSize="lg" mb={8}>
        Select the type of ad you would like to create.
      </Text>

      <VStack spacing={8}>
        {adOptions.map(
          ({
            type,
            icon: IconComponent,
            description,
            route,
            disabled,
            tooltip,
          }) => (
            <Flex
              key={type}
              w="full"
              bg={cardBgColor}
              borderRadius="md"
              align="center"
              p={4}
              onClick={() => !disabled && handleAdClick(type, route)}
              cursor={disabled ? 'not-allowed' : 'pointer'}
              _hover={!disabled ? { bg: cardHoverBgColor } : {}}
              borderWidth={disabled ? '0' : '1px'}
              borderColor={disabled ? 'transparent' : borderColor}
              boxShadow={!disabled ? `0 0 10px 5px ${shadowColor}` : 'none'}
              opacity={disabled ? 0.5 : 1}
              h={{ base: 'auto', md: '125px' }}
            >
              <Box ml={4} mr={8}>
                <IconComponent size={24} />
              </Box>
              <VStack align="start" spacing={1}>
                <HStack justify={'flex-start'} gap={0}>
                  <Heading size="md" color={headingColor} textAlign={'left'}>
                    {type}
                  </Heading>

                  <Tooltip
                    label={tooltip}
                    aria-label={`${type} info tooltip`}
                    placement="top"
                  >
                    <IconButton
                      variant="ghost"
                      aria-label={`Info about ${type}`}
                      icon={<FaCircleInfo size={16} />}
                      size="sm"
                      p={0}
                      m={0}
                    />
                  </Tooltip>
                </HStack>

                <Text maxW={'400px'} textAlign={'left'}>
                  {description}
                </Text>
              </VStack>
            </Flex>
          )
        )}
      </VStack>
    </Box>
  );
};

export default AdStart;
