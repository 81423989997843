import { useCallback, useState, useEffect } from 'react';
import { getIndustries } from '../services/industry.service';
import { useToast } from '@chakra-ui/react';
import { useUserContext } from '../providers/UserProvider';

const useGetIndustries = () => {
  const { token } = useUserContext();
  const [loading, setLoading] = useState(false);
  const [industries, setIndustries] = useState([]);
  const toast = useToast();

  const fetchIndustries = useCallback(async () => {
    setLoading(true);
    try {
      const industries = await getIndustries(token);
      setIndustries(industries);
    } catch (error) {
      toast({
        title: 'An error occurred.',
        description: `Unable to fetch industries. ${error.message}`,
        status: 'error',
        duration: 9000,
        isClosable: true,
      });
    } finally {
      setLoading(false);
    }
  }, [toast, token]);

  useEffect(() => {
    fetchIndustries();
  }, [fetchIndustries]);

  return { industries, loading };
};

export default useGetIndustries;
