import api from '../utils/api.utils';
import { handleResponse } from '../utils/app.utils';

// Retrieves scripts from the server.
export const getScripts = async token => {
  const response = await api.get(`/scripts`, { customHeaders: { token } });
  return handleResponse(response, 'Error getting scripts');
};

// Retrieves a script by its ID.
export const getScriptById = async (id, token) => {
  const response = await api.get(`/scripts/${id}`, {
    customHeaders: { token },
  });
  return handleResponse(response, 'Error getting script by ID');
};

// Saves the script.
export const saveScript = async (scriptData, token) => {
  const response = await api.post(`/scripts`, scriptData, {
    customHeaders: { token },
  });
  return handleResponse(response, 'Error creating script');
};

// Updates a script with the provided script data.
export const updateScript = async (id, scriptData, token) => {
  const response = await api.patch(`/scripts/${id}`, scriptData, {
    customHeaders: { token },
  });
  return handleResponse(response, 'Error updating script');
};

// Deletes a script with the specified ID.
export const deleteScript = async (id, token) => {
  const response = await api.delete(`/scripts/${id}`, {
    customHeaders: { token },
  });
  return handleResponse(response, 'Error deleting script');
};

// Downloads a script file.
export const downloadScript = async (scriptId, type, token) => { 
    const response = await api.post(
      `/scripts/${scriptId}/download`, 
      { type },
      {
        customHeaders: { token },
        responseType: 'blob',
      }
    );

    return response;
};