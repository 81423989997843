import { useState } from 'react';
import { generateMusic as generateMusicService } from '../services/ai.service';
import { useUserContext } from '../providers/UserProvider';
import { useToast } from '@chakra-ui/react';

const useGenerateMusic = () => {
  const { token } = useUserContext();
  const [musicLoading, setMusicLoading] = useState(false);
  const toast = useToast();

  const generateMusic = async ({ genre, mood, tone }) => {
    setMusicLoading(true);
    try {
      const musicUrl = await generateMusicService({ genre, mood, tone, token });
      return musicUrl;
    } catch (error) {
      console.error('Error generating music:', error);
      toast({
        title: 'Error',
        description: 'Error generating music',
        status: 'error',
        duration: 9000,
        isClosable: true,
      });
      throw error;
    } finally {
      setMusicLoading(false);
    }
  };

  return { generateMusic, musicLoading };
};

export default useGenerateMusic;
