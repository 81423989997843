import React from 'react';
import { Box, Text, Flex } from '@chakra-ui/react';

const PizzaProgress = ({ status }) => {
  const steps = [
    { label: 'Pending', status: 'pending' },
    { label: 'Completed', status: 'completed' },
  ];

  const getStatusColor = (stepStatus, currentStatus) => {
    if (stepStatus === currentStatus) return 'green.500';
    if (stepStatus === 'pending') return 'green.500';
    return 'gray.300';
  };

  const getClipPath = (index, totalSteps) => {
    if (index === 0) {
      return 'polygon(0 0, 95% 0, 90% 100%, 0 100%)'; // slant right with rounded left
    } else if (index === totalSteps - 1) {
      return 'polygon(10% 0, 100% 0, 100% 100%, 5% 100%)'; // slant left with rounded right
    } else {
      return 'polygon(10% 0, 95% 0, 90% 100%, 5% 100%)'; // slant on both sides
    }
  };

  return (
    <Flex direction="column" align="stretch" flex={1} maxW={'350px'}>
      <Flex w="100%" justify="center" maxW={'350px'}>
        {steps.map((step, index) => (
          <Flex key={index} direction="column" align="flex-start" flex="1">
            <Box
              bg={getStatusColor(step.status, status)}
              height="20px"
              clipPath={getClipPath(index, steps.length)}
              w={index < steps.length - 1 ? 'calc(100% - (-20px))' : '100%'}
              mr={index < steps.length - 1 ? '0' : '0'}
              ml={index > 0 ? '0' : '0'}
              borderRadius="full"
            />
            <Text fontSize="sm" mt={2}>{step.label}</Text>
          </Flex>
        ))}
      </Flex>
    </Flex>
  );
};

export default PizzaProgress;
