import { useCallback, useState } from 'react';
import {
  getSuggestedAudiences,
  getSuggestedAudienceById,
} from '../services/suggestedAudience.service';
import { useToast } from '@chakra-ui/react';
import { useUserContext } from '../providers/UserProvider';

const useSuggestedAudiences = () => {
  const { token } = useUserContext();
  const [loading, setLoading] = useState(false);
  const [suggestedAudiences, setSuggestedAudiences] = useState([]);
  const toast = useToast();

  const fetchSuggestedAudiences = useCallback(async () => {
    setLoading(true);
    try {
      const audiences = await getSuggestedAudiences(token);

      setSuggestedAudiences(audiences);
    } catch (error) {
      toast({
        title: 'An error occurred.',
        description: `Unable to fetch suggested audiences. ${error.message}`,
        status: 'error',
        duration: 9000,
        isClosable: true,
      });
    } finally {
      setLoading(false);
    }
  }, [toast, token]);

  const fetchSuggestedAudienceById = useCallback(
    async id => {
      setLoading(true);
      try {
        const audience = await getSuggestedAudienceById(token, id);
        return audience;
      } catch (error) {
        toast({
          title: 'An error occurred.',
          description: `Unable to fetch suggested audience. ${error.message}`,
          status: 'error',
          duration: 9000,
          isClosable: true,
        });
      } finally {
        setLoading(false);
      }
    },
    [toast, token]
  );

  return {
    fetchSuggestedAudiences,
    suggestedAudiences,
    loading,
    fetchSuggestedAudienceById,
  };
};

export default useSuggestedAudiences;
