import React from 'react';
import {
  Box,
  Text,
} from '@chakra-ui/react';
import Hero from '../Hero/Hero';
import CreditBanner from '../CreditBanner/CreditBanner';
import HeaderImage from '../../assets/header-crowd.jpg';
import { useUserContext } from '../../providers/UserProvider';
import RecentProjectsSlider from '../RecentProjectsSlider/RecentProjectsSlider';
import useRecentProjects from '../../hooks/useRecentProjects';

const Dashboard = () => {
  const { user, loading, subscription } = useUserContext();
  const { recentProjects, loading: recentLoading, error } = useRecentProjects();

  return (
    <Box mt={6} mb={20}>
      <Hero showContent bg={HeaderImage} />
      <CreditBanner user={user} subscription={subscription} loading={loading} />
      {recentLoading ? (
        <Text>Loading recent projects...</Text>
      ) : error ? (
        <Text>Error fetching recent projects.</Text>
      ) : (
        <RecentProjectsSlider projects={recentProjects} loading={recentLoading} />
      )}
    </Box>
  );
};

export default Dashboard;
