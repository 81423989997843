import React from 'react';
import {
  Button,
  Divider,
  Flex,
  Heading,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Spinner,
  useBreakpointValue,
  useColorModeValue,
  Tooltip,
  Icon,
  Text,
  Box,
  useDisclosure,
  HStack,
  VStack,
} from '@chakra-ui/react';
import PurchaseCredits from '../PurchaseCredits/PurchaseCredits';
import { isOverCredits, hasLowCredits } from '../../config/config';
import { PiCoinsFill } from 'react-icons/pi';

const CreditBanner = ({ user, subscription, loading, accumulatedCredits }) => {
  const width = useBreakpointValue({ base: '100%', md: '100%' });
  const padding = useBreakpointValue({ base: 4, md: 6 });
  const bgColor = useColorModeValue(
    '#57828F',
    'linear-gradient(to bottom, #3d4449, #22292f)'
  );
  const cardBgColor = useColorModeValue('beige.300', 'gunmetal.600');
  const textColor = useColorModeValue('white', 'white');
  const buttonBgColor = useColorModeValue('beige.200', 'beige.300');
  const buttonHoverBgColor = useColorModeValue('beige.300', 'beige.400');
  const buttonHoverTextColor = useColorModeValue('white', 'white');
  const modalBgColor = useColorModeValue('beige.100', '#232C33');
  const modalBorderColor = useColorModeValue('gray.300', '#3E4E57');
  const modalHeaderBgColor = useColorModeValue('beige.200', '#314048');
  const warningBgColor = useColorModeValue('orange.100', '#1C242A');
  const warningIconColor = useColorModeValue('orange.500', '#FFB223');
  const subscriptionCreditsColor = useColorModeValue(
    'moonstone.400',
    'moonstone.400'
  );
  const purchasedCreditsColor = useColorModeValue('green.400', 'green.600');

  const { onOpen, isOpen, onClose } = useDisclosure();

  const subscriptionCredits = user?.subscriptionCredits || 0;
  const purchasedCredits = user?.purchasedCredits || 0;
  const totalCredits = subscriptionCredits + purchasedCredits;
  const planCredits = subscription?.subscriptionPlan?.credits || 0;
  const progressPercentage = planCredits
    ? Math.min((totalCredits / planCredits) * 100, 100)
    : 0;
  const subscriptionPercentage = planCredits
    ? (subscriptionCredits / planCredits) * 100
    : 0;
  const purchasedPercentage = planCredits
    ? (purchasedCredits / planCredits) * 100
    : 0;

  const handleModalOpen = () => {
    onOpen();
  };

  return (
    <Flex flexDir={'column'} justify={'center'} align={'center'}>
      <Flex
        width={width}
        justify={'center'}
        align={'center'}
        flexDir={'column'}
        background={bgColor}
        shadow={'lg'}
        rounded={'2xl'}
        p={padding}
        mb={10}
      >
        <Flex flexDir={'column'}>
          {loading ? (
            <Flex align={'center'} justify={'center'}>
              <Spinner color={textColor} />
            </Flex>
          ) : (
            <Heading
              as="h3"
              size="md"
              fontWeight={'500'}
              textAlign="center"
              color={textColor}
              textTransform={'uppercase'}
            >
              Beta Trial - {subscription?.subscriptionPlan?.name}
            </Heading>
          )}

          <Divider my={4} borderColor={textColor} />

          <Text
            fontSize={['xs', 'sm', 'md']}
            color={textColor}
            textAlign="center"
            mb={4}
          >
            Your plan offers {planCredits} credits.
          </Text>

          {/* Progress Bar with Tooltips */}
          <Flex
            w={'100%'}
            bg={'gray.500'}
            rounded={'full'}
            overflow={'hidden'}
            h={[6, 8]}
            mb={4}
            position="relative"
          >
            <Tooltip
              label={`Purchased Credits: ${purchasedCredits}`}
              aria-label="Purchased Credits Info"
              hasArrow
              bg={cardBgColor}
              color={textColor}
              placement="top"
            >
              <Box
                bg={purchasedCreditsColor}
                w={`${purchasedPercentage}%`}
                h={'100%'}
                cursor="pointer"
              />
            </Tooltip>
            <Tooltip
              label={`Subscription Credits: ${subscriptionCredits}`}
              aria-label="Subscription Credits Info"
              hasArrow
              bg={cardBgColor}
              color={textColor}
              placement="top"
            >
              <Box
                bg={subscriptionCreditsColor}
                w={`${subscriptionPercentage}%`}
                h={'100%'}
                cursor="pointer"
              />
            </Tooltip>

            {isOverCredits && (
              <Tooltip
                label={`Additional Credits: ${totalCredits - planCredits}`}
                aria-label="Additional Credits Info"
                hasArrow
                bg={cardBgColor}
                color={textColor}
                placement="top"
              >
                <Box
                  bg={purchasedCreditsColor}
                  w={`${progressPercentage - 100}%`}
                  h={'100%'}
                  position="absolute"
                  right={0}
                  cursor="pointer"
                />
              </Tooltip>
            )}
          </Flex>

          {loading ? (
            <Flex align={'center'} justify={'center'}>
              <Spinner color={textColor} />
            </Flex>
          ) : (
            <HStack>
              <Text
                color={
                  isOverCredits
                    ? 'green.300'
                    : hasLowCredits
                    ? 'red.400'
                    : textColor
                }
              >
                {totalCredits}
              </Text>
              <Text color={textColor}>/ {planCredits} credits remaining</Text>
            </HStack>
          )}

          <Button
            size={['sm', 'md']}
            background={buttonBgColor}
            p={[4, 6]}
            mt={4}
            onClick={handleModalOpen}
            _hover={{ bg: buttonHoverBgColor, color: buttonHoverTextColor }}
          >
            Purchase more credits
          </Button>

          {/* Modal */}
          <Modal isOpen={isOpen} onClose={onClose} size={'4xl'}>
            <ModalOverlay />
            <ModalContent
              background={modalBgColor}
              borderRadius="md"
              borderWidth="1px"
              borderColor={modalBorderColor}
            >
              <ModalHeader
                bg={modalHeaderBgColor}
                color={textColor}
                fontSize={'lg'}
                fontWeight={'normal'}
                borderBottom="1px solid"
                borderColor={modalBorderColor}
              >
                Purchase Credits
              </ModalHeader>

              <ModalCloseButton color={textColor} />
              <ModalBody>
                <VStack
                  spacing={2}
                  bg={warningBgColor}
                  p={4}
                  borderRadius="md"
                  boxShadow="sm"
                  border={'1px solid'}
                  borderColor={modalBorderColor}
                  my={4}
                >
                  <Icon as={PiCoinsFill} color={warningIconColor} boxSize={8} />

                  <Divider
                    orientation="vertical"
                    colorScheme={'whiteAlpha'}
                    variant={'solid'}
                  />

                  <Text fontSize={'xl'} textAlign={'center'}>
                    Are you running low on credits or preparing for a big
                    project?
                  </Text>

                  <Text fontSize={'lg'} textAlign={'center'}>
                    Buy a credit pack to make sure you never run out.
                  </Text>
                </VStack>
                <PurchaseCredits />
              </ModalBody>
            </ModalContent>
          </Modal>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default CreditBanner;
