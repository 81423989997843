const colors = {
  transparent: 'transparent',
  gradBlack:
    'linear-gradient(180deg, rgba(255, 255, 255, 0.12) 0%, rgba(217, 212, 212, 0.07) 22.92%, rgba(0, 0, 0, 0.07) 100%), #232C33;',
  gradBlackHover:
    'linear-gradient(180deg, rgba(255, 255, 255, 0.17) 0%, rgba(217, 212, 212, 0.12) 22.92%, rgba(0, 0, 0, 0.12) 100%), #3A454C;',
  gradGunmetal:
    'linear-gradient(180deg, rgba(156, 219, 255, 0.30) 0%, rgba(131, 183, 212, 0.17) 15.55%, rgba(37, 52, 60, 0.17) 100%), #375566',
  gradGunmetalHover:
    'linear-gradient(180deg, rgba(156, 219, 255, 0.35) 0%, rgba(131, 183, 212, 0.22) 15.55%, rgba(37, 52, 60, 0.22) 100%), #4A6B7F',
  gradBeige:
    'linear-gradient(180deg, rgba(229, 211, 184, .9) 0%, rgba(178, 157, 125, .7) 100%), #232C33;',
  gradBeigeHover:
    'linear-gradient(180deg, rgba(229, 211, 184, .8) 0%, rgba(178, 157, 125, .6) 100%), #232C33;',
  black: '#000000',
  blackAlpha: {
    50: 'rgba(0, 0, 0, 0.04)',
    100: 'rgba(0, 0, 0, 0.06)',
    200: 'rgba(0, 0, 0, 0.08)',
    300: 'rgba(0, 0, 0, 0.16)',
    400: 'rgba(0, 0, 0, 0.24)',
    500: 'rgba(0, 0, 0, 0.36)',
    600: 'rgba(0, 0, 0, 0.48)',
    700: 'rgba(0, 0, 0, 0.60)',
    800: 'rgba(0, 0, 0, 0.72)',
    900: 'rgba(0, 0, 0, 0.84)',
  },
  white: '#FFFFFF',
  whiteAlpha: {
    50: 'rgba(255, 255, 255, 0.04)',
    100: 'rgba(255, 255, 255, 0.06)',
    200: 'rgba(255, 255, 255, 0.08)',
    300: 'rgba(255, 255, 255, 0.16)',
    400: 'rgba(255, 255, 255, 0.24)',
    500: 'rgba(255, 255, 255, 0.36)',
    600: 'rgba(255, 255, 255, 0.48)',
    700: 'rgba(255, 255, 255, 0.60)',
    800: 'rgba(255, 255, 255, 0.72)',
    900: 'rgba(255, 255, 255, 0.84)',
  },
  gunmetal: {
    50: '#F0F6F7',
    100: '#E6F5F7',
    200: '#BADDE2',
    300: '#89B5C2',
    400: '#57828F',
    450: '#46677A',
    500: '#375566',
    550: '#2F4957',
    600: '#232C33',
    700: '#1A1F22',
    800: '#111416',
    900: '#0A0C0D',
  },
  beige: {
    50: '#FBF8F2',
    100: '#F7F0E6',
    200: '#E5D3B8',
    300: '#B29D7D',
    400: '#837156',
    500: '#594D43',
    600: '#332A26',
    700: '#221C1B',
    800: '#161211',
    900: '#0D0B0B',
  },
  walnut: {
    50: '#FDFCF7',
    100: '#F6F7E6',
    200: '#E3E5C5',
    300: '#BFBDA7',
    400: '#8C845B',
    500: '#595643',
    600: '#333126',
    700: '#22201B',
    800: '#161513',
    900: '#0D0C0B',
  },
  moonstone: {
    50: '#f3fbfc',
    100: '#ceeff4',
    200: '#b8e8ef',
    300: '#9edfe9',
    400: '#57c3d4',
    500: '#4fb3c3',
    600: '#48a2af',
    700: '#3b8691',
    800: '#316e77',
    900: '#26555c',
  },
  green: {
    50: '#ECF8F2',
    100: '#CAECDA',
    200: '#A8E0C3',
    300: '#86D4AB',
    400: '#65C894',
    500: '#43BC7C',
    600: '#359763',
    700: '#28714A',
    800: '#1B4B32',
    900: '#0D2619',
  },
  gray: {
    50: '#F7F8F8',
    100: '#EAF0F0',
    200: '#DAE3E4',
    300: '#BFC4C6',
    400: '#8A8F91',
    500: '#5D5F60',
    600: '#323434',
    700: '#242525',
    800: '#1A1B1B',
    900: '#101111',
  },
  red: {
    50: '#FFEDED',
    100: '#FFBABA',
    200: '#FF8B8B',
    300: '#FF5C5C',
    400: '#FF3333',
    500: '#BF2828',
    600: '#A52424',
    700: '#8B2020',
    800: '#711C1C',
    900: '#571818',
  },
  lightMode: {
    bg: '#F7F0E6', // beige.100
    bgSecondary: '#B29D7D', // beige.300
    bgTertiary: '#B29D7D', // walnut.200
    text: '#161211', // beige.800
    textSecondary: '#332A26', // beige.600
    accent: '#9edfe9', // walnut.400
    link: '#57c3d4', // moonstone.400
  },
  darkMode: {
    bg: '#232C33', // gunmetal.600
    bgSecondary: '#1A1F22', // gunmetal.700
    text: '#FFFFFF', // white
    textSecondary: '#B29D7D', // beige.300
    primary: '#375566', // gunmetal.500
    secondary: '#57c3d4', // moonstone.400
    accent: '#B29D7D', // beige.300
    link: '#57c3d4', // moonstone.400
  },
};

export default colors;
