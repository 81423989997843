import { useState, useEffect, useCallback } from 'react';
import { getScriptById } from '../services/script.service';
import { useUserContext } from '../providers/UserProvider';

const useGetAllScripts = projects => {
  const { user, token } = useUserContext();
  const [scripts, setScripts] = useState({});
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const fetchScripts = useCallback(async () => {
    if (!user || !token || projects.length === 0) {
      return;
    }

    setLoading(true);
    setError(null);

    try {
      const scriptDetails = {};
      for (const project of projects) {
        for (const scriptId of project.scripts) {
          if (!scriptDetails[scriptId]) {
            const fetchedScript = await getScriptById(scriptId, token);
            scriptDetails[scriptId] = fetchedScript;
          }
        }
      }
      setScripts(scriptDetails);
    } catch (err) {
      console.error('Error fetching scripts:', err);
      setError(err);
    } finally {
      setLoading(false);
    }
  }, [projects, user, token]);

  useEffect(() => {
    fetchScripts();
  }, [fetchScripts]);

  return {
    scripts,
    loading,
    error,
  };
};

export default useGetAllScripts;
