import React from 'react';
import { useUserContext } from '../providers/UserProvider';
import { Flex, Spinner } from '@chakra-ui/react';

const Callback = () => {
  const { error, loading } = useUserContext();

  if (loading) {
    return (
        <Flex minHeight="100vh" align="center" justify="center">
            <Spinner size="xl" />
        </Flex>
    );
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  return <div>Login successful!</div>;
};

export default Callback;
