import React, { useState, useEffect, useMemo } from 'react';
import { Progress, Text, Flex, useBreakpointValue } from '@chakra-ui/react';

const GenerativeProgress = () => {
  const [progress, setProgress] = useState(0);
  const [currentQuote, setCurrentQuote] = useState('');

  const totalDuration = 20000; 
  const progressStep = 100 / (totalDuration / 2500); 

  const steps = useMemo(
    () => [
      [
        '"Just like dreaming of electric sheep."',
        '"Warming up the flux capacitor..."',
        '"Spawn more Overlords."',
        '"These go to eleven."',
        '"I am the one who knocks."',
      ],
      [
        '"This is your last chance. After this, there is no turning back."',
        '"Crunching numbers, or rather, creating universes..."',
        `"It's time to kick ass and chew bubble gum... and I'm all outta gum."`,
        `"Shop Smart. Shop S-Mart.  You got that?"`,
      ],
      [
        '"Open the pod bay doors, HAL."',
        '"Nuclear Launch Detected"',
        `"Where we're going, we don't need roads."`,
        `"Surely you can't be serious. I am serious...and don't call me Shirley."`,
      ],
      [
        `"I'll be back."`,
        '"Hail to the King, Baby."',
        '"Johnny 5 is alive!"',
        '"My life for Aiur"',
      ],
    ],
    []
  );

  useEffect(() => {
    const progressInterval = setInterval(() => {
      setProgress(oldProgress => {
        const newProgress = oldProgress + progressStep;
        if (newProgress >= 100) {
          clearInterval(progressInterval);
          setCurrentQuote(
            'Script complete! "No matter where you go, there you are."'
          );
        }
        return newProgress;
      });
    }, 2500); 

    return () => clearInterval(progressInterval);
  }, [progressStep]);

  useEffect(() => {
    if (progress < 100) {
      const stepIndex = Math.floor(progress / 25);
      const quotes = steps[stepIndex];
      const randomIndex = Math.floor(Math.random() * quotes.length);
      setCurrentQuote(quotes[randomIndex]);
    }
  }, [progress, steps]);

  const progressSize = useBreakpointValue({ base: 'sm', md: 'lg' });
  const textFontSize = useBreakpointValue({ base: 'sm', md: 'md' });

  return (
    <Flex direction="column" align="center" justify="center" width="100%">
      <Progress
        value={progress}
        size={progressSize}
        colorScheme="green"
        width="100%"
        rounded={'full'}
      />
      <Text fontSize={textFontSize} mt={4} textAlign="center" px={4}>
        {currentQuote}
      </Text>
    </Flex>
  );
};

export default GenerativeProgress;
