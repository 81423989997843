import React from 'react';
import { Box, useColorModeValue, Text } from '@chakra-ui/react';
import { MotionBox } from '../../config/config';

export default function AnimatedPricingSwitch({
  isYearly,
  toggleBillingPeriod,
}) {
  const activeColor = useColorModeValue('beige', 'beige.300');
  const textColor = useColorModeValue('gray.800', 'white');

  return (
    <Box
      display="flex"
      position="relative"
      borderRadius="full"
      overflow="hidden"
      width="200px"
      height="40px"
      bg={'whiteAlpha.300'}
      border={'1px'}
      borderColor={'whiteAlpha.300'}
    >
      <MotionBox
        layout
        initial={false}
        animate={{ x: isYearly ? '100%' : '0%' }}
        transition={{ type: 'spring', stiffness: 300, damping: 30 }}
        position="absolute"
        width="50%"
        height="100%"
        backgroundColor={activeColor}
        borderRadius="full"
      />
      <Box
        flex="1"
        textAlign="center"
        cursor="pointer"
        zIndex="1"
        onClick={() => toggleBillingPeriod(false)}
        color={textColor}
        lineHeight="40px"
      >
        <Text>Monthly</Text>
      </Box>
      <Box
        flex="1"
        textAlign="center"
        cursor="pointer"
        zIndex="1"
        onClick={() => toggleBillingPeriod(true)}
        color={textColor}
        lineHeight="40px"
      >
        <Text>Yearly</Text>
      </Box>
    </Box>
  );
}
