import { useState, useEffect, useCallback } from 'react';
import { getProjects } from '../services/project.service';
import { useUserContext } from '../providers/UserProvider';

const useGetProjects = () => {
  const { user, token } = useUserContext(); 
  const [projects, setProjects] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const fetchProjects = useCallback(async () => {
    if (!user || !token) {
      return;
    }

    setIsLoading(true);
    setError(null);

    try {
      const fetchedProjects = await getProjects(user._id, token);
      setProjects(fetchedProjects);
    } catch (err) {
      console.error("Error fetching projects:", err);
      setError(err);
    } finally {
      setIsLoading(false);
    }
  }, [user, token]);

  useEffect(() => {
    fetchProjects(); 
  }, [fetchProjects]);

  return {
    projects,
    setProjects,
    isLoading,
    error,
    refetch: fetchProjects
  };
};

export default useGetProjects;
