import React, { useState } from 'react';
import {
  Button,
  VStack,
  Text,
  Heading,
  useToast,
  Flex,
  HStack,
} from '@chakra-ui/react';
import { motion, AnimatePresence } from 'framer-motion';

const MotionVStack = motion(VStack);

const TriviaQuestion = ({
  question,
  answers,
  currentQuestion,
  setCurrentQuestion,
  setScore,
  score,
  xpPerQuestion,
  totalQuestions,
  onAnswerClick
}) => {
  const [selected, setSelected] = useState();
  const toast = useToast();

  const handleSelect = async (i) => {
    setSelected(i);

    console.log('XP per question in Trivia Question:', xpPerQuestion);

    const isCorrect = await onAnswerClick(question?._id, answers[i]);

    if (isCorrect) {
      setScore(score + 1);
      toast({
        title: `Correct! +${xpPerQuestion} XP`,
        status: 'success',
        duration: 2000,
        isClosable: true,
      });
    } else {
      toast({
        title: `Wrong Answer! The correct answer is: ${question.correctAnswer}`,
        status: 'error',
        duration: 2000,
        isClosable: true,
      });
    }
    setTimeout(() => {
      setSelected(undefined);
      setCurrentQuestion(currentQuestion + 1)
    }, 1000);
  };

  return (
    <Flex
      background="linear-gradient(180deg, rgba(156,219,255,0.35) 0%, rgba(37,52,60,0.35) 100%), #375566"
      direction="column"
      justify="center"
      align="center"
      flex={2}
      h="100%"
      p={10}
      overflowX={'hidden'}
    >
      <AnimatePresence mode='wait'>
        <MotionVStack
          key={currentQuestion}
          initial={{ x: '100vw', opacity: 0 }}
          animate={{ x: 0, opacity: 1 }}
          exit={{ x: '-100vw', opacity: 0 }}
          transition={{ duration: 0.5 }}
          spacing={4}
          align="stretch"
        >
          <Heading as="h1" size="lg" color="white" mb={4}>
            {question.question}
          </Heading>
          <VStack spacing={4} w="full">
            {answers.map((answer, index) => (
              <Button
                key={index}
                h="80px"
                borderRadius="lg"
                borderColor="beige.200"
                borderWidth={2}
                variant={selected === index ? 'solid' : 'outline'}
                color="white"
                bg={selected === index ? 'moonstone.200' : 'transparent'}
                _hover={{ bg: selected === index ? 'moonstone.300' : 'beige.300' }}
                onClick={() => handleSelect(index)}
                w="full"
                fontSize="xl"
              >
                {answer}
              </Button>
            ))}
          </VStack>
          <HStack justify="space-between" w="full">
            <Text color="white">Question {currentQuestion + 1} of {totalQuestions}</Text>
            <Text color="white" fontWeight="bold">
              🏆 +{xpPerQuestion} XP
            </Text>
          </HStack>
        </MotionVStack>
      </AnimatePresence>
    </Flex>
  );
};

export default TriviaQuestion;
