import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import {
  Button,
  Flex,
  HStack,
  Text,
  VStack,
  Spinner,
  useDisclosure,
  Modal,
  ModalOverlay,
  Box,
  ModalContent,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  useColorModeValue,
  useToast,
  Icon,
  Divider,
  ButtonGroup,
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { FPS, SPEED } from '../../config/config';
import { FaFileExport } from 'react-icons/fa6';
import { totalDuration } from '../../utils/app.utils';
import FlyoutMenu from '../FlyoutMenu/FlyoutMenu';
import ShotBlock from './ShotBlock';
import MusicSelection from '../MusicSelection/MusicSelection';
import ScriptEditorHeader from './ScriptEditorHeader';
import VoiceoverControls from './VoiceoverControls';
import PreviewControls from '../PreviewControls/PreviewControls';
import MusicControls from './MusicControls';
import ExportScriptModal from '../ExportScriptModal/ExportScriptModal';
import useShots from '../../hooks/useShots';
import useManageServices from '../../hooks/useManageServices';
import useManageMusic from '../../hooks/useManageMusic';
import useGetScriptById from '../../hooks/useGetScriptById';
import useSaveScript from '../../hooks/useSaveScript';
import useGenerateVoiceover from '../../hooks/useGenerateVoiceover';
import useManageVoiceover from '../../hooks/useManageVoiceover';
import useManageAd from '../../hooks/useManageAd';
import useDownloadScript from '../../hooks/useDownloadScript';
import { FaAd } from 'react-icons/fa';
import ExportAdModal from '../ExportAdModal/ExportAdModal';
import { useUserContext } from '../../providers/UserProvider';

const ScriptEditor = () => {
  const bgColor = useColorModeValue('gray.50', 'gunmetal.700');
  const borderColor = useColorModeValue('gray.200', 'gunmetal.550');
  const textColor = useColorModeValue('gray.800', 'white');
  const subTextColor = useColorModeValue('gray.600', 'gray.400');
  const hoverBgColor = useColorModeValue('gray.100', 'gunmetal.500');
  const buttonBgColor = useColorModeValue('beige.300', 'beige.300');
  const buttonHoverBgColor = useColorModeValue('beige.400', 'beige.400');
  const buttonDisabledBgColor = useColorModeValue('gray.200', 'gunmetal.550');
  const secondaryButtonBgColor = useColorModeValue('gunmetal.500', 'gray.600');
  const secondaryButtonHoverBgColor = useColorModeValue('gunmetal.600', 'gray.500');
  const errorColor = useColorModeValue('red.500', 'red.300');

  const toast = useToast();
  const { scriptId } = useParams();
  const navigate = useNavigate();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isFlyoutOpen,
    onOpen: onFlyoutOpen,
    onClose: onFlyoutClose,
  } = useDisclosure();
  const {
    isOpen: isExportScriptOpen,
    onOpen: onExportScriptOpen,
    onClose: onExportScriptClose,
  } = useDisclosure();
  const {
    isOpen: isExportAdOpen,
    onOpen: onExportAdOpen,
    onClose: onExportAdClose,
  } = useDisclosure();

  const { script, setScript, scriptLoading, error } =
    useGetScriptById(scriptId);
  const { updateScriptData, saveLoading } = useSaveScript();
  const { generateVoiceover, voiceoverLoading } = useGenerateVoiceover();
  const { addAudioToScript, removeAudioFromScript } = useManageVoiceover();
  const { addSongToScript, removeSongFromScript, musicLoading } =
    useManageMusic();
  const {
    fetchAdPreview,
    loading: previewLoading,
    setLoading: setPreviewLoading,
  } = useManageAd();
  const { newDownloadData } = useDownloadScript();

  const { subscription } = useUserContext();

  const [voiceoverUrl, setVoiceoverUrl] = useState(
    script?.voiceover?.audioPath || null
  );
  const [musicUrl, setMusicUrl] = useState(script?.music?.url || null);
  const [previewUrl, setPreviewUrl] = useState(script?.adPreview || null);

  const { shots, timeOverLimit, addShot, removeShot, updateShotDialogue } =
    useShots(script?.script, script, FPS, SPEED);

  const [voiceoverChanged, setVoiceoverChanged] = useState(false);
  const [musicChanged, setMusicChanged] = useState(false);

  const handleGeneratePreview = useCallback(async () => {
    setPreviewLoading(true);
    try {
      const response = await fetchAdPreview(scriptId, voiceoverUrl, musicUrl, 'mp3');
      if (response && response.script) {
        setPreviewUrl(response.script.adPreview);
        setScript(response.script);
        toast({
          title: 'Ad Preview Generated',
          description: 'Your ad preview has been generated successfully.',
          status: 'success',
          duration: 5000,
          isClosable: true,
        });

        // Reset the change flags
        setVoiceoverChanged(false);
        setMusicChanged(false);
      }
    } catch (error) {
      console.error('Error generating preview:', error);
      toast({
        title: 'Error',
        description: 'Failed to generate preview. Please try again.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setPreviewLoading(false);
    }
  }, [
    setPreviewLoading,
    fetchAdPreview,
    scriptId,
    voiceoverUrl,
    musicUrl,
    setScript,
    toast,
    setPreviewUrl,
  ]);

  useEffect(() => {
    if (script) {
      setVoiceoverUrl(script.voiceover ? script.voiceover.audioPath : null);
      setMusicUrl(script.music ? script.music.url : null);
      setPreviewUrl(script.adPreview || null);
    }
  }, [musicUrl, previewUrl, script, voiceoverUrl]);

  const handleScriptExport = async (type) => {
    if (scriptId && script?.title) {
      await newDownloadData(scriptId, type, script.title);
    }
  };

  const handleAddShot = () => {
    addShot(updatedShots => {
      handleSave(updatedShots);
    });
  };

  const handleRemoveShot = shotId => {
    removeShot(shotId, updatedShots => {
      handleSave(updatedShots);
    });
  };

  const handleDialogueSubmit = (value, shotId) => {
    updateShotDialogue(value, shotId, updatedShots => {
      handleSave(updatedShots);
    });
  };

  const handleBack = () => {
    navigate('/');
  };

  const handleSave = async (shotsToSave = shots) => {
    try {
      const updatedScriptData = {
        _id: scriptId,
        script: shotsToSave,
      };

      const savedScript = await updateScriptData(scriptId, updatedScriptData);

      // Update the script state while preserving existing properties
      setScript(prevScript => ({
        ...prevScript,
        script: savedScript.updatedScript.script, // Update only the 'script' field
      }));

    } catch (error) {
      console.error('Error saving shots:', error);
      toast({
        title: 'Error',
        description: 'Failed to save script. Please try again.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const handleEditDuration = async (duration) => {

    try {
      console.log('Duration:', duration);

      const updatedScriptData = {
        duration: duration,
      };

      const savedScript = await updateScriptData(scriptId, updatedScriptData);
      console.log('Saved Script:', savedScript);

      setScript(prevScript => ({
        ...prevScript,
        duration: savedScript.updatedScript.duration,
      }));
      
      // Show success toast
      toast({
        title: 'Duration updated.',
        description: 'Script duration has been updated successfully.',
        status: 'success',
        duration: 5000,
        isClosable: true,
      });

    } catch (error) {
      console.error('Error saving duration:', error);
      toast({
        title: 'Error',
        description: 'Failed to save duration. Please try again.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const getLoadingText = () => {
    if (saveLoading) return 'Saving...';
    if (voiceoverLoading) return 'Processing Voiceover...';
    if (musicLoading) return 'Processing Music...';
    return 'Loading...';
  };

  const { downloadAd, downloadLoading } = useManageAd();

  const handleAdExport = () => {
    if (!voiceoverUrl || !musicUrl) {
      toast({
        title: 'Error',
        description: 'Please generate a voiceover and select music before exporting the ad.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
      return;
    }
    onExportAdOpen();
  };

  const handleAdDownload = async (format) => {
    if (!voiceoverUrl || !musicUrl) {
      toast({
        title: 'Error',
        description:
          'Please generate a voiceover and select music before exporting the ad.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
      return;
    }
    try {
      const response = await downloadAd(scriptId, format);

      const blobData = response.data;

      // Retrieve filename from response headers
      const disposition = response.headers['content-disposition'];
      let filename = `${script.title}.${format}`; // Default filename
      if (disposition && disposition.includes('filename=')) {
        const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
        const matches = filenameRegex.exec(disposition);
        if (matches != null && matches[1]) {
          filename = decodeURIComponent(matches[1].replace(/['"]/g, ''));
        }
      }

      const url = window.URL.createObjectURL(new Blob([blobData]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', filename);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error('Error downloading ad:', error);
      toast({
        title: 'Error',
        description: 'Failed to download ad. Please try again.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const handleGenerateVoiceover = async () => {
    try {
      const newVoiceoverUrl = await generateVoiceover(script);

      if (newVoiceoverUrl) {
        setVoiceoverUrl(newVoiceoverUrl);
        setVoiceoverChanged(true);

        const audioData = {
          title: script.title,
          audioPath: newVoiceoverUrl,
        };

        // Add the audio to the project
        const addedVoiceover = await addAudioToScript({
          scriptId,
          audioData,
        });

        // Update the script state with the new voiceover data
        const updatedScript = {
          ...script,
          voiceover: addedVoiceover.script.voiceover,
        };

        setScript(updatedScript);

        toast({
          title: 'Voiceover Generated',
          description: 'The voiceover has been generated successfully.',
          status: 'success',
          duration: 5000,
          isClosable: true,
        });
      }
    } catch (error) {
      console.error('Error generating voiceover:', error);
      toast({
        title: 'Error',
        description: 'Failed to generate voiceover. Please try again.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const handleRemoveVoiceover = async () => {
    try {
      await removeAudioFromScript({
        scriptId,
        audioId: script.voiceover._id,
      });

      // Update the script state to remove the voiceover
      const updatedScript = {
        ...script,
        voiceover: null,
      };

      setScript(updatedScript);
      setVoiceoverUrl(null);

      toast({
        title: 'Voiceover Removed',
        description: 'The voiceover has been removed successfully.',
        status: 'success',
        duration: 5000,
        isClosable: true,
      });

      setVoiceoverChanged(true);
    } catch (error) {
      console.error('Error removing voiceover:', error);
      toast({
        title: 'Error',
        description: 'Failed to remove voiceover. Please try again.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const handleRegenerateVoiceover = async () => {
    try {
      // Remove the current voiceover from the DB
      const removedVoiceover = await removeAudioFromScript({
        scriptId,
        audioId: script.voiceover._id,
      });

      // Generate a new voiceover
      const newVoiceoverUrl = await generateVoiceover(script);

      if (newVoiceoverUrl) {
        setVoiceoverUrl(newVoiceoverUrl);
        setVoiceoverChanged(true);

        const audioData = {
          title: script.title,
          audioPath: newVoiceoverUrl,
        };

        // Add the audio to the project
        const addedVoiceover = await addAudioToScript({
          scriptId,
          audioData,
        });

        // Update the script state with the new voiceover data
        const updatedScript = {
          ...script,
          voiceoverAssignedAt: addedVoiceover.script.voiceoverAssignedAt,
          voiceover: addedVoiceover.script.voiceover,
        };

        setScript(updatedScript);

        toast({
          title: 'Voiceover Regenerated',
          description: 'The voiceover has been regenerated successfully.',
          status: 'success',
          duration: 5000,
          isClosable: true,
        });
      }
    } catch (error) {
      console.error('Error regenerating voiceover:', error);
      toast({
        title: 'Error',
        description: 'Failed to regenerate voiceover. Please try again.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const handleEditVoiceover = () => {
    console.log('Edit Voiceover');
    // Implement edit voiceover functionality here
  };

  const handleMusicSelect = async selectedMusic => {
    try {
      const musicId = selectedMusic._id;
      // Add the selected music to the project
      const addedMusic = await addSongToScript(scriptId, musicId);

      if (addedMusic) {

        // Update the local script state with the new music data
        const updatedScript = {
          ...script,
          musicAssignedAt: addedMusic.script.musicAssignedAt,
          music: {
            ...selectedMusic,
            _id: addedMusic.script.music,
          },
        };

        setScript(updatedScript);
        setMusicChanged(true);
      }
    } catch (error) {
      console.error('Error adding music:', error);
      toast({
        title: 'Error',
        description: 'Failed to add music. Please try again.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const handleRemoveMusic = async () => {
    try {
      const musicId = script.music._id;

      // Remove the music from the project
      await removeSongFromScript(scriptId, musicId);

      // Update the local script state to remove the music
      const updatedScript = {
        ...script,
        music: null,
      };

      setScript(updatedScript);
      setMusicUrl(null);
      setMusicChanged(true);
    } catch (error) {
      console.error('Error removing music:', error);
      toast({
        title: 'Error',
        description: 'Failed to remove music. Please try again.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const handleEditMusic = () => {
    console.log('Edit Music');
    // Implement edit music functionality here
  };

  const handleGenerateMusic = () => {
    console.log('Generate Music');
  };

  const handleRegenerateMusic = () => {
    console.log('Regenerate Music');
  };

  const handleMusicModalOpen = () => {
    onOpen();
  };

  if (scriptLoading) {
    return (
      <Flex justify="center" align="center" h="100vh" bg={bgColor}>
        <Spinner color={textColor} />
        <Text ml={3} color={subTextColor}>
          Loading...
        </Text>
      </Flex>
    );
  }

  if (error) {
    return (
      <Flex justify="center" align="center" h="100vh" bg={bgColor}>
        <Text color={errorColor}>Error: {error.message}</Text>
      </Flex>
    );
  }

  return (
    <>
      <Flex
        direction="column"
        p={4}
        borderRadius={'lg'}
        bg={bgColor}
        boxShadow="xl"
        border={'1px solid'}
        borderColor={borderColor}
        mr={20}
      >
        {/* Header */}
        <ScriptEditorHeader
          scriptTitle={script?.title}
          targetDuration={script?.duration}
          currentDuration={totalDuration(shots, FPS, SPEED)}
          timeOverLimit={timeOverLimit}
          onBack={handleBack}
          onSave={() => handleSave()}
          onEditDuration={handleEditDuration}
          saveLoading={saveLoading}
          voiceoverLoading={voiceoverLoading}
        />

        {/* Content */}
        <HStack align={'stretch'} justify={'stretch'} gap={8}>
          <VStack
            flex="2"
            spacing={4}
            mt={4}
            align="stretch"
            justify={'stretch'}
          >
            {shots
              .filter(shot => shot.audio.some(item => item.type === 'DIALOGUE'))
              .map(shot => (
                <ShotBlock
                  key={shot._id}
                  shot={shot}
                  onDialogueSubmit={handleDialogueSubmit}
                  onRemoveShot={handleRemoveShot}
                  FPS={FPS}
                  SPEED={SPEED}
                />
              ))}

            <Flex
              bg={'transparent'}
              flexDirection={'column'}
              p={4}
              align={'center'}
              justify={'center'}
              borderRadius="md"
              borderColor={timeOverLimit ? 'gray.400' : borderColor}
              borderWidth="1px"
              borderStyle={'dashed'}
              transition={'all 0.3s'}
              onClick={handleAddShot}
              _hover={{
                bg: hoverBgColor,
                cursor: 'pointer',
                borderColor: borderColor,
                borderWidth: '1px',
                borderStyle: 'solid',
              }}
              opacity={1}
            >
              <Text>Add Script Block</Text>
            </Flex>
          </VStack>

          <VStack
            mt={0}
            flex={'1'}
            spacing={4}
            align={'stretch'}
            justify={'stretch'}
          >
            <VoiceoverControls
              voiceoverUrl={voiceoverUrl}
              onRemoveVoiceover={handleRemoveVoiceover}
              onRegenerateVoiceover={handleRegenerateVoiceover}
              onGenerateVoiceover={handleGenerateVoiceover}
              voiceoverLoading={voiceoverLoading}
              timeOverLimit={timeOverLimit}
              handleEditVoiceover={handleEditVoiceover}
              duration={script?.duration}
            />

            <Divider />

            <MusicControls
              musicUrl={musicUrl}
              onRemoveMusic={handleRemoveMusic}
              onSelectMusic={handleMusicSelect}
              musicLoading={musicLoading}
              onGenerateMusic={handleGenerateMusic}
              onOpenMusicModal={handleMusicModalOpen}
              onEditMusic={handleEditMusic}
              duration={script?.duration}
              timeOverLimit={timeOverLimit}
            />

            <Divider />

            <PreviewControls
              previewUrl={previewUrl}
              timeOverLimit={timeOverLimit}
              onGeneratePreview={handleGeneratePreview}
              previewLoading={previewLoading}
              voiceoverUrl={voiceoverUrl}
              musicUrl={musicUrl}
              voiceoverChanged={voiceoverChanged}
              musicChanged={musicChanged}
            />

            <Flex w="full" flexDirection={'column'}>
              <Button
                bg={buttonBgColor}
                _hover={{ bg: buttonHoverBgColor }}
                borderRight={'1px solid'}
                borderColor={borderColor}
                onClick={handleAdExport}
                leftIcon={<Icon as={FaAd} />}
                isDisabled={!voiceoverUrl || !musicUrl}
                isLoading={downloadLoading || saveLoading || voiceoverLoading || musicLoading}
                loadingText={getLoadingText()}
                mt={4}
                py={10}
                flex={1}
              >
                Export Ad
              </Button>

              <Button
                bg={secondaryButtonBgColor}
                _hover={{ bg: secondaryButtonHoverBgColor }}
                onClick={onExportScriptOpen}
                leftIcon={<Icon as={FaFileExport} />}
                isLoading={saveLoading || voiceoverLoading || musicLoading}
                loadingText={getLoadingText()}
                mt={2}
                py={4}
                flex={1}
              >
                Download Script
              </Button>
            </Flex>
          </VStack>
        </HStack>

        {/* Modal for Music Selection */}
        <Modal isOpen={isOpen} onClose={onClose} size="full">
          <ModalOverlay />
          <ModalContent bg={bgColor}>
            <ModalCloseButton />
            <ModalBody>
              <MusicSelection
                onMusicSelect={handleMusicSelect}
                onMusicRemove={handleRemoveMusic}
                selectedMusicUrl={musicUrl}
                duration={script?.duration.slice(0, -1)}
              />
            </ModalBody>
            <ModalFooter>
              <Button onClick={onClose}>Close</Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </Flex>

      <Box
        position="fixed"
        bottom="50%"
        right="-50px"
        transform={'rotate(-90deg)'}
      >
        <Button
          bg={'beige.300'}
          _hover={{ bg: 'beige.400' }}
          onClick={onFlyoutOpen}
        >
          Project Settings
        </Button>
      </Box>

      <FlyoutMenu isOpen={isFlyoutOpen} onClose={onFlyoutClose} />
      <ExportScriptModal
        isOpen={isExportScriptOpen}
        onClose={onExportScriptClose}
        onExport={handleScriptExport}
        mediaType={'Script'}
      />
      <ExportAdModal
        isOpen={isExportAdOpen}
        onClose={onExportAdClose}
        onExport={handleAdDownload}
        mediaType={'Ad'}
      />
    </>
  );
};

export default ScriptEditor;
