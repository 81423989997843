import { useCallback, useState } from 'react';
import { useUserContext } from '../providers/UserProvider';
import { useToast } from '@chakra-ui/react';
import { processStripeSuccess } from '../services/billing.service';

const useStripeSuccess = () => {
  const { user, setUser } = useUserContext();
  const [loading, setLoading] = useState(false);
  const toast = useToast();

  const handleStripeSuccess = useCallback(
    async (sessionId, token) => {
      setLoading(true);
      try {
        const response = await processStripeSuccess(sessionId, token);

        if (response.user) {
          setUser(response.user);
        }

        toast({
          title: 'Subscription Successful',
          description: response.message,
          status: 'success',
          duration: 5000,
          isClosable: true,
        });

        return true;
      } catch (error) {
        console.error('Error processing Stripe success:', error);
        toast({
          title: 'Subscription Failed',
          description: 'Your purchase was not successful. Please try again.',
          status: 'error',
          duration: 5000,
          isClosable: true,
        });

        return false;
      } finally {
        setLoading(false);
      }
    },
    [setUser, toast]
  );

  return { handleStripeSuccess, loading };
};

export default useStripeSuccess;
