import api from '../utils/api.utils';
import { handleResponse } from '../utils/app.utils';

export const getUserReferrals = async ({ userId, token }) => {
  try {
    const response = await api.get(`/referrals/${userId}`, {
      customHeaders: { token },
    });
    return handleResponse(response, 'Error fetching user referrals');
  } catch (error) {
    console.error('Error fetching user referrals:', error.message);
    throw new Error('Failed to fetch user referrals');
  }
};

export const createReferral = async ({ referrerId, referredEmail, token }) => {
  try {
    const response = await api.post(
      '/referrals',
      { referrerId, referredEmail },
      {
        customHeaders: { token },
      }
    );
    return handleResponse(response, 'Error creating referral');
  } catch (error) {
    console.error('Error creating referral:', error.message);
    throw new Error('Failed to create referral');
  }
};

export const completeReferral = async ({
  referralCode,
  referredUserEmail,
  token,
}) => {
  try {
    const response = await api.post(
      `/referrals/complete`,
      { referralCode, referredUserEmail },
      {
        customHeaders: { token },
      }
    );
    return handleResponse(response, 'Error completing referral');
  } catch (error) {
    console.error('Error completing referral:', error.message);
    throw new Error('Failed to complete referral');
  }
};

export const deleteReferral = async ({ userId, referralId, token }) => {
  try {
    const response = await api.delete(`/referrals/${userId}/${referralId}`, {
      customHeaders: { token },
    });
    return handleResponse(response, 'Error deleting referral');
  } catch (error) {
    console.error('Error deleting referral:', error.message);
    throw new Error('Failed to delete referral');
  }
};