import { useEffect, useState } from 'react';
import {
  Box,
  Badge,
  Button,
  Card,
  CardBody,
  CardHeader,
  Divider,
  Flex,
  Heading,
  Stack,
  Text,
  useColorModeValue,
  keyframes,
  HStack,
  VStack,
  Skeleton,
} from '@chakra-ui/react';
import { CheckIcon } from '@chakra-ui/icons';
import AnimatedPricingSwitch from '../AnimatedPricingSwitch/AnimatedPricingSwitch';
import useGetPlans from '../../hooks/useGetPlans';
import useStripeCheckout from '../../hooks/useStripeCheckout';

const lightSweep = keyframes`
  0% { background-position: -200% 0; }
  100% { background-position: 200% 0; }
`;

export default function PricingTable() {
  const { plans, loading } = useGetPlans();
  const { handleStripeCheckout, loading: checkoutLoading } = useStripeCheckout();
  const [isYearly, setIsYearly] = useState(false);
  const textColor = useColorModeValue('gunmetal.500', 'beige.200');
  const proBgColor = useColorModeValue('gray.100', 'whiteAlpha.100');

  const toggleBillingPeriod = () => setIsYearly(!isYearly);

  const getPrice = (prices) => {
    const price = prices.find(p => p.interval === (isYearly ? 'year' : 'month'));
    return price ? `$${price.cost}` : 'N/A';
  };

  const getBillingPeriod = () => (isYearly ? 'year' : 'month');

  const calculateYearlySavings = (monthlyPrice, yearlyPrice) => {
    const yearlyCost = monthlyPrice * 12;
    const savings = yearlyCost - yearlyPrice;
    return Math.round((savings / yearlyCost) * 100);
  };

  const handleSubscribe = async (planId) => {
    const returnUrl = `${window.location.origin}/subscription-success`;
    const planInterval = isYearly ? 'year' : 'month';

    const responseUrl = await handleStripeCheckout({
      planId,
      planInterval,
      products: [],
      returnUrl,
    });

    if (responseUrl) {
      window.location.href = responseUrl;
    } else {
      console.error('Error initiating checkout session');
    }
  };

  return (
    <Box maxW="100%" mx="auto" py={16} px={4}>
      <>
      <Heading as="h2" size="xl" textAlign="center" mb={8}>
        Choose the Perfect Plan for Your Content Needs
      </Heading>

      <Flex justify="center" align="center" mb={12} flexDirection={'column'}>
        <AnimatedPricingSwitch
          isYearly={isYearly}
          toggleBillingPeriod={toggleBillingPeriod}
        />
      </Flex>

      <Flex wrap="wrap" justify="center" spacing={8}>
        {plans?.map((plan, index) => (
          loading ? (
            <Skeleton height="500px" rounded={'2rem'} key={index} flex={'1 1 30%'} m={4} />
          ) : (
            <Card
            key={plan.stripeProductId}
            borderWidth={index === 1 ? '2px' : '1px'} // Highlight the second tier
            borderColor={index === 1 ? 'whiteAlpha.300' : 'transparent'}
            flex="1 1 30%"
            m={4}
            maxW="sm"
            bg={index === 1 ? proBgColor : 'transparent'}
            borderRadius="2rem"
            py={6}
            px={4}
            shadow={'none'}
          >
            <CardHeader>
              <Flex justify="space-between" align="center">
                <Heading as="h3" size="lg" mb={2}>
                  {plan.name}
                </Heading>
                {index === 1 && ( // Display badge for the second tier
                  <Badge
                    colorScheme="teal"
                    variant="solid"
                    bgGradient="linear(to-r, gunmetal.500, gunmetal.400, gunmetal.500)"
                    border="2px solid"
                    borderColor="whiteAlpha.300"
                    p={2}
                    rounded={'full'}
                    bgSize="200% auto"
                    animation={`${lightSweep} 10s linear infinite`}
                  >
                    Most Popular
                  </Badge>
                )}
              </Flex>

              <HStack align={'center'}>
                <Text fontSize="5xl" fontWeight="bold">
                  {getPrice(plan.prices)}
                </Text>

                <VStack align={'flex-start'} gap={0}>
                  <Text fontSize={'sm'}>per</Text>
                  <Text fontSize="sm" fontWeight="normal">
                    {getBillingPeriod()}
                  </Text>
                </VStack>
                
              </HStack>

              {isYearly &&
                plan.prices.some(p => p.interval === 'month') &&
                plan.prices.some(p => p.interval === 'year') && (
                  <Text fontSize="sm" color="moonstone.300">
                    Save{' '}
                    {calculateYearlySavings(
                      plan.prices.find(p => p.interval === 'month').cost,
                      plan.prices.find(p => p.interval === 'year').cost
                    )}
                    %
                  </Text>
                )}
            </CardHeader>

            <Divider borderColor={'whiteAlpha.300'} />

            <CardBody>
              <Text mb={4} color={textColor} height={'100px'}>
                {plan.description}
              </Text>
              <Button
                w="full"
                bg="beige.200"
                color={'gunmetal.600'}
                variant={'solid'}
                rounded={'full'}
                mb={8}
                _hover={{ bg: 'beige.300', color: 'white' }}
                onClick={() => handleSubscribe(plan._id)}
                isLoading={checkoutLoading}
                isDisabled={checkoutLoading}
                loadingText="Redirecting..."
              >
                Get Started
              </Button>
              <Stack spacing={2}>
                {plan.marketingFeatures.map((feature) => (
                  <Flex key={feature._id} align="center">
                    <CheckIcon color="teal.500" mr={2} />
                    <Text>{feature.name}</Text>
                  </Flex>
                ))}
              </Stack>
            </CardBody>
          </Card>
          )
          
        ))}
      </Flex>
      </>
    </Box>
  );
}
