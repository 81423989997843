import React, { useEffect, useState, useRef } from 'react';
import {
  Flex,
  Icon,
  Text,
  Tooltip,
  Spinner,
  Badge,
  useBreakpointValue,
  useColorModeValue,
  Box,
  VStack,
  HStack,
  Button,
  Divider,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
} from '@chakra-ui/react';
import { motion, AnimatePresence } from 'framer-motion';
import { PiCoinVerticalLight, PiCreditCardFill } from 'react-icons/pi';
import { useLocation, useNavigate } from 'react-router-dom';
import { useUserContext } from '../../providers/UserProvider';
import { useReward } from 'react-rewards';
import PurchaseModal from '../PurchaseModal/PurchaseModal';
import Odometer from '../Odometer/Odometer';
import useGetProducts from '../../hooks/useGetProducts';
import useStripeCheckout from '../../hooks/useStripeCheckout';

const MotionText = motion(Text);

const CreditsInfo = ({ user, subscription, loading }) => {
  const flexDirection = useBreakpointValue({ base: 'column', md: 'row' });
  const alignItems = useBreakpointValue({ base: 'flex-start', md: 'center' });
  const textSize = useBreakpointValue({ base: 'sm', md: 'md' });
  const badgeMargin = useBreakpointValue({ base: '0', md: '4' });
  const borderColor = useColorModeValue('gunmetal.500', 'white');
  const bgColor = useColorModeValue('transparent', 'transparent');
  const textColor = useColorModeValue('gunmetal.500', 'white');
  const iconColor = useColorModeValue('gunmetal.500', 'white');
  const contentHeaderColor = useColorModeValue('gunmetal.500', 'white');

  const { refreshUserData } = useUserContext();
  const { products, loadingProducts } = useGetProducts();
  const {
    handleStripeCheckout,
    handleStripeSession,
    loading: sessionLoading,
  } = useStripeCheckout();
  const { reward } = useReward('rewardId', 'confetti');
  const isMounted = useRef(false);
  const location = useLocation();
  const navigate = useNavigate();

  const [selectedProducts, setSelectedProducts] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [showCancelModal, setShowCancelModal] = useState(false);
  const [creditsPurchased, setCreditsPurchased] = useState(0);
  const [xpGained, setXpGained] = useState(0);
  const [change, setChange] = useState(0); // State to track change in credits
  const [totalCredits, setTotalCredits] = useState(
    user?.purchasedCredits + user?.subscriptionCredits || 0
  );

  useEffect(() => {
    isMounted.current = true;
    return () => (isMounted.current = false);
  }, []);

  const onOpen = () => setIsOpen(true);
  const onClose = () => setIsOpen(false);

  // Handle quantity change
  const handleQuantityChange = (productId, value) => {
    const quantity = parseInt(value) || 0;
    setSelectedProducts(prev => {
      const updatedProducts = prev.filter(item => item.productId !== productId);
      if (quantity > 0) {
        updatedProducts.push({ productId, quantity });
      }
      return updatedProducts;
    });
  };

  // Calculate grand total price
  const displayGrandTotalPrice = () => {
    return selectedProducts.reduce((total, item) => {
      const product = products.find(p => p._id === item.productId);
      const price = product?.prices[0]?.cost || 0;
      return total + price * item.quantity;
    }, 0);
  };

  // Handle purchase
  const handlePurchase = async () => {
    const returnUrl = `${window.location.origin}${window.location.pathname}`;
    const url = await handleStripeCheckout({
      products: selectedProducts,
      returnUrl,
    });

    if (url) {
      window.location.href = url;
    }
  };

  // Handle success and cancel redirections
  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const sessionId = params.get('session_id');
    const isSuccess = params.get('success') === 'true';
    const isCanceled = params.get('canceled') === 'true';

    if (isSuccess && sessionId) {
      setShowSuccessModal(true);

      // Retrieve session data and calculate credits
      const fetchSessionData = async () => {
        const sessionData = await handleStripeSession(sessionId);

        if (
          sessionData &&
          sessionData.session &&
          sessionData.session.line_items
        ) {
          setCreditsPurchased(sessionData.totalCreditsPurchased);
          setXpGained(sessionData.totalXpGained);

          // Update credits and trigger animation
          const newTotalCredits =
            (user?.purchasedCredits + user?.subscriptionCredits || 0) +
            sessionData.totalCreditsPurchased;
          setChange(sessionData.totalCreditsPurchased);
          setTotalCredits(newTotalCredits);
        }

        // Fetch user data after updating credits
        try {
          await refreshUserData();
        } catch (error) {
          console.error('Error fetching user data:', error);
        }
      };

      fetchSessionData();

      navigate(location.pathname, { replace: true });
    } else if (isCanceled) {
      setShowCancelModal(true);
      navigate(location.pathname, { replace: true });
    }
  }, [handleStripeSession, refreshUserData, location.pathname, navigate, user]);

  // Trigger confetti reward on success
  useEffect(() => {
    if (showSuccessModal && isMounted.current) {
      const timer = setTimeout(() => {
        reward();
      }, 100);

      return () => clearTimeout(timer);
    }
  }, [showSuccessModal, reward]);

  // Reset the change value after the animation is complete
  useEffect(() => {
    if (change !== 0) {
      const timer = setTimeout(() => {
        setChange(0);
      }, 2000); // Duration should match the animation duration
      return () => clearTimeout(timer);
    }
  }, [change]);

  // Update totalCredits when user data changes
  useEffect(() => {
    const updatedCredits =
      user?.purchasedCredits + user?.subscriptionCredits || 0;
    if (updatedCredits !== totalCredits) {
      const creditDifference = updatedCredits - totalCredits;
      if (creditDifference !== 0) {
        setChange(creditDifference);
        setTotalCredits(updatedCredits);
      }
    }
  }, [user, totalCredits]);

  if (loading || loadingProducts) {
    return <Spinner color={textColor} />;
  }

  return (
    <Flex
      direction={flexDirection}
      align={alignItems}
      minW={{ base: '100px', md: '300px' }}
      flex={1}
      p={4}
    >
      {subscription && (
        <>
          <Box
            _hover={{ cursor: 'pointer' }}
            onClick={onOpen}
            position="relative"
          >
            <Tooltip
              label="Each script or voiceover consumes credits. Your credits renew each month."
              aria-label="Credits Remaining"
            >
              <Flex
                align="center"
                justify={{ base: 'stretch', md: 'stretch' }}
                borderColor={borderColor}
                borderWidth="1px"
                rounded="lg"
                p={2}
                mb={{ base: 2, md: 0 }}
                bg={bgColor}
                position="relative"
              >
                <Icon
                  as={PiCoinVerticalLight}
                  w={6}
                  h={6}
                  mr={1}
                  color={iconColor}
                />
                <Odometer value={totalCredits} size="md" />
                <Text fontSize={textSize} color={textColor}>
                  Credits Remaining
                </Text>
              </Flex>
            </Tooltip>

            <AnimatePresence>
              {change !== 0 && (
                <MotionText
                  key={`change-${change}`}
                  initial={{ opacity: 0, y: change > 0 ? 20 : -20 }}
                  animate={{ opacity: 1, y: 0 }}
                  exit={{ opacity: 0, y: change > 0 ? -20 : 20 }}
                  transition={{ duration: 1 }}
                  position="absolute"
                  top={change > 0 ? '100%' : '100%'}
                  left="25%"
                  transform="translateX(-50%)"
                  color={change > 0 ? 'green.500' : 'red.500'}
                  fontSize="1em"
                  fontWeight="bold"
                >
                  {change > 0 ? `+${change}` : `${change}`}
                </MotionText>
              )}
            </AnimatePresence>
          </Box>

          {/* Purchase Modal */}
          <Modal isOpen={isOpen} onClose={onClose} size="lg">
            <ModalOverlay />
            <ModalContent>
              <ModalHeader
                textAlign="left"
                fontWeight="bold"
                color={contentHeaderColor}
                m={0}
              >
                Purchase More Credits
              </ModalHeader>
              <ModalCloseButton />
              <ModalBody mb={4}>
                <VStack spacing={2} align="stretch">
                  <Box>
                    <Text textAlign="left">
                      Choose a credit pack to continue creating amazing podcast
                      and brand ads.
                    </Text>

                    <Divider my={4} />

                    {products.map((product, index) => {
                      const selectedProduct = selectedProducts.find(
                        p => p.productId === product._id
                      );
                      const quantity = selectedProduct?.quantity || 0;
                      const price = product.prices[0].cost;

                      return (
                        <HStack
                          key={index}
                          justify="space-between"
                          align="center"
                          py={2}
                          px={4}
                          mb={2}
                          borderWidth="1px"
                          borderColor="gray.600"
                          rounded="lg"
                          _hover={{
                            cursor: 'pointer',
                            bg: 'gunmetal.500',
                            color: 'white',
                          }}
                          gap={4}
                        >
                          <HStack align="flex-start" flex={1}>
                            <Icon
                              as={PiCoinVerticalLight}
                              w={6}
                              h={6}
                              mr={1}
                              color={iconColor}
                            />
                            <Text fontWeight="bold" fontSize="lg">
                              {product.name}
                            </Text>
                          </HStack>

                          <HStack flex={1} gap={4}>
                            <NumberInput
                              min={0}
                              max={100}
                              step={1}
                              flex={1}
                              value={quantity}
                              onChange={valueString =>
                                handleQuantityChange(product._id, valueString)
                              }
                            >
                              <NumberInputField />
                              <NumberInputStepper>
                                <NumberIncrementStepper />
                                <NumberDecrementStepper />
                              </NumberInputStepper>
                            </NumberInput>
                            <Text fontSize="md" flex={1} textAlign="right">
                              ${price.toFixed(2)} per pack
                            </Text>
                          </HStack>
                        </HStack>
                      );
                    })}

                    <Divider my={4} />

                    <HStack justify="space-between" px={4}>
                      <Text fontSize="lg" fontWeight="bold">
                        Grand Total:
                      </Text>
                      <Text fontSize="lg" fontWeight="bold">
                        ${displayGrandTotalPrice().toFixed(2)}
                      </Text>
                    </HStack>

                    <Button
                      w="100%"
                      bg="gunmetal.550"
                      color="white"
                      _hover={{
                        bg: 'gunmetal.500',
                      }}
                      onClick={handlePurchase}
                      mt={4}
                      isDisabled={selectedProducts.length === 0}
                    >
                      <Icon as={PiCreditCardFill} mr={2} />
                      <Text>Purchase</Text>
                    </Button>
                  </Box>
                </VStack>
              </ModalBody>
            </ModalContent>
          </Modal>
        </>
      )}

      {subscription ? (
        <Tooltip
          label="Your current subscription plan. Upgrade for more features."
          aria-label="Subscription Plan"
        >
          <Badge colorScheme="green" ml={badgeMargin} mt={{ base: 2, md: 0 }}>
            {subscription?.planName}
          </Badge>
        </Tooltip>
      ) : (
        <Badge colorScheme="red" ml={badgeMargin} mt={{ base: 2, md: 0 }}>
          No Subscription
        </Badge>
      )}

      <span
        id="rewardId"
        style={{
          position: 'fixed',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          zIndex: 9999,
        }}
      />

      {/* Success Modal */}
      <PurchaseModal
        isOpen={showSuccessModal}
        onClose={() => setShowSuccessModal(false)}
        title="Purchase Successful"
        message="Your purchase was successful!"
        credits={creditsPurchased}
        xp={xpGained}
        loading={sessionLoading}
      />

      {/* Cancellation Modal */}
      <PurchaseModal
        isOpen={showCancelModal}
        onClose={() => setShowCancelModal(false)}
        title="Purchase Canceled"
        message="Your purchase was canceled. Please try again if you wish to buy credits."
      />
    </Flex>
  );
};

export default CreditsInfo;
