import React from 'react';
import {
  Box,
  HStack,
  Text,
  IconButton,
  Button,
  Flex,
  Tooltip,
  Heading,
  Spinner,
  useColorModeValue,
} from '@chakra-ui/react';
import { CloseIcon } from '@chakra-ui/icons';
import { FaArrowsRotate, FaGear } from 'react-icons/fa6';
import AudioPlayer, { RHAP_UI } from 'react-h5-audio-player';
import { Global } from '@emotion/react';
import 'react-h5-audio-player/lib/styles.css'; 
import useManageServices from '../../hooks/useManageServices';

const VoiceoverControls = ({
  voiceoverUrl,
  onRemoveVoiceover,
  onRegenerateVoiceover,
  onGenerateVoiceover,
  voiceoverLoading,
  timeOverLimit,
  handleEditVoiceover,
  duration
}) => {

  const borderColor = useColorModeValue('gray.200', 'gunmetal.550');
  const hoverBgColor = useColorModeValue('gray.100', 'gunmetal.500');
  const textColor = useColorModeValue('gray.800', 'white');
  const subTextColor = useColorModeValue('gray.600', 'gray.400');
  const iconHoverBgColor = useColorModeValue('gray.300', 'gray.500');
  const playerBgColor = useColorModeValue('gray.300', '#232C33');
  const controlColor = useColorModeValue('gray.800', 'white');
  const controlHoverColor = useColorModeValue('gray.600', 'gray.300');
  const progressBarBg = useColorModeValue('gray.300', 'gray.600');
  const progressFilledBg = useColorModeValue('blue.500', 'blue.300');
  const boxBorderColor = useColorModeValue('gray.200', '#2F4957');

  const { services, loading } = useManageServices()

  const voiceoverCost = ({type, duration}) => {
    const service = services?.find(service => service.type === type)
    const serviceCost = service?.costs?.find(cost => cost.duration === duration.toString().split('s')[0])
    return serviceCost?.cost;
  }

  return (
    <>
      {/* Global Styles for Audio Player */}
      <Global
        styles={`
          .rhap_container {
            background-color: ${playerBgColor} !important;
            border-radius: 8px;
            border: 1px solid ${boxBorderColor};
            color: ${controlColor};
          }
          .rhap_additional-controls {
            display: none !important;
          }
          .rhap_volume-controls {
            padding-right: 10px !important;
          }
          .rhap_button-clear {
            color: ${controlColor} !important;
          }
          .rhap_time {
            color: ${controlColor} !important;
          }
          .rhap_button-clear:hover {
            color: ${controlHoverColor} !important;
          }
          .rhap_progress-bar {
            background: ${progressBarBg} !important;
          }
          .rhap_progress-filled {
            background: ${progressFilledBg} !important;
          }
        `}
      />

      {voiceoverUrl ? (
        <Box position="relative">
          <HStack align="center" justify="space-between" mb={2}>
          <Text
          textAlign={'center'}
          fontSize="sm"
          fontWeight="bold"
          mb={2}
        >
              Voiceover
            </Text>

            <HStack>
              <IconButton
                icon={<FaGear />}
                aria-label="Edit"
                size="sm"
                borderRadius="md"
                onClick={handleEditVoiceover}
                variant="outline"
                _hover={{ bg: iconHoverBgColor }}
              />
              <IconButton
                icon={<CloseIcon />}
                aria-label="Remove voiceover"
                size="sm"
                onClick={onRemoveVoiceover}
                variant="outline"
                borderRadius="md"
                _hover={{ bg: 'red.600' }}
              />
            </HStack>
          </HStack>

          <AudioPlayer
            src={voiceoverUrl}
            autoPlayAfterSrcChange={false}
            showJumpControls={false}
            customAdditionalControls={[
              ...RHAP_UI.ADDITIONAL_CONTROLS,
            ]}
            layout="horizontal-reverse"
          />

          <Flex
            flexDirection="column"
            justify="center"
            align="stretch"
            mb={4}
            mt={2}
          >
            <Button
              onClick={onRegenerateVoiceover}
              isLoading={voiceoverLoading}
              loadingText="Regenerating..."
              bg="blue.500"
              color="white"
              _hover={{ bg: 'blue.600' }}
              leftIcon={<FaArrowsRotate />}
              mb={2}
            >
              Regenerate Voiceover
            </Button>
            <Text fontSize="sm" color={subTextColor} textAlign="center">
              {voiceoverCost({type: 'VOICE_OVER_GENERATION', duration})} Credits
            </Text>
          </Flex>
        </Box>
      ) : (
        <Tooltip
          label="Duration exceeds the target limit. Please adjust your script."
          isDisabled={!timeOverLimit}
          shouldWrapChildren
        >
          <Flex
            bg="transparent"
            flexDirection="column"
            p={4}
            align="center"
            justify="center"
            borderRadius="md"
            borderColor={timeOverLimit ? 'gray.400' : borderColor}
            borderWidth="1px"
            borderStyle="dashed"
            transition="all 0.3s"
            onClick={!timeOverLimit ? onGenerateVoiceover : undefined}
            _hover={{
              bg: !timeOverLimit ? hoverBgColor : 'transparent',
              cursor: timeOverLimit ? 'not-allowed' : 'pointer',
              borderColor: timeOverLimit ? 'gray.400' : borderColor,
              borderWidth: '1px',
              borderStyle: 'solid',
            }}
            opacity={timeOverLimit ? 0.6 : 1}
          >
            {voiceoverLoading ? (
              <Spinner size="sm" />
            ) : (
              <>
              <Heading size="md" color={textColor}>
                  Generate Voiceover
                </Heading>
                <Text textAlign="center" color={subTextColor}>
                  Costs {voiceoverCost({type: 'VOICE_OVER_GENERATION', duration})} credits
                </Text>
              </>
            )}
          </Flex>
        </Tooltip>
      )}
    </>
  );
};

export default VoiceoverControls;
