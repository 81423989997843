import React, { useState } from 'react';
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  VStack,
  Text,
  Link,
  HStack,
  IconButton,
  useToast,
} from '@chakra-ui/react';
import { CopyIcon } from '@chakra-ui/icons';
import ShareToSocialMedia from '../ShareToSocialMedia/ShareToSocialMedia';

const ReferralCode = ({ referralHook }) => {
  const [referralEmail, setReferralEmail] = useState('');
  const { createNewReferral, isLoading, referralLink } = referralHook;
  const toast = useToast();

  const handleCreateReferral = async () => {
    try {
      await createNewReferral(referralEmail);
      setReferralEmail('');
    } catch (err) {
      console.log(err);
    }
  };

  const handleCopyReferralLink = () => {
    navigator.clipboard.writeText(referralLink);
    toast({
      title: 'Referral Link Copied',
      status: 'success',
      duration: 3000,
      isClosable: true,
    });
  };

  return (
    <VStack spacing={4} align="stretch" flex={1} mt={8} mb={16}>
      <Box>
        <FormControl id="referralEmail">
          <FormLabel>Referral Email</FormLabel>
          <Input
            type="email"
            value={referralEmail}
            onChange={e => setReferralEmail(e.target.value)}
          />
        </FormControl>
        <Button mt={4} onClick={handleCreateReferral} isLoading={isLoading}>
          Create Referral
        </Button>
      </Box>
      {referralLink && (
        <HStack mt={4}>
          <Text fontSize="md" color="gray.400">
            Referral Link: <Link to={referralLink}>{referralLink}</Link>
          </Text>
          <IconButton
            icon={<CopyIcon />}
            aria-label="Copy Referral Link"
            variant="ghost"
            size="sm"
            colorScheme="gray"
            borderRadius="md"
            onClick={handleCopyReferralLink}
            isLoading={isLoading}
          />
        </HStack>
      )}
      <ShareToSocialMedia />
    </VStack>
  );
};

export default ReferralCode;
