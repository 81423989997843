import React, { useState, useRef, useEffect } from 'react';
import {
  Box,
  Heading,
  IconButton,
  Image,
  Text,
  useColorModeValue,
  Flex,
  Button,
  Skeleton,
  Link as ChakraLink,
  HStack,
} from '@chakra-ui/react';
import { FaChevronLeft, FaChevronRight, FaArrowRight } from 'react-icons/fa6';
import { Link as RouterLink } from 'react-router-dom';
import ProjectPlaceholderBeige from '../../assets/ProjectPlaceholder-Beige.jpg';
import ProjectPlaceholderBlue from '../../assets/ProjectPlaceholder-Blue.jpg';

const RecentProjectsSlider = ({ projects = [], loading }) => {
  const cardBg = useColorModeValue('gray.100', 'gray.700');
  const bgColor = useColorModeValue('beige.100', 'gray.800');
  const textColor = useColorModeValue('gray.800', 'white');
  const iconBgColor = useColorModeValue('rgba(230, 230, 230, 0.8)', 'rgba(31, 41, 55, 0.8)');

  const [currentIndex, setCurrentIndex] = useState(0);
  const sliderRef = useRef(null);
  const [isDragging, setIsDragging] = useState(false);
  const [startX, setStartX] = useState(0);
  const [scrollLeft, setScrollLeft] = useState(0);

  const scroll = (direction) => {
    setCurrentIndex((prevIndex) => {
      if (direction === 'left') {
        return Math.max(prevIndex - 1, 0);
      } else {
        return Math.min(prevIndex + 1, projects.length - 3);
      }
    });
  };

  useEffect(() => {
    if (sliderRef.current) {
      sliderRef.current.scrollTo({
        left: currentIndex * (sliderRef.current.clientWidth / 3),
        behavior: 'smooth',
      });
    }
  }, [currentIndex]);

  const handleMouseDown = (e) => {
    setIsDragging(true);
    setStartX(e.pageX - sliderRef.current.offsetLeft);
    setScrollLeft(sliderRef.current.scrollLeft);
  };

  const handleMouseUp = () => {
    setIsDragging(false);
  };

  const handleMouseMove = (e) => {
    if (!isDragging) return;
    e.preventDefault();
    const x = e.pageX - sliderRef.current.offsetLeft;
    const walk = (x - startX) * 2;
    sliderRef.current.scrollLeft = scrollLeft - walk;
  };

  const placeholderImage = useColorModeValue(ProjectPlaceholderBeige, ProjectPlaceholderBlue);

  return (
    <Box w="full" bg={bgColor} p={6} rounded="lg" mt={6}>
      <HStack justify="space-between">
        <Heading as="h2" size="lg" mb={4} color={textColor}>
          Recent Projects
        </Heading>

        <Button as={RouterLink} rightIcon={<FaArrowRight />} to="/projects" variant="outline" colorScheme="blue" size="sm" mb={4}>
          View All Projects 
        </Button>
      </HStack>

      {projects.length === 0 && !loading && (
        <Button as={RouterLink} to="/generate-ad" colorScheme="teal" size="md" mb={4}>
          Generate your first ad
        </Button>
      )}

      <Box position="relative">
        <Box
          ref={sliderRef}
          display="flex"
          overflowX="hidden"
          onMouseDown={handleMouseDown}
          onMouseUp={handleMouseUp}
          onMouseLeave={handleMouseUp}
          onMouseMove={handleMouseMove}
          sx={{
            '::-webkit-scrollbar': {
              display: 'none',
            },
            'msOverflowStyle': 'none',
            'scrollbarWidth': 'none',
          }}
        >
          {projects.map((project) => (
            loading ? (
              <Skeleton key={project._id} w="100%" h="160px" />
            ) : (
                <Box
              key={project._id}
              minW="calc(33.333% - 1rem)"
              flexShrink={0}
              bg={cardBg}
              color={textColor}
              rounded="lg"
              overflow="hidden"
              mx={2}
            >
              <ChakraLink
                as={RouterLink}
                to={`/project/${project._id}/edit`}
                _hover={{ textDecoration: 'none' }}
              >
                {project.imageUrl ? (
                  <Image
                    src={project.imageUrl}
                  alt={project.title}
                  w="100%"
                  h="160px"
                  objectFit="cover"
                  />
                ) : (
                  <Flex align="center" justify="center" w="100%" h="160px" bg={`url(${placeholderImage})`} bgSize="cover" bgPosition="center">
                    <Heading as="h2" fontSize="8rem" fontWeight="bold" color="white" textShadow="2px 2px 4px rgba(0, 0, 0, 0.5)">
                      {project.title.charAt(0)}
                    </Heading>
                  </Flex>
                )}
                <Box p={4}>
                  <Heading as="h3" size="md" mb={0}>
                    {project.title}
                  </Heading>
                </Box>
                <Box p={4} pt={0}>
                  <Text fontSize={'sm'} color={textColor}>
                    {project.scripts.length} {project.scripts.length === 1 ? 'script' : 'scripts'}
                  </Text>
                  <Text fontSize="sm" color="gray.400">
                    {new Date(project.createdAt).toLocaleDateString()}
                  </Text>
                </Box>
              </ChakraLink>
              </Box>
            )
          ))}
        </Box>
        <IconButton
          aria-label="Scroll Left"
          icon={<FaChevronLeft />}
          position="absolute"
          left="0"
          top="50%"
          transform="translateY(-50%)"
          bg={iconBgColor}
          color={textColor}
          _hover={{ bg: iconBgColor }}
          onClick={() => scroll('left')}
          disabled={currentIndex === 0 || projects.length === 0}
        />
        <IconButton
          aria-label="Scroll Right"
          icon={<FaChevronRight />}
          position="absolute"
          right="0"
          top="50%"
          transform="translateY(-50%)"
          bg={iconBgColor}
          color={textColor}
          _hover={{ bg: iconBgColor }}
          onClick={() => scroll('right')}
          disabled={currentIndex >= projects.length - 3 || projects.length === 0}
        />

      </Box>

    </Box>

  );
};

export default RecentProjectsSlider;
