import api from '../utils/api.utils';
import { handleResponse } from '../utils/app.utils';

export const generateInviteCode = async (email, token) => {
  try {
    const response = await api.post('/invite/generate', { email }, {
      customHeaders: { token },
    });
    return handleResponse(response, 'Error generating invite code');
  } catch (error) {
    console.error('Error generating invite code:', error.message);
    throw new Error('Failed to generate invite code');
  }
};

export const validateInviteCode = async (code, token) => {
  try {
    const response = await api.post('/invite/validate', { code }, {
      customHeaders: { token },
    });
    return handleResponse(response);
  } catch (error) {
    console.error('Error validating invite code:', error.message);
    throw error;
  }
};

export const revokeInviteCode = async (code, token) => {
  try {
    const response = await api.post('/invite/revoke', { code }, {
      customHeaders: { token },
    });
    return handleResponse(response, 'Error revoking invite code');
  } catch (error) {
    console.error('Error revoking invite code:', error.message);
    throw new Error('Failed to revoke invite code');
  }
};
