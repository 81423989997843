import React, { useEffect } from 'react';
import { Text, Stack, Icon, Flex } from '@chakra-ui/react';
import { FaArrowRight } from 'react-icons/fa';
import { motion } from 'framer-motion';
import AirBNBRating from '../AirBNBRating/AirBNBRating';
import { useSignupFormContext } from '../../providers/SignupFormProvider';

const MotionFlex = motion(Flex);

const variants = {
  initial: { opacity: 0, x: 50 },
  animate: { opacity: 1, x: 0 },
  exit: { opacity: 0, x: -50 },
};

const ProfileSetupSkillLevel = ({ step }) => {
  const { skillLevel, setSkillLevel } = useSignupFormContext();

  return (
    <MotionFlex
      flexDir={'column'}
      justify={'center'}
      align={'stretch'}
      key="step2"
      variants={variants}
      initial="initial"
      animate="animate"
      exit="exit"
      transition={{ duration: 0.5 }}
      flex={1}
    >
      {/* Section Step */}
      <Stack
        direction={{ base: 'column', md: 'row' }}
        align={'center'}
        mb={4}
      >
        <Text fontSize={{ base: 'xl', md: '2xl' }}>{step - 1}</Text>
        <Icon as={FaArrowRight} w={{ base: 4, md: 6 }} h={{ base: 4, md: 6 }} color="gunmetal.300" mr={{ base: 2, md: 4 }} />
        <Text fontSize={{ base: 'lg', md: '2xl' }}>
          What is your Skill Level?
        </Text>
      </Stack>

      <Text fontSize={{ base: 'sm', md: 'md' }} mb={8} color={'gray.300'}>
        Select the skill level that best describes you.
      </Text>

      <AirBNBRating
        totalStars={5}
        initialRating={skillLevel}
        onRatingChange={(rating) => setSkillLevel(rating)}
      />

      <Stack
        direction={'row'}
        justify={'space-between'}
        mt={4}
        fontSize={{ base: 'xs', md: 'md' }}
      >
        <Text color={'gray.300'}>
          Beginner
        </Text>
        <Text color={'gray.300'}>
          Intermediate
        </Text>
        <Text color={'gray.300'}>
          Advanced
        </Text>
      </Stack>
    </MotionFlex>
  );
}

export default ProfileSetupSkillLevel;
