import React, { useState, useMemo, useEffect, useRef } from 'react';
import {
  Box,
  Text,
  HStack,
  VStack,
  Select,
  Button,
  Skeleton,
  Icon,
  useColorModeValue,
  SimpleGrid,
} from '@chakra-ui/react';
import {
  MotionHStack,
  MotionVStack,
  containerVariants,
  itemVariants,
  MUSIC_GENRES,
  MUSIC_MOODS,
  MUSIC_TONES,
} from '../../config/config';
import AudioPlayer, { RHAP_UI } from 'react-h5-audio-player';
import { Global } from '@emotion/react';
import 'react-h5-audio-player/lib/styles.css'; 
import { AnimatePresence } from 'framer-motion';
import { FaMusic, FaPlus } from 'react-icons/fa';
import useGetAvailableSongs from '../../hooks/useGetAvailableSongs';
import useGenerateMusic from '../../hooks/useGenerateMusic';
import Pagination from '../Pagination/Pagination';

const MusicSelection = ({
  onMusicSelect,
  onMusicRemove,
  onGenerateMusic,
  selectedMusicUrl,
  duration,
}) => {
  const borderColor = useColorModeValue('gray.200', 'gunmetal.550');
  const hoverBgColor = useColorModeValue('gray.100', 'gunmetal.500');
  const textColor = useColorModeValue('gray.800', 'white');
  const subTextColor = useColorModeValue('gray.600', 'gray.400');
  const iconHoverBgColor = useColorModeValue('gray.300', 'gray.500');
  const playerBgColor = useColorModeValue('gray.300', '#232C33');
  const controlColor = useColorModeValue('gray.800', 'white');
  const controlHoverColor = useColorModeValue('gray.600', 'gray.300');
  const progressBarBg = useColorModeValue('gray.300', 'gray.600');
  const progressFilledBg = useColorModeValue('blue.500', 'blue.300');
  const boxBorderColor = useColorModeValue('gray.200', '#2F4957');

  const { songs, loading: songsLoading } = useGetAvailableSongs();
  const { generateMusic, loading: generateMusicLoading } = useGenerateMusic();

  const [selectedSong, setSelectedSong] = useState(null);
  const [filters, setFilters] = useState({ genre: '', mood: '', tone: '' });
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 6;

  const currentAudioRef = useRef(null);

  const filteredSongs = useMemo(() => {
    // Parse the duration prop to ensure it's a number
    const parsedDuration = parseInt(duration, 10);

    return songs.filter((song) => {
      const genreMatch =
        !filters.genre ||
        song.genre.toLowerCase() === filters.genre.toLowerCase();

      const moodMatch =
        !filters.mood ||
        (Array.isArray(song.mood)
          ? song.mood.some(
              (m) => m.toLowerCase() === filters.mood.toLowerCase()
            )
          : song.mood.toLowerCase() === filters.mood.toLowerCase());

      const toneMatch =
        !filters.tone || song.tone.toLowerCase() === filters.tone.toLowerCase();

      // Ensure song.duration is a number
      const songDuration = Number(song.duration);

      // Duration filter: Ensure the song's duration exactly matches the parsed duration
      const durationMatch =
        !parsedDuration || (songDuration && songDuration === parsedDuration);

      return genreMatch && moodMatch && toneMatch && durationMatch;
    });
  }, [songs, filters, duration]);

  const paginatedSongs = useMemo(() => {
    const startIndex = (currentPage - 1) * itemsPerPage;
    return filteredSongs.slice(startIndex, startIndex + itemsPerPage);
  }, [filteredSongs, currentPage]);

  const totalPages = Math.ceil(filteredSongs.length / itemsPerPage);

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilters((prevFilters) => ({
      ...prevFilters,
      [name]: value,
    }));
    setCurrentPage(1);
  };

  const handleSelectSong = (song) => {
    setSelectedSong(song);
    if (selectedMusicUrl === song.url) {
      onMusicRemove();
    } else {
      onMusicSelect(song);
    }
  };

  const handleGenerateMusic = async () => {
    try {
      const newSongUrl = await generateMusic(filters);
      setSelectedSong({ url: newSongUrl, title: 'Generated Song' });
    } catch (error) {
      console.error('Error generating music:', error);
    }
  };

  return (
    <>
      {/* Global Styles for Audio Player */}
      <Global
        styles={`
          .rhap_container {
            background-color: ${playerBgColor} !important;
            border-radius: 8px;
            border: 1px solid ${boxBorderColor};
            color: ${controlColor};
          }
          .rhap_additional-controls {
            display: none !important;
          }
          .rhap_volume-controls {
            padding-right: 10px !important;
          }
          .rhap_button-clear {
            color: ${controlColor} !important;
          }
          .rhap_time {
            color: ${controlColor} !important;
          }
          .rhap_button-clear:hover {
            color: ${controlHoverColor} !important;
          }
          .rhap_progress-bar {
            background: ${progressBarBg} !important;
          }
          .rhap_progress-filled {
            background: ${progressFilledBg} !important;
          }
        `}
      />
      <Box
        p={8}
        bg="gunmetal.700"
        color="white"
        borderRadius="md"
        maxW="1400px"
        mx="auto"
        w="full"
      >
        <HStack spacing={4} align="center" mb={2}>
          <Text fontSize={{ base: 'lg', md: '2xl' }}>Step 5</Text>
          <Icon as={FaMusic} w={6} h={6} color="gunmetal.300" mx={4} />
          <Text fontSize={{ base: 'lg', md: '2xl' }}>
            Select or Generate Music
          </Text>
        </HStack>
        <Text mb={8}>
          Choose music from our library for 0 credits or generate music for your ad using credits.
        </Text>

        <HStack spacing={4} mb={8}>
          <Select placeholder="Genre" name="genre" onChange={handleFilterChange}>
            {MUSIC_GENRES.map((genre) => (
              <option key={genre.value} value={genre.value}>
                {genre.label}
              </option>
            ))}
          </Select>

          <Select placeholder="Mood" name="mood" onChange={handleFilterChange}>
            {MUSIC_MOODS.map((mood) => (
              <option key={mood.value} value={mood.value}>
                {mood.label}
              </option>
            ))}
          </Select>

          <Select placeholder="Tone" name="tone" onChange={handleFilterChange}>
            {MUSIC_TONES.map((tone) => (
              <option key={tone.value} value={tone.value}>
                {tone.label}
              </option>
            ))}
          </Select>
        </HStack>

        <MotionVStack
          spacing={4}
          align="stretch"
          mt={8}
          bg={'blackAlpha.200'}
          variants={containerVariants}
          initial="hidden"
          animate="show"
        >
          <Button
            onClick={onGenerateMusic}
            isLoading={generateMusicLoading}
            mt={4}
            bg="beige.300"
            color="gunmetal.700"
            leftIcon={<FaPlus />}
          >
            Generate Music
          </Button>

          <AnimatePresence>
            {songsLoading
              ? Array.from({ length: itemsPerPage }).map((_, index) => (
                  <Skeleton key={index} height="100px" />
                ))
              : paginatedSongs.length > 0 ? (
                  paginatedSongs.map((song, index) => (
                    <MotionHStack
                      key={song.id || index}
                      custom={index}
                      spacing={2}
                      bg="gradBlack"
                      borderRadius="md"
                      borderColor={borderColor}
                      borderWidth={1}
                      p={2}
                      _hover={{ bg: 'gradBlackHover' }}
                      variants={itemVariants}
                      initial="hidden"
                      animate="show"
                      exit="hidden"
                    >
                      <SimpleGrid
                        columns={4}
                        spacing={2}
                        alignItems="stretch"
                        w="50%"
                      >
                        <VStack align="stretch" spacing={0} p={4} wrap="wrap">
                          <Text fontSize="sm" color="beige.300">
                            Song Title
                          </Text>
                          <Text fontSize="md">{song.title}</Text>
                        </VStack>

                        <VStack align="stretch" spacing={0} p={4} wrap="wrap">
                          <Text fontSize="sm" color="beige.300">
                            Genre
                          </Text>
                          <Text fontSize="md">
                            {song.genre.charAt(0).toUpperCase() +
                              song.genre.slice(1)}
                          </Text>
                        </VStack>

                        <VStack align="stretch" spacing={0} p={4} wrap="wrap">
                          <Text fontSize="sm" color="beige.300">
                            Mood
                          </Text>
                          <Text fontSize="md">
                            {Array.isArray(song.mood)
                              ? song.mood
                                  .map(
                                    (mood) =>
                                      mood.charAt(0).toUpperCase() + mood.slice(1)
                                  )
                                  .join(', ')
                              : song.mood.charAt(0).toUpperCase() +
                                song.mood.slice(1)}
                          </Text>
                        </VStack>

                        <VStack align="stretch" spacing={0} p={4} wrap="wrap">
                          <Text fontSize="sm" color="beige.300">
                            Tone
                          </Text>
                          <Text fontSize="md">
                            {song.tone.charAt(0).toUpperCase() + song.tone.slice(1)}
                          </Text>
                        </VStack>
                      </SimpleGrid>

                      <HStack
                        spacing={4}
                        alignItems="stretch"
                        w="50%"
                        justifyContent="center"
                      >
                        <AudioPlayer
                          src={song.previewUrl}
                          autoPlayAfterSrcChange={false}
                          showJumpControls={false}
                          customAdditionalControls={[...RHAP_UI.ADDITIONAL_CONTROLS]}
                          layout="horizontal-reverse"
                          onPlay={(e) => {
                            if (
                              currentAudioRef.current &&
                              currentAudioRef.current !== e.target
                            ) {
                              currentAudioRef.current.pause();
                            }
                            currentAudioRef.current = e.target;
                          }}
                        />

                        <Button
                          variant={
                            selectedMusicUrl === song.url ? 'solid' : 'outline'
                          }
                          borderRadius="full"
                          minW="125px"
                          py={6}
                          bg={
                            selectedMusicUrl === song.url
                              ? 'beige.300'
                              : 'transparent'
                          }
                          borderColor={
                            selectedMusicUrl === song.url ? 'beige.300' : 'white'
                          }
                          color="white"
                          onClick={() => handleSelectSong(song)}
                        >
                          {selectedMusicUrl === song.url ? 'Selected' : 'Select'}
                        </Button>
                      </HStack>
                    </MotionHStack>
                  ))
                ) : (
                  <Text>No songs match the selected duration.</Text>
                )}
          </AnimatePresence>
        </MotionVStack>

        {!songsLoading && (
          <Pagination
            currentPage={currentPage}
            totalPages={totalPages}
            onPageChange={setCurrentPage}
          />
        )}
      </Box>
    </>
  );
};

export default MusicSelection;
