import React from 'react';
import { Flex, Text, useTheme } from '@chakra-ui/react';
import { MotionFlex } from '../../config/config';

const SegmentedProgressBar = ({ currentStep, totalSteps }) => {
  const theme = useTheme();
  const segmentWidth = `${100 / totalSteps}%`;
  const segments = Array.from({ length: totalSteps }, (_, i) => i + 1);

  return (
    <Flex
      width="100%"
      alignItems="center"
      justify="stretch"
      position="relative"
      flexDirection="row"
      flexWrap="nowrap"
      mb={4}
      pb={10}
    >
      {segments.map((step, index) => (
        <MotionFlex
          key={step}
          aria-current={step === currentStep ? 'step' : undefined}
          animate={step <= currentStep ? 'active' : 'inactive'}
          variants={{
            active: {
              width: segmentWidth,
              backgroundColor: theme.colors.moonstone[500],
              transition: { duration: 0.5 },
            },
            inactive: {
              width: segmentWidth,
              backgroundColor: theme.colors.gunmetal[500],
              transition: { duration: 0.5 },
            },
          }}
          sx={{
            height: '20px',
            marginRight: index === totalSteps - 1 ? 0 : '15px',
            borderTopLeftRadius: index === 0 ? 'xl' : 0,
            borderBottomLeftRadius: index === 0 ? 'xl' : 0,
            borderTopRightRadius: index === totalSteps - 1 ? 'xl' : 0,
            borderBottomRightRadius: index === totalSteps - 1 ? 'xl' : 0,
          }}
        />
      ))}
      <Text
        position="absolute"
        bottom="0px"
        left="50%"
        transform="translateX(-50%)"
        fontSize="sm"
        color="gray.500"
        textAlign="center"
        width="100%"
      >{`Step ${currentStep} of ${totalSteps}`}</Text>
    </Flex>
  );
};

export default SegmentedProgressBar;
