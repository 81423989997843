import React, { useState } from 'react';
import {
  Box,
  HStack,
  Text,
  IconButton,
  Button,
  Flex,
  Tooltip,
  Heading,
  Spinner,
  useColorModeValue,
} from '@chakra-ui/react';
import { CloseIcon } from '@chakra-ui/icons';
import { FaArrowsRotate, FaGear, FaPlus } from 'react-icons/fa6';
import AudioPlayer, { RHAP_UI } from 'react-h5-audio-player';
import { Global } from '@emotion/react';
import 'react-h5-audio-player/lib/styles.css';
import useManageServices from '../../hooks/useManageServices';

const MusicControls = ({
  musicUrl,
  onRemoveMusic,
  onRegenerateMusic,
  onGenerateMusic,
  onSelectMusic,
  onOpenMusicModal,
  musicLoading,
  timeOverLimit,
  handleEditMusic,
  duration,
}) => {
  const borderColor = useColorModeValue('gray.200', 'gunmetal.550');
  const hoverBgColor = useColorModeValue('gray.100', 'gunmetal.500');
  const textColor = useColorModeValue('gray.800', 'white');
  const subTextColor = useColorModeValue('gray.600', 'gray.400');
  const iconHoverBgColor = useColorModeValue('gray.300', 'gray.500');
  const playerBgColor = useColorModeValue('gray.300', '#232C33');
  const controlColor = useColorModeValue('gray.800', 'white');
  const controlHoverColor = useColorModeValue('gray.600', 'gray.300');
  const progressBarBg = useColorModeValue('gray.300', 'gray.600');
  const progressFilledBg = useColorModeValue('blue.500', 'blue.300');
  const boxBorderColor = useColorModeValue('gray.200', '#2F4957');

  const { services } = useManageServices();

  const musicCost = ({ type, duration }) => {
    const service = services?.find(service => service.type === type);
    const serviceCost = service?.costs?.find(
      cost => cost.duration === duration.toString().split('s')[0]
    );
    return serviceCost?.cost;
  };

  const [isMusicGenerated, setIsMusicGenerated] = useState(false);

  return (
    <>
      {/* Global Styles for Audio Player */}
      <Global
        styles={`
          .rhap_container {
            background-color: ${playerBgColor} !important;
            border-radius: 8px;
            border: 1px solid ${boxBorderColor};
            color: ${controlColor};
          }
          .rhap_additional-controls {
            display: none !important;
          }
          .rhap_volume-controls {
            padding-right: 10px !important;
          }
          .rhap_button-clear {
            color: ${controlColor} !important;
          }
          .rhap_time {
            color: ${controlColor} !important;
          }
          .rhap_button-clear:hover {
            color: ${controlHoverColor} !important;
          }
          .rhap_progress-bar {
            background: ${progressBarBg} !important;
          }
          .rhap_progress-filled {
            background: ${progressFilledBg} !important;
          }
        `}
      />

      {musicUrl ? (
        <Box position="relative">
          <HStack align="center" justify="space-between" mb={2}>
            <Text textAlign={'center'} fontSize="sm" fontWeight="bold" mb={2}>
              Music
            </Text>

            <HStack>
              <IconButton
                icon={<FaGear />}
                aria-label="Edit"
                size="sm"
                borderRadius="md"
                onClick={handleEditMusic}
                variant="outline"
                _hover={{ bg: iconHoverBgColor }}
              />
              <IconButton
                icon={<CloseIcon />}
                aria-label="Remove music"
                size="sm"
                onClick={onRemoveMusic}
                variant="outline"
                borderRadius="md"
                _hover={{ bg: 'red.600' }}
              />
            </HStack>
          </HStack>

          <AudioPlayer
            src={musicUrl}
            autoPlayAfterSrcChange={false}
            showJumpControls={false}
            customAdditionalControls={[...RHAP_UI.ADDITIONAL_CONTROLS]}
            layout="horizontal-reverse"
          />

          <Flex
            flexDirection="column"
            justify="center"
            align="stretch"
            mb={4}
            mt={2}
          >
            <Button
              onClick={onOpenMusicModal}
              bg="blue.500"
              color="white"
              _hover={{ bg: 'blue.600' }}
              leftIcon={<FaPlus />}
              mb={2}
            >
              Select Another Song
            </Button>

            {/* // This should appear when the user generates AI music */}
            {isMusicGenerated && (
              <>
                <Button
                  onClick={onRegenerateMusic}
                  isLoading={musicLoading}
                  loadingText="Regenerating..."
                  bg="blue.500"
                  color="white"
                  _hover={{ bg: 'blue.600' }}
                  leftIcon={<FaArrowsRotate />}
                  mb={2}
                >
                  Regenerate Music
                </Button>
                <Text fontSize="sm" color={subTextColor} textAlign="center">
                  {musicCost({ type: 'MUSIC_GENERATION', duration })} Credits
                </Text>
              </>
            )}
          </Flex>
        </Box>
      ) : (
        <Tooltip
          label="Duration exceeds the target limit. Please adjust your script."
          isDisabled={!timeOverLimit}
          shouldWrapChildren
        >
          <Flex
            bg="transparent"
            flexDirection="column"
            p={4}
            align="center"
            justify="center"
            borderRadius="md"
            borderColor={timeOverLimit ? 'gray.400' : borderColor}
            borderWidth="1px"
            borderStyle="dashed"
            transition="all 0.3s"
            onClick={!timeOverLimit ? onOpenMusicModal : undefined}
            _hover={{
              bg: !timeOverLimit ? hoverBgColor : 'transparent',
              cursor: timeOverLimit ? 'not-allowed' : 'pointer',
              borderColor: timeOverLimit ? 'gray.400' : borderColor,
              borderWidth: '1px',
              borderStyle: 'solid',
            }}
            opacity={timeOverLimit ? 0.6 : 1}
          >
            {musicLoading ? (
              <Spinner size="sm" />
            ) : (
              <>
                <Heading size="md" color={textColor}>
                  {musicUrl ? 'Selected Music' : 'Select or Generate Music'}
                </Heading>
                <Text textAlign="center" color={subTextColor}>
                  Costs 0 - {musicCost({ type: 'MUSIC_GENERATION', duration })}{' '}
                  credits
                </Text>
              </>
            )}
          </Flex>
        </Tooltip>
      )}
    </>
  );
};

export default MusicControls;
