import React from 'react';
import {
  Button,
  Heading,
  Flex,
  Badge,
  useColorModeValue,
} from '@chakra-ui/react';
import useManageStripe from '../../hooks/useManageStripe';

const SubscriptionPlan = ({ user, subscription }) => {

  const { fetchCustomerPortalSession, loading: loadingCustomerPortalSession } = useManageStripe();

  const bg = useColorModeValue('white', 'gunmetal.700');
  const borderColor = useColorModeValue('gunmetal.400', 'gunmetal.500');

  const handleCustomerPortalSession = async () => {
    const session = await fetchCustomerPortalSession();
    window.location.href = session.url;
  };

  return (
    <Flex
      bg={bg}
      p={4}
      rounded="lg"
      shadow="lg"
      border="1px solid"
      borderColor={borderColor}
      direction="column"
      align="flex-start"
      justify="center"
    >
      <Heading as="h2" size="md" mb={2}>
        Your current subscription:
      </Heading>
      <Badge mb={10} colorScheme="green" fontSize="lg" p={2}>
        {subscription.subscriptionPlan.name
          ? subscription.subscriptionPlan.name
          : 'None'}
      </Badge>
      <Button
        onClick={handleCustomerPortalSession}
        isLoading={loadingCustomerPortalSession}
      >
        Manage Subscription
      </Button>
    </Flex>
  );
};

export default SubscriptionPlan;
