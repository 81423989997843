import React from 'react';
import { Heading, Text, Flex } from '@chakra-ui/react';
import { motion } from 'framer-motion';
import { INVITE_CODE_ENABLED } from '../../config/config';

const MotionFlex = motion(Flex);

const ProfileSetupStart = ({ subscriptionPlan }) => {
  return (
    <MotionFlex
      flexDirection="column"
      align="center"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5 }}
      p={4}
    >
      {INVITE_CODE_ENABLED ? (
        <>
          <Heading as="h1" size="2xl" mb={6} textAlign="center">
            You're in!
          </Heading>
          <Text
            mb={0}
            fontSize={{ base: 'md', md: 'lg' }}
            textAlign="center"
            maxW="500px"
          >
            Thank you for being an early beta tester.  Enjoy the premium features for the next 30 days.
          </Text>

          <Text
          mt={4}
            mb={8}
            fontSize={{ base: 'md', md: 'lg' }}
            textAlign="center"
            maxW="500px"
          >
            250 credits have been added to your account.
          </Text>
          <Text fontSize={{ base: 'xl', md: '2xl' }} mb={2} textAlign="center">
            Let's start setting up your profile.
          </Text>
        </>
      ) : (
        <>
          <Heading as="h1" size="2xl" mb={6} textAlign="center">
            Subscription Successful!
          </Heading>
          <Text
            mb={0}
            fontSize={{ base: 'md', md: 'lg' }}
            textAlign="center"
            maxW="500px"
          >
            Thank you for subscribing to the {subscriptionPlan.name} plan.
          </Text>
          <Text
            mb={8}
            fontSize={{ base: 'md', md: 'lg' }}
            textAlign="center"
            maxW="500px"
          >
            {subscriptionPlan.credits} credits have been added to your account.
          </Text>
          <Text fontSize={{ base: 'xl', md: '2xl' }} mb={2} textAlign="center">
            Let's start setting up your profile.
          </Text>
        </>
      )}
    </MotionFlex>
  );
};

export default ProfileSetupStart;
