import { defineStyleConfig } from '@chakra-ui/react';

const Link = defineStyleConfig({
  baseStyle: {
    fontWeight: 'normal',
    textTransform: 'none',
    textDecoration: 'none',
  },
  variants: {
    normal: {
      color: 'moonstone',
      textDecoration: 'none',
    },

    white: {
      color: 'white',
      textDecoration: 'none',
      _hover: {
        color: 'moonstone',
        textDecoration: 'none',
      },
    },

    withBG: {
      color: 'white',
      border: '1px transparent',
      textDecoration: 'none',
      borderRadius: 'full',
      py: '2',
      px: '4',
      h: '50px',
      transition: 'all 0.4s ease-out',
      _hover: {
        bg: 'purple.500',
        bgGradient: 'linear(to-r, purple.200, purple.100)',
        borderRadius: 'full',
        border: '1px solid',
        borderColor: 'purple.100',
        color: 'white',
        textDecoration: 'none',
        height: '50px',
        py: '2',
        px: '4',
      },
      _activeLink: {
        bg: 'purple.500',
        bgGradient: 'linear(to-r, purple.200, purple.100)',
        borderRadius: 'full',
        border: '1px solid',
        borderColor: 'purple.100',
        color: 'white',
        textDecoration: 'none',
        height: '50px',
        py: '2',
        px: '4',
      },
    },
  },
  defaultProps: {
    variant: 'normal',
  },
});

export default Link;
