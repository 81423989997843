import { useCallback, useState } from 'react';
import { saveScript, updateScript } from '../services/script.service';
import { useUserContext } from '../providers/UserProvider';
import { useToast } from '@chakra-ui/react';

const useSaveScript = () => {
  const { token } = useUserContext();
  const [saveLoading, setSaveLoading] = useState(false);
  const toast = useToast();

  const saveScriptData = useCallback(
    async scriptData => {
      setSaveLoading(true);
      try {
        let savedScript = await saveScript(scriptData, token);

        // Show success toast
        toast({
          title: 'Script saved.',
          description: 'Script has been saved successfully.',
          status: 'success',
          duration: 9000,
          isClosable: true,
        });

        return savedScript.script;
      } catch (error) {
        // Show error toast
        toast({
          title: 'An error occurred.',
          description: `Unable to save script. ${error.message}`,
          status: 'error',
          duration: 9000,
          isClosable: true,
        });

        throw error;
      } finally {
        setSaveLoading(false);
      }
    },
    [token, toast]
  );

  const updateScriptData = useCallback(
    async (scriptId, scriptData) => {
      setSaveLoading(true);
      try {
        console.log('about to update scriptData', scriptData);

        let updatedScript = await updateScript(scriptId, scriptData, token);

        console.log('Updated Script Details', updatedScript);

        return updatedScript;
      } catch (error) {
        // Show error toast
        toast({
          title: 'An error occurred.',
          description: `Unable to update script. ${error.message}`,
          status: 'error',
          duration: 9000,
          isClosable: true,
        });

        throw error;
      } finally {
        setSaveLoading(false);
      }
    },
    [token, toast]
  );

  return { saveScriptData, updateScriptData, saveLoading };
};

export default useSaveScript;
