import { defineStyleConfig } from '@chakra-ui/react';

const Heading = defineStyleConfig({
  baseStyle: {
    fontWeight: 'bold',
    fontSize: 'xl',
    lineHeight: 'shorter',
  },
  sizes: {
    xl: {
      fontSize: '4xl',
      lineHeight: 'shorter',
    },
    lg: {
      fontSize: '3xl',
      lineHeight: 'shorter',
    },
    md: {
      fontSize: '2xl',
      lineHeight: 'shorter',
    },
    sm: {
      fontSize: 'xl',
      lineHeight: 'shorter',
    },
    xs: {
      fontSize: 'md',
      lineHeight: 'shorter',
    },
  },
  variants: {
    gradient: {
      bgGradient: 'linear(to-r, beige.400, beige.500)',
      bgClip: 'text',
    },
    link: {
      color: 'beige.400',
      _hover: {
        textDecoration: 'underline',
      },
    },
  },
  defaultProps: {
    size: 'xl',
  },
});

export default Heading;
