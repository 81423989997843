// theme/index.js
import { extendTheme } from '@chakra-ui/react';

// Global style overrides
import styles from './styles';

// Foundational style overrides
import colors from './foundation/colors';
import shadows from './foundation/shadows';
import breakpoints from './foundation/breakpoints';
import borderRadius from './foundation/borderradius';

// Component style overrides
import Button from './components/button';
import Link from './components/link';
import Modal from './components/modal';
import { menuTheme } from './components/menu';
import { switchTheme } from './components/switch';
import { selectTheme } from './components/select';
import tagTheme from './components/tag';
import Heading from './components/heading';

const config = {
  initialColorMode: 'dark',
  useSystemColorMode: false,
};

const overrides = {
  config,
  styles,
  colors,
  shadows,
  breakpoints,
  borderRadius,
  fonts: {
    heading: `'effra-cc', sans-serif`,
    body: `'effra-cc', sans-serif`,
  },

  components: {
    Heading,
    Button,
    Link,
    Modal,
    Menu: menuTheme,
    Switch: switchTheme,
    Select: selectTheme,
    Tag: tagTheme,
  },
};

export default extendTheme(overrides);
