import { useCallback, useState } from 'react';
import { useUserContext } from '../providers/UserProvider';
import { getCustomerPortalSession } from '../services/billing.service';

const useManageStripe = () => {
    const { token } = useUserContext();

    const [loading, setLoading] = useState(false);
    const [session, setSession] = useState(null);

    const fetchCustomerPortalSession = useCallback(async () => {
        setLoading(true);
        try {
            const response = await getCustomerPortalSession(token);
            setSession(response);
            return response;
        } catch (error) {
            console.error('Error getting customer portal session:', error);
        } finally {
            setLoading(false);
        }
    }, [token]);

    return { fetchCustomerPortalSession, loading, session };
}

export default useManageStripe;