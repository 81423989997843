import React from 'react';
import {
  FormControl,
  Button,
  Box,
  Image,
  useBreakpointValue,
} from '@chakra-ui/react';
import EpiclyLogo from '../../assets/Epicly-Logo-Wht.svg';

const LoginForm = ({ login }) => {
  const buttonSize = useBreakpointValue({ base: 'md', md: 'lg' });
  const logoWidth = useBreakpointValue({ base: '150px', md: '250px' });
  const marginX = useBreakpointValue({ base: '4', md: '8' });

  return (
    <Box
      flex="1"
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      p={{ base: 4, md: 8 }}
    >
      <Box mb={8}>
        <Image src={EpiclyLogo} alt="Epicly Logo" maxW={'100%'} w={logoWidth} />
      </Box>
     
      <FormControl
        display="flex"
        flexDirection="column"
        justifyContent="stretch"
        alignItems="center"
        mx={marginX}
      >
        <Button variant="solid" size={buttonSize} onClick={login}>
          Use App
        </Button>
      </FormControl>
    </Box>
  );
};

export default LoginForm;
