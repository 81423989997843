import api from '../utils/api.utils';
import { handleResponse } from '../utils/app.utils';

// Get all subscription plans
export const getSubscriptionPlans = async (token) => {
  try {
    const response = await api.get('subscriptions/', {
        customHeaders: { token }
    });
    return handleResponse(response, 'Successfully fetched subscription plans');
  } catch (error) {
    console.error('Error', error.message);
    throw new Error('Failed to fetch subscription plans');
  }
};

// Get a subscription plan by ID
export const getSubscriptionPlanById = async (planId) => {
  try {
    const response = await api.get(`/subscriptions/${planId}`);
    return handleResponse(response, 'Successfully fetched subscription plan');
  } catch (error) {
    console.error('Error', error.message);
    throw new Error('Failed to fetch subscription plan');
  }
};

