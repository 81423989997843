import api from '../utils/api.utils';
import { handleResponse } from '../utils/app.utils';

export const getProducts = async (token) => {
  try {
    const response = await api.get('/products', {
      customHeaders: { token }
    });

    return handleResponse(response);
  } catch (error) {
    console.error('Error fetching products:', error);
    throw error;
  }
};
