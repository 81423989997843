import React, { useState, useEffect } from 'react';
import {
  Heading,
  Text,
  Stack,
  Flex,
  Button,
  VStack,
  useBreakpointValue,
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import GenerativeProgress from '../GenerativeProgress/GenerativeProgress';
import { useAdGeneration } from '../../providers/AdGenerationProvider';
import useGenerateScript from '../../hooks/useGenerateScript';
import useSaveScript from '../../hooks/useSaveScript';

const ScriptGenerating = () => {
  const headingSize = useBreakpointValue({ base: 'lg', md: 'xl' });

  const navigate = useNavigate();
  const { adType, projectDetails, scriptDetails, setScriptDetails, scriptIdea, voiceActor } = useAdGeneration();
  const { generateScriptData, hookLoading: generateLoading } = useGenerateScript();
  const { saveScriptData, hookLoading: saveLoading } = useSaveScript();
  
  const {
    brandName,
    productName,
    callToAction,
    duration,
    industry,
    targetAudience,
  } = scriptDetails;

  const [loading, setLoading] = useState(false);
  const [script, setScript] = useState(null);

  const sessionAdType = sessionStorage.getItem('adType');

  const handleGenerateAndSaveScript = async () => {
    setLoading(true);
    try {
      const data = {
        adType: sessionAdType || adType,
        project: projectDetails?.project?.id,
        brandName,
        productName,
        callToAction,
        duration,
        industry,
        targetAudience,
        scriptIdea,
      };

      const generatedScript = await generateScriptData(data);
      setScript(generatedScript);

      const saveData = {
        title: scriptIdea.title,
        script: generatedScript.script,
        projectId: projectDetails?.project?.id,
        brandName,
        productName,
        callToAction,
        duration,
        industry,
        targetAudience,
        adType: sessionAdType || adType,
        voiceActor,
      };

      const savedScript = await saveScriptData(saveData);

      setScriptDetails(prevDetails => ({
        ...prevDetails,
        script: savedScript.script,
      }));

      navigate(`/script/${savedScript._id}/edit`);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Flex
      direction={'column'}
      justify={'center'}
      align={'center'}
      w={'100%'}
      bgGradient="linear(to-b, #557D94, #34505F)"
      h="85vh"
      borderRadius={{ base: 0, md: '2xl' }}
      mb={8}
    >
      <Stack
        direction={'column'}
        spacing={10}
        w={'100%'}
        p={{ base: 6, md: 20 }}
        align={'center'}
        justify={'stretch'}
      >
        <VStack>
          <Heading as={'h2'} size={headingSize} mb={2}>
            "{scriptIdea.title}"
          </Heading>

          <Text textAlign={'center'} maxW={'450px'} mb={8}>
            Click below to generate your script. Make any adjustments to fit
            your brand, voice, or to fill in any gaps.
          </Text>

          <Button
            p={8}
            size={'lg'}
            mb={2}
            onClick={handleGenerateAndSaveScript}
            isLoading={loading || generateLoading || saveLoading}
            loadingText="Generating..."
            _hover={{ bg: 'gunmetal.400' }}
          >
            Generate Script
          </Button>

          <Button
            onClick={() => navigate('/generate-ad')}
            variant="link"
            color={'gray.300'}
            isLoading={loading || generateLoading || saveLoading}
          >
            Back to Start
          </Button>
        </VStack>

        {loading && <GenerativeProgress />}
      </Stack>
    </Flex>
  );
};

export default ScriptGenerating;
