import React from 'react';
import {
  Box,
  HStack,
  Flex,
  Tooltip,
  Heading,
  Spinner,
  Text,
  useColorModeValue,
  Button,
  Icon,
} from '@chakra-ui/react';
import AudioPlayer, { RHAP_UI } from 'react-h5-audio-player';
import { Global } from '@emotion/react';
import 'react-h5-audio-player/lib/styles.css'; 
import { FaRegPlayCircle } from 'react-icons/fa';

const PreviewControls = ({ 
  previewUrl, 
  timeOverLimit, 
  onGeneratePreview,  
  previewLoading,
  voiceoverUrl,
  musicUrl,
  voiceoverChanged,
  musicChanged,
  
 }) => {

  const borderColor = useColorModeValue('gray.200', 'gunmetal.550');
  const hoverBgColor = useColorModeValue('gray.100', 'gunmetal.500');
  const textColor = useColorModeValue('gray.800', 'white');
  const subTextColor = useColorModeValue('gray.600', 'gray.400');
  const iconHoverBgColor = useColorModeValue('gray.300', 'gray.500');
  const playerBgColor = useColorModeValue('gray.300', '#232C33');
  const controlColor = useColorModeValue('gray.800', 'white');
  const controlHoverColor = useColorModeValue('gray.600', 'gray.300');
  const progressBarBg = useColorModeValue('gray.300', 'gray.600');
  const progressFilledBg = useColorModeValue('blue.500', 'blue.300');
  const boxBorderColor = useColorModeValue('gray.200', '#2F4957');

  const shouldShowRegenButton =
    previewUrl !== null && (voiceoverChanged || musicChanged);

  return (
    <>
      {/* Global Styles for Audio Player */}
      <Global
        styles={`
          .rhap_container {
            background-color: ${playerBgColor} !important;
            border-radius: 8px;
            border: 1px solid ${boxBorderColor};
            color: ${controlColor};
          }
          .rhap_additional-controls {
            display: none !important;
          }
          .rhap_volume-controls {
            padding-right: 10px !important;
          }
          .rhap_button-clear {
            color: ${controlColor} !important;
          }
          .rhap_time {
            color: ${controlColor} !important;
          }
          .rhap_button-clear:hover {
            color: ${controlHoverColor} !important;
          }
          .rhap_progress-bar {
            background: ${progressBarBg} !important;
          }
          .rhap_progress-filled {
            background: ${progressFilledBg} !important;
          }
        `}
      />
{previewLoading ? (
        <Box display="flex" alignItems="center">
          <Spinner />
          <Text ml={2} color={textColor}>
            Generating preview...
          </Text>
        </Box>
) : (
  previewUrl ? (
        <Box position="relative">

          <HStack align="center" justify="space-between" mb={2}>
          <Text
          textAlign={'center'}
          fontSize="sm"
          fontWeight="bold"
          mb={2}
        >
              Preview
            </Text>
          </HStack>

          <AudioPlayer
            src={previewUrl}
            autoPlayAfterSrcChange={false}
            showJumpControls={false}
            customAdditionalControls={[
              ...RHAP_UI.ADDITIONAL_CONTROLS,
            ]}
            layout="horizontal-reverse"
          />
        </Box>
      ) : (
        <Tooltip
          label="Duration exceeds the target limit. Please adjust your script."
          isDisabled={!timeOverLimit}
          shouldWrapChildren
        >
          <Flex
            bg="transparent"
            flexDirection="column"
            p={4}
            align="center"
            justify="center"
            borderRadius="md"
            borderColor={timeOverLimit ? 'gray.400' : borderColor}
            borderWidth="1px"
            borderStyle="dashed"
            transition="all 0.3s"
            disabled={timeOverLimit || !voiceoverUrl || !musicUrl}
            onClick={!timeOverLimit ? onGeneratePreview : undefined}
            _hover={{
              bg: !timeOverLimit ? hoverBgColor : 'transparent',
              cursor: timeOverLimit ? 'not-allowed' : 'pointer',
              borderColor: timeOverLimit ? 'gray.400' : borderColor,
              borderWidth: '1px',
              borderStyle: 'solid',
            }}
            opacity={timeOverLimit ? 0.6 : 1}
          >
            {previewLoading ? (
              <Spinner size="sm" />
            ) : (
              <>
              <Heading size="md" color={textColor}>
                  Preview Full Ad
                </Heading>
              </>
            )}
          </Flex>
        </Tooltip>
      )
)
}

      {shouldShowRegenButton && (
        <Button
          onClick={onGeneratePreview}
          isLoading={previewLoading}
          loadingText="Generating Preview..."
          mt={0}
          leftIcon={<Icon as={FaRegPlayCircle} />}
        >
          Re-Generate Ad Preview
        </Button>
      )}
    </>
  );
};

export default PreviewControls;
