import { handleResponse } from '../utils/app.utils';
import api from '../utils/api.utils';

export const getSuggestedAudiences = async token => {
  try {
    const response = await api.get(`/suggested-audiences`, { customHeaders: { token } });

    return handleResponse(response, 'Error getting suggested audiences');
  } catch (error) {
    console.error('Error fetching suggested audiences:', error);
    throw new Error('Error getting suggested audiences');
  }
};

export const getSuggestedAudienceById = async (token, id) => {
  try {
    const response = await api.get(`/suggested-audiences/${id}`, { customHeaders: { token } });

    return handleResponse(response, 'Error getting suggested audience');
  } catch (error) {
    console.error('Error fetching suggested audience:', error);
    throw new Error('Error getting suggested audience');
  }
};
