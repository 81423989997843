// src/providers/UserProvider.js
import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  useCallback,
} from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import {
  handleAuth0Login,
  getUserSubscription,
} from '../services/user.service';

const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const {
    user: auth0User,
    getAccessTokenSilently,
    isLoading: authLoading,
    isAuthenticated,
    loginWithRedirect,
    logout: auth0Logout,
    error: auth0Error,
  } = useAuth0();

  const [userData, setUserData] = useState(null);
  const [unlockedAchievements, setUnlockedAchievements] = useState([]);
  const [subscription, setSubscription] = useState(null);
  const [loading, setLoading] = useState(true);
  const [token, setToken] = useState(null);

  const refreshUserData = useCallback(async () => {
    if (auth0Error) {
      console.log('Error:', auth0Error);
      setLoading(false);
      return;
    }

    if (isAuthenticated && !authLoading) {
      try {
        const accessToken = await getAccessTokenSilently();
        setToken(accessToken);

        if (!accessToken) {
          throw new Error('No access token found');
        }

        const details = await handleAuth0Login(auth0User, accessToken);

        setUserData(details.user);
        setUnlockedAchievements(details.achievements);

        if (details?.user.subscriptionStatus === 'active') {
          const sub = await getUserSubscription(details.user._id, accessToken);
          setSubscription(sub);
        } else {
          console.log(`[${new Date().toISOString()}] User does not have an active subscription`);
          setSubscription(null);
        }

        setLoading(false);
      } catch (error) {
        console.error(`[${new Date().toISOString()}] Silent Authentication Failed:`, error);
        if (error.error === 'login_required') {
          loginWithRedirect();
        }
        setLoading(false);
      }
    } else {
      setLoading(authLoading);
    }
  }, [auth0Error, isAuthenticated, authLoading, getAccessTokenSilently, auth0User, loginWithRedirect]);

  useEffect(() => {
    if (authLoading) {
      setLoading(true);
    } else {
      if (isAuthenticated) {
        refreshUserData();
      } else {
        setLoading(false);
      }
    }
  }, [authLoading, isAuthenticated, refreshUserData]);

  const login = () => {
    const queryParams = new URLSearchParams(window.location.search)
    const queryString = queryParams.toString();
    sessionStorage.setItem('queryParams', queryString);

    loginWithRedirect();
  };

  const logout = () => {
    auth0Logout({ returnTo: window.location.origin + '/login' });
    setUserData(null);
    setSubscription(null);
    setUnlockedAchievements([]);
    setLoading(true);
  };

  return (
    <UserContext.Provider
      value={{
        user: userData,
        setUser: setUserData,
        achievements: unlockedAchievements,
        subscription,
        setSubscription,
        loading: loading || authLoading,
        token,
        login,
        logout,
        refreshUserData,
        isAuthenticated,
        error: auth0Error,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export const useUserContext = () => useContext(UserContext);
