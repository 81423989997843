import api from '../utils/api.utils';
import { handleResponse } from '../utils/app.utils';

// Adds audio to a script.
export const addAudioToScript = async ({
  scriptId,
  audioData, // { title, audioPath }
  token,
}) => {
  const response = await api.post(
    `/audios/${scriptId}`,
    audioData,
    {
      customHeaders: { token },
    }
  );
  return handleResponse(response, 'Error adding audio to ad');
};

// Removes an audio file from a script.
export const removeAudioFromScript = async ({
  scriptId,
  audioId,
  token,
}) => {
  const response = await api.delete(
    `/audios/${scriptId}/${audioId}`,
    {
      customHeaders: { token },
    }
  );
  return handleResponse(response, 'Error removing audio from project');
};

// Retrieves all audios for a specific user.
export const getAllAudiosForUser = async ( userId, token ) => {
  const response = await api.get(`/audios/${userId}`, {
    customHeaders: { token },
  });
  return handleResponse(response, 'Error getting audios for user');
};

// Updates the audio with the specified audioId and scriptId.
export const updateAudio = async (scriptId, audioId, audioData, token) => {
  const response = await api.patch(
    `/audios/${scriptId}/${audioId}`,
    audioData,
    {
      customHeaders: { token },
    }
  );
  return handleResponse(response, 'Error updating audio');
};

// Deletes an audio file from a project.
export const deleteAudio = async (scriptId, audioId, token) => {
  const response = await api.delete(`/audios/${scriptId}/${audioId}/delete`, {
    customHeaders: { token },
  });
  return handleResponse(response, 'Error deleting audio');
};
