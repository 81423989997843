import api from '../utils/api.utils';
import { handleResponse } from '../utils/app.utils';

// Creates an ad preview.
export const fetchAdPreview = async (scriptId, voiceoverUrl, musicUrl, format, token) => {
  const response = await api.post(
    `/ads/preview/${scriptId}/generate-preview`,
    { voiceoverUrl, musicUrl, format },
    {
      customHeaders: {
        token,
      },
    }
  );
  return handleResponse(response, 'Failed to fetch ad preview');
};

export const downloadAd = async (scriptId, format, token) => {
  const response = await api.post(
    `/ads/export/${scriptId}/download-ad`,
    { format },
    {
      customHeaders: {
        token,
      },
      responseType: 'blob',
    }
  );
  return response;
};
