import React from 'react';
import {
  Box,
  Heading,
  VStack,
  HStack,
  Text,
  IconButton,
  Button,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuDivider,
  Link as ChakraLink,
  useColorModeValue,
  Flex,
  Tooltip,
  Portal,
} from '@chakra-ui/react';
import { Link as RouterLink } from 'react-router-dom';
import {
  FaPenToSquare,
  FaTrash,
  FaDownload,
  FaClone,
  FaEllipsisVertical,
  FaCircleMinus
} from 'react-icons/fa6';
import { FaAd } from 'react-icons/fa';
import { motion } from 'framer-motion';
import PlaceHolderHeader from '../../assets/default-project-header.png';

const MotionBox = motion(Box);

const ProjectCard = ({
  project,
  scripts,
  handleDeleteClick,
  handleDownloadScript,
  handleDeleteScript,
  setSelectedScriptId,
  setSelectedScriptTitle,
}) => {
  // Color and style variables
  const cardBgColor = useColorModeValue('white', 'gunmetal.600');
  const headingColor = useColorModeValue('gray.800', 'white');
  const linkColor = useColorModeValue('moonstone.700', 'moonstone.400');
  const headingLinkColor = useColorModeValue('gunmetal.500', 'beige.300');
  const headingLinkHoverColor = useColorModeValue('gunmetal.600', 'beige.200');
  const buttonBgColor = useColorModeValue('beige.300', 'beige.300');
  const buttonIconColor = useColorModeValue('white', 'white');
  const buttonHoverColor = useColorModeValue('beige.400', 'beige.400');
  const iconColor = useColorModeValue('gray.600', 'white');
  const borderColor = useColorModeValue('gray.500', 'gunmetal.550');
  const menuBgColor = useColorModeValue('gunmetal.600', 'gunmetal.600');
  const menuHoverColor = useColorModeValue('gunmetal.700', 'gunmetal.700');

  const linkHoverColor = useColorModeValue('moonstone.800', 'moonstone.300');

  return (
    <MotionBox
      p={6}
      borderRadius="md"
      boxShadow="lg"
      bg={cardBgColor}
      border="1px solid"
      borderColor={borderColor}
      position="relative"
      id={`project-${project.title}`}
    >
      <Flex
        justify={'center'}
        align="center"
        mb={4}
        p={4}
        bg={project.imageUrl ? `url(${project.imageUrl})` : `url(${PlaceHolderHeader})`}
        borderRadius={'md'}
        minH={'175px'}
        backgroundSize={'cover'}
        backgroundPosition={'center'}
        position={'relative'}
        overflow={'hidden'}
      >
        <Box pos={'absolute'} zIndex={1} top={0} left={0} right={0} bottom={0} bg={'rgba(0,0,0,0.7)'}></Box>
        <Heading
          zIndex={2}
          as="h2"
          size="xl"
          textAlign={'center'}
          color={headingColor}
          fontWeight={'bold'}
        >
          <ChakraLink
            as={RouterLink}
            textAlign={'center'}
            to={`/project/${project._id}/edit`}
            color={headingLinkColor}
            _hover={{ color: headingLinkHoverColor }}
          >
            {project.title}
          </ChakraLink>
        </Heading>
      </Flex>

      <IconButton
        icon={<FaCircleMinus />}
        aria-label="Delete Project"
        variant="ghost"
        bg="whiteAlpha.400"
        _hover={{
          bg: "red.300",
          color: "white"
        }}
        size="sm"
        borderRadius={'md'}
        position="absolute"
        top="10px"
        right="10px"
        onClick={() => handleDeleteClick(project, 'project')}
        zIndex={1000}
      />

      <VStack align="start" mt={4} spacing={4}>
        <Box maxHeight="300px" overflowY="auto" width="100%">
          {Array.isArray(scripts) && scripts.length > 0 ? (
            scripts.map((script, scriptIndex) => (
              <HStack
                key={script._id || scriptIndex}
                alignItems="center"
                justifyContent="space-between"
                width="100%"
                py={2}
                borderBottom="1px solid"
                borderColor={borderColor}
              >
                <HStack spacing={4}>
                  <VStack align="start" spacing={0}>
                    <ChakraLink
                      as={RouterLink}
                      to={`/script/${script._id || '#'}/edit`}
                      color={linkColor}
                      _hover={{
                        color: linkHoverColor,
                      }}
                      fontWeight="bold"
                    >
                      {script.title}
                    </ChakraLink>
                    <Text fontSize="sm" color="gray.400">
                      Duration: {script.duration || '0:00'}
                    </Text>
                  </VStack>
                </HStack>
                <HStack spacing={2}>
                  <HStack mr={4}>
                    {script.brandName && (
                      <Text fontSize="sm" color="gray.400">
                        Brand Name: {script.brandName}
                      </Text>
                    )}

                    <Text fontSize="sm" color="gray.400">
                      |
                    </Text>
                    <Text fontSize="sm" color="gray.400">
                      Type: {script.adType}
                    </Text>
                  </HStack>

                  <Tooltip label="Edit" aria-label="Edit tooltip" placement="top">
                    <IconButton
                      icon={<FaPenToSquare />}
                      aria-label="Edit"
                      variant="ghost"
                      size="sm"
                      borderRadius={'md'}
                      as={RouterLink}
                      to={`/script/${script._id || '#'}/edit`}
                    />
                  </Tooltip>

                  <Tooltip label="Download Script" aria-label="Download Script tooltip" placement="top">
                    <IconButton
                      icon={<FaDownload />}
                      aria-label="Export"
                      variant="ghost"
                      size="sm"
                      borderRadius={'md'}
                      onClick={() => {
                        setSelectedScriptId(script._id);
                        setSelectedScriptTitle(script.title);
                        handleDownloadScript(script._id, script.title);
                      }}
                    />
                  </Tooltip>
                  
                  <Tooltip label="Export Ad" aria-label="Export Ad tooltip" placement="top">
                    <IconButton 
                      icon={<FaAd />}
                      aria-label="Export Ad"
                      variant="ghost"
                      size="sm"
                      borderRadius={'md'}
                      onClick={() => {
                        // Implement export ad functionality if needed
                        // Example:
                        // handleExportAd(script._id);
                      }}
                    />
                  </Tooltip>

                  <Tooltip label="Delete" aria-label="Delete tooltip" placement="top">
                    <IconButton
                      icon={<FaTrash />}
                      aria-label="Delete Script"
                      variant="ghost"
                      size="sm"
                      onClick={() => handleDeleteScript(script)}
                      borderRadius={'md'}
                    />
                  </Tooltip>

                  <Menu>
                    <MenuButton
                      as={IconButton}
                      aria-label="More options"
                      icon={<FaEllipsisVertical />}
                      variant="ghost"
                      size="sm"
                      borderRadius={'md'}
                    />
                    <Portal>
                      <MenuList bg={menuBgColor}>
                        <MenuItem
                          bg={menuBgColor}
                          _hover={{
                            bg: menuHoverColor,
                          }}
                          icon={<FaPenToSquare />}
                          as={RouterLink}
                          to={`/script/${script._id || '#'}/edit`}
                        >
                          Edit
                        </MenuItem>
                        <MenuItem
                          bg={menuBgColor}
                          _hover={{
                            bg: menuHoverColor,
                          }}
                          icon={<FaClone />}
                          onClick={() => {
                            // Handle duplicate action
                          }}
                        >
                          Duplicate
                        </MenuItem>
                        <MenuDivider />
                        <MenuItem
                          bg={menuBgColor}
                          _hover={{
                            bg: menuHoverColor,
                          }}
                          icon={<FaTrash />}
                          onClick={() => handleDeleteScript(script)}
                        >
                          Delete
                        </MenuItem>
                      </MenuList>
                    </Portal>
                  </Menu>
                </HStack>
              </HStack>
            ))
          ) : (
            <>
              <Text>No Ads Created for this Project.</Text>
              <Button mt={4} as={RouterLink} to={`/generate-ad?projectId=${project._id}`}>Create Ad</Button>
            </>
          )}
        </Box>
      </VStack>
    </MotionBox>
  );
};

export default ProjectCard;
