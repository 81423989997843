import React from 'react';
import { motion } from 'framer-motion';

const LogoAnimation = () => {
  const strokeVariants = {
    hidden: { pathLength: 0, opacity: 1 },
    visible: {
      pathLength: 1,
      opacity: 1,
      transition: {
        duration: 1,
        ease: 'easeInOut',
      },
    },
    fadeOut: {
      opacity: 0,
      transition: {
        delay: 1, // Adjust delay to start after fill animation
        duration: 1,
        ease: 'easeInOut',
      },
    },
  };

  const fillVariants = {
    hidden: { fillOpacity: 0 },
    visible: {
      fillOpacity: 1,
      transition: {
        delay: 1.25, // Delay to ensure stroke animation completes first
        duration: 1,
        ease: 'easeInOut',
      },
    },
  };

  return (
    <motion.svg
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 434 141.8"
      width="200"
      height="65"
      initial="hidden"
      animate="visible"
      variants={{
        visible: {
          transition: {
            staggerChildren: 0.3, // Stagger each letter's stroke animation
          },
        },
      }}
    >
      <motion.polygon
        points="32.1 50 32.1 40.2 71.8 40.2 71.8 50 42.6 50 42.6 66 65.5 66 65.5 75.8 42.6 75.8 42.6 91.8 71.8 91.8 71.8 101.6 32.1 101.6 32.1 58.6 42.6 50 32.1 50"
        stroke="#fff"
        strokeWidth="2"
        fill="none"
        variants={strokeVariants}
        initial="hidden"
        animate="visible"
      />
      <motion.path
        d="M103.5,40.2h-20.2v9.9h10.3l-10.3,8.4v43h10.5v-21.6h9.7c13.9,0,20.8-9.9,20.8-19.8s-7-19.9-20.8-19.9ZM103.6,70h-9.7v-19.9h9.7c13.2,0,13.3,19.9,0,19.9Z"
        stroke="#fff"
        strokeWidth="2"
        fill="none"
        variants={strokeVariants}
        initial="hidden"
        animate="visible"
      />
      <motion.polygon
        points="135.9 40.2 146.4 40.2 146.4 101.5 135.9 101.5 135.9 58.5 146.4 50 135.9 50 135.9 40.2"
        stroke="#fff"
        strokeWidth="2"
        fill="none"
        variants={strokeVariants}
        initial="hidden"
        animate="visible"
      />
      <motion.path
        d="M202.2,88.2l6.1,8.4c-5,4.1-10.3,6.2-18.3,6.2-26.6,0-36.5-25.6-29.4-44.3h12.3c-7.9,12.6-2.1,33.9,17.3,33.9,6.5,0,10-2.5,12-4.2Z"
        stroke="#fff"
        strokeWidth="2"
        fill="none"
        variants={strokeVariants}
        initial="hidden"
        animate="visible"
      />
      <motion.path
        d="M173.3,57.7l-7.6-8.2c5.3-6.4,13.4-10.7,24.4-10.7s13.1,2.3,18.3,6.2l-6.1,8.6c-2.5-2.3-6.9-4.4-12.1-4.4-7.9.1-13.5,3.5-16.9,8.5Z"
        stroke="#fff"
        strokeWidth="2"
        fill="none"
        variants={strokeVariants}
        initial="hidden"
        animate="visible"
      />
      <motion.polygon
        points="231.3 82.3 220.8 91.7 220.8 40.2 231.3 40.2 231.3 82.3"
        stroke="#fff"
        strokeWidth="2"
        fill="none"
        variants={strokeVariants}
        initial="hidden"
        animate="visible"
      />
      <motion.rect
        x="220.8"
        y="91.7"
        width="39.7"
        height="9.8"
        stroke="#fff"
        strokeWidth="2"
        fill="none"
        variants={strokeVariants}
        initial="hidden"
        animate="visible"
      />
      <motion.polygon
        points="255.9 40.2 267.2 40.2 281.4 66.3 295.6 40.2 306.9 40.2 286.7 77.2 286.7 101.6 276.2 101.6 276.2 75.6 276.4 75.6 280.9 67.4 270.7 67 255.9 40.2"
        stroke="#fff"
        strokeWidth="2"
        fill="none"
        variants={strokeVariants}
        initial="hidden"
        animate="visible"
      />
      <motion.path
        d="M303.7,96.1c0-3.2,2.5-5.6,5.7-5.6s5.6,2.5,5.6,5.6-2.5,5.7-5.6,5.7-5.7-2.6-5.7-5.7Z"
        stroke="#5ac7da"
        strokeWidth="2"
        fill="none"
        variants={strokeVariants}
        initial="hidden"
        animate="visible"
      />
      <motion.path
        d="M352.8,40.2h-11l8.4,18.3h-8.4l-19.7,43h11l3.9-8.6h29l3.9,8.6h10.9l-28-61.3ZM341.6,83.1l10-21.7,10,21.7h-20Z"
        stroke="#fff"
        strokeWidth="2"
        fill="none"
        variants={strokeVariants}
        initial="hidden"
        animate="visible"
      />
      <motion.polygon
        points="390.8 40.2 401.3 40.2 401.3 101.5 390.8 101.5 390.8 58.5 401.3 50 390.8 50 390.8 40.2"
        stroke="#fff"
        strokeWidth="2"
        fill="none"
        variants={strokeVariants}
        initial="hidden"
        animate="visible"
      />
      <motion.g variants={fillVariants}>
        <polygon
          className="st1"
          points="32.1 50 32.1 40.2 71.8 40.2 71.8 50 42.6 50 42.6 66 65.5 66 65.5 75.8 42.6 75.8 42.6 91.8 71.8 91.8 71.8 101.6 32.1 101.6 32.1 58.6 42.6 50 32.1 50"
          fill="#fff"
        />
        <path
          className="st1"
          d="M103.5,40.2h-20.2v9.9h10.3l-10.3,8.4v43h10.5v-21.6h9.7c13.9,0,20.8-9.9,20.8-19.8s-7-19.9-20.8-19.9ZM103.6,70h-9.7v-19.9h9.7c13.2,0,13.3,19.9,0,19.9Z"
          fill="#fff"
        />
        <polygon
          className="st1"
          points="135.9 40.2 146.4 40.2 146.4 101.5 135.9 101.5 135.9 58.5 146.4 50 135.9 50 135.9 40.2"
          fill="#fff"
        />
        <path
          className="st1"
          d="M202.2,88.2l6.1,8.4c-5,4.1-10.3,6.2-18.3,6.2-26.6,0-36.5-25.6-29.4-44.3h12.3c-7.9,12.6-2.1,33.9,17.3,33.9,6.5,0,10-2.5,12-4.2Z"
          fill="#fff"
        />
        <path
          className="st1"
          d="M173.3,57.7l-7.6-8.2c5.3-6.4,13.4-10.7,24.4-10.7s13.1,2.3,18.3,6.2l-6.1,8.6c-2.5-2.3-6.9-4.4-12.1-4.4-7.9.1-13.5,3.5-16.9,8.5Z"
          fill="#fff"
        />
        <polygon
          className="st1"
          points="231.3 82.3 220.8 91.7 220.8 40.2 231.3 40.2 231.3 82.3"
          fill="#fff"
        />
        <rect
          className="st1"
          x="220.8"
          y="91.7"
          width="39.7"
          height="9.8"
          fill="#fff"
        />
        <polygon
          className="st1"
          points="255.9 40.2 267.2 40.2 281.4 66.3 295.6 40.2 306.9 40.2 286.7 77.2 286.7 101.6 276.2 101.6 276.2 75.6 276.4 75.6 280.9 67.4 270.7 67 255.9 40.2"
          fill="#fff"
        />
        <path
          className="st0"
          d="M303.7,96.1c0-3.2,2.5-5.6,5.7-5.6s5.6,2.5,5.6,5.6-2.5,5.7-5.6,5.7-5.7-2.6-5.7-5.7Z"
          fill="#5ac7da"
        />
        <path
          className="st1"
          d="M352.8,40.2h-11l8.4,18.3h-8.4l-19.7,43h11l3.9-8.6h29l3.9,8.6h10.9l-28-61.3ZM341.6,83.1l10-21.7,10,21.7h-20Z"
          fill="#fff"
        />
        <polygon
          className="st1"
          points="390.8 40.2 401.3 40.2 401.3 101.5 390.8 101.5 390.8 58.5 401.3 50 390.8 50 390.8 40.2"
          fill="#fff"
        />
      </motion.g>
    </motion.svg>
  );
};

export default LogoAnimation;
