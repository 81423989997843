import React, { useState, useEffect, useMemo } from 'react';
import { Box, Input, Tag, TagLabel, TagCloseButton, Wrap, VStack, Text, Spinner, useColorModeValue } from '@chakra-ui/react';
import { debounce } from 'lodash';
import useSuggestedAudiences from '../../hooks/useSuggestedAudiences';

const TargetAudience = ({ selectedAudience, setSelectedAudience }) => {
  const { fetchSuggestedAudiences, suggestedAudiences, loading } = useSuggestedAudiences();
  const [filteredSuggestions, setFilteredSuggestions] = useState([]);
  const [inputValue, setInputValue] = useState('');

  // Memoize safeSelectedAudience
  const safeSelectedAudience = useMemo(() => 
    Array.isArray(selectedAudience) ? selectedAudience : []
  , [selectedAudience]);

  // Color mode values
  const textColor = useColorModeValue('gray.800', 'white');
  const inputBgColor = useColorModeValue('beige.100', 'whiteAlpha.100');
  const inputBorderColor = useColorModeValue('beige.300', 'gray.600');
  const tagBgColor = useColorModeValue('beige.100', 'beige.400');
  const tagTextColor = useColorModeValue('gray.800', 'white');
  const suggestionHoverBgColor = useColorModeValue('beige.100', 'gunmetal.400');

  useEffect(() => {
    fetchSuggestedAudiences();
  }, [fetchSuggestedAudiences]);

  useEffect(() => {
    const debouncedSearch = debounce((value) => {
      const filteredTags = suggestedAudiences.filter(tag =>
        tag.label.toLowerCase().includes(value.toLowerCase()) &&
        !safeSelectedAudience.some(selected => selected._id === tag._id)
      ).slice(0, 6);
      setFilteredSuggestions(filteredTags);
    }, 300);

    debouncedSearch(inputValue);

    return () => {
      debouncedSearch.cancel();
    };
  }, [inputValue, suggestedAudiences, safeSelectedAudience]);

  const handleAddTag = (event) => {
    if (event.key === 'Enter' && inputValue.trim() !== '') {
      const newTag = {
        _id: { $oid: Date.now().toString() },
        value: inputValue.trim().toLowerCase().replace(/\s+/g, '-'),
        label: inputValue.trim(),
        description: ''
      };
      if (!safeSelectedAudience.some(tag => tag.value === newTag.value)) {
        setSelectedAudience([...safeSelectedAudience, newTag]);
        setInputValue('');
      }
    }
  };

  const handleRemoveTag = (tagToRemove) => {
    const updatedAudience = safeSelectedAudience.filter(tag => tag._id !== tagToRemove._id);
    setSelectedAudience(updatedAudience);
  };

  const handleSuggestionClick = (suggestion) => {
    if (!safeSelectedAudience.some(tag => tag._id === suggestion._id)) {
      const updatedAudience = [...safeSelectedAudience, suggestion];
      setSelectedAudience(updatedAudience);
      setInputValue('');
    }
  };

  useEffect(() => {
    console.log('TargetAudience - selectedAudience:', selectedAudience);
  }, [selectedAudience]);

  return (
    <Box color={textColor}>
      <Wrap mb={4}>
        {safeSelectedAudience.map((tag) => (
          <Tag key={tag._id} size="lg" borderRadius="full" variant="solid" bg={tagBgColor} color={tagTextColor}>
            <TagLabel>{tag.label}</TagLabel>
            <TagCloseButton onClick={() => handleRemoveTag(tag)} />
          </Tag>
        ))}
        <Input
          variant="outline"
          placeholder="Add target audience"
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
          onKeyDown={handleAddTag}
          bg={inputBgColor}
          borderColor={inputBorderColor}
          color={textColor}
        />
      </Wrap>
      <VStack align="start">
        <Text mb={2} color={textColor}>
          Suggested Audiences:
        </Text>
        {loading ? (
          <Spinner />
        ) : (
          <Wrap>
            {filteredSuggestions.map((suggestion) => (
              <Tag
                key={suggestion._id}
                size="lg"
                borderWidth={1}
                borderRadius="full"
                variant="outline"
                cursor="pointer"
                onClick={() => handleSuggestionClick(suggestion)}
                color={textColor}
                _hover={{ bg: suggestionHoverBgColor }}
                _active={{ bg: suggestionHoverBgColor }}
              >
                <TagLabel>{suggestion.label}</TagLabel>
              </Tag>
            ))}
          </Wrap>
        )}
      </VStack>
    </Box>
  );
};

export default TargetAudience;
