import React, { useRef, useState, useEffect } from 'react';
import {
  Text,
  HStack,
  VStack,
  Icon,
  Stack,
  Button,
  Flex,
  Image,
  Skeleton,
  Select,
  ButtonGroup,
  useColorModeValue,
  useToast,
  useBreakpointValue,
} from '@chakra-ui/react';
import SegmentedProgressBar from '../SegmentedProgressBar/SegmentedProgressBar';
import { FaArrowRight } from 'react-icons/fa';
import { useGetVoiceActors } from '../../hooks/useGetVoiceActors';
import { useAdGeneration } from '../../providers/AdGenerationProvider';
import { useNavigate } from 'react-router-dom';
import AudioPlayer, { RHAP_UI } from 'react-h5-audio-player';
import { Global } from '@emotion/react';
import 'react-h5-audio-player/lib/styles.css';

const AudioSelectAVoice = () => {
  const bgColor = useColorModeValue('beige.200', 'gunmetal.700');
  const textColor = useColorModeValue('gray.800', 'white');
  const iconColor = useColorModeValue('gray.500', 'gunmetal.400');
  const rowBgColor = useColorModeValue('beige.200', 'blackAlpha.200');
  const cardBgColor = useColorModeValue('beige.100', 'gradBlack');
  const cardBgHoverColor = useColorModeValue('white', 'gradBlackHover');
  const borderColor = useColorModeValue('gray.200', 'gunmetal.550');
  const cardHeadingColor = useColorModeValue('gunmetal.500', 'beige.300');
  const selectButtonBgColor = useColorModeValue('transparent', 'transparent');
  const selectButtonBorderColor = useColorModeValue('gunmetal.500', 'white');
  const selectedButtonBgColor = useColorModeValue('gunmetal.500', 'beige.300');
  const selectedButtonBorderColor = useColorModeValue(
    'gunmetal.500',
    'beige.300'
  );
  const selectButtonTextColor = useColorModeValue('gunmetal.500', 'white');
  const selectButtonHoverTextColor = useColorModeValue('white', 'white');
  const selectedButtonHoverTextColor = useColorModeValue('white', 'white');
  const selectedButtonTextColor = useColorModeValue('white', 'white');
  const selectedButtonHoverBgColor = useColorModeValue(
    'gunmetal.500',
    'beige.300'
  );
  const selectButtonHoverBgColor = useColorModeValue(
    'gunmetal.500',
    'beige.300'
  );
  const badgeBgColor = useColorModeValue('beige.200', 'beige.200');
  const badgeTextColor = useColorModeValue('gunmetal.500', 'gunmetal.600');
  const badgeHoverBgColor = useColorModeValue('beige.300', 'beige.300');
  const loadMoreBgColor = useColorModeValue('beige.200', 'gunmetal.500');
  const loadMoreTextColor = useColorModeValue('gunmetal.500', 'white');
  const loadMoreHoverBgColor = useColorModeValue('beige.300', 'gunmetal.400');
  const backButtonBgColor = useColorModeValue('gradBeige', 'gradBlack');
  const backButtonHoverBgColor = useColorModeValue(
    'gradBeigeHover',
    'gradBlackHover'
  );
  const nextButtonBgColor = useColorModeValue('gradGunmetal', 'gradGunmetal');
  const nextButtonHoverBgColor = useColorModeValue(
    'gradGunmetalHover',
    'gradGunmetalHover'
  );
  const playerBgColor = useColorModeValue('gray.300', '#232C33');
  const controlColor = useColorModeValue('gray.800', 'white');
  const controlHoverColor = useColorModeValue('gray.600', 'gray.300');
  const progressBarBg = useColorModeValue('gray.300', 'gray.600');
  const progressFilledBg = useColorModeValue('blue.500', 'blue.300');
  const boxBorderColor = useColorModeValue('gray.200', '#2F4957');
  const stackDirection = useBreakpointValue({
    base: 'column',
    md: 'column',
    lg: 'row',
  });
  const textAlign = useBreakpointValue({ base: 'center', md: 'left' });
  const imageSize = useBreakpointValue({ base: '100px', md: '125px' });

  const { voiceActors, loading } = useGetVoiceActors();
  const { setVoiceActor, voiceActor, totalSteps } = useAdGeneration();

  const navigate = useNavigate();
  const toast = useToast();
  const currentAudioRef = useRef(null);
  const [filters, setFilters] = useState({
    gender: '',
    ageRange: '',
    emotion: '',
    style: '',
    tone: '',
  });
  const [showAll, setShowAll] = useState(false);

  useEffect(() => {
    return () => {
      if (currentAudioRef.current) {
        currentAudioRef.current.pause();
      }
    };
  }, []);

  const handleFilterChange = e => {
    const { name, value } = e.target;
    setFilters({
      ...filters,
      [name]: value,
    });
  };

  const filteredActors = voiceActors.filter(actor => {
    return (
      (!filters.gender || actor.gender === filters.gender) &&
      (!filters.ageRange || actor.ageRange === filters.ageRange) &&
      (!filters.emotion || actor.deliveryEmotion === filters.emotion) &&
      (!filters.style || actor.deliveryStyle === filters.style) &&
      (!filters.tone || actor.deliveryTone === filters.tone)
    );
  });

  const suggestedActors = filteredActors.filter(actor =>
    ['Bernice', 'Andrew', 'Claire'].includes(actor.name)
  );

  const handleLoadMore = () => {
    setShowAll(true);
  };

  const handleBack = () => {
    navigate(-1);
  };

  const handleNext = () => {
    if (voiceActor) {
      navigate('/script-generating');
    } else {
      toast({
        title: 'Please select a voice',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleSelectActor = actor => {
    if (voiceActor?.name === actor.name) {
      setVoiceActor(null);
    } else {
      setVoiceActor(actor);
    }
  };

  // Define filter configurations
  const filterOptions = [
    {
      name: 'gender',
      placeholder: 'Gender',
      options: [
        { value: 'Female', label: 'Female' },
        { value: 'Male', label: 'Male' },
      ],
    },
    {
      name: 'ageRange',
      placeholder: 'Age Range',
      options: [
        { value: '20-30', label: '20-30' },
        { value: '25-35', label: '25-35' },
        { value: '30-40', label: '30-40' },
        { value: '35-45', label: '35-45' },
        { value: '40-50', label: '40-50' },
        { value: '65+', label: '65+' },
      ],
    },
    {
      name: 'emotion',
      placeholder: 'Emotion',
      options: [
        { value: 'Friendly', label: 'Friendly' },
        { value: 'Calm', label: 'Calm' },
        { value: 'Confident', label: 'Confident' },
        { value: 'Approachable', label: 'Approachable' },
        { value: 'Heartfelt', label: 'Heartfelt' },
        { value: 'Determined', label: 'Determined' },
      ],
    },
    {
      name: 'style',
      placeholder: 'Style',
      options: [
        { value: 'Conversational', label: 'Conversational' },
        { value: 'Professional', label: 'Professional' },
        { value: 'Gentle', label: 'Gentle' },
        { value: 'Energetic', label: 'Energetic' },
        { value: 'Authoritative', label: 'Authoritative' },
      ],
    },
    {
      name: 'tone',
      placeholder: 'Tone',
      options: [
        { value: 'Warm', label: 'Warm' },
        { value: 'Soft', label: 'Soft' },
        { value: 'Smooth', label: 'Smooth' },
        { value: 'Clear', label: 'Clear' },
        { value: 'Deep', label: 'Deep' },
        { value: 'Crisp', label: 'Crisp' },
        { value: 'Bright', label: 'Bright' },
      ],
    },
  ];

  return (
    <>
      {/* Global Styles for Audio Player */}
      <Global
        styles={`
          .rhap_container {
            background-color: ${playerBgColor} !important;
            border-radius: 8px;
            border: 1px solid ${boxBorderColor};
            color: ${controlColor};
          }
          .rhap_additional-controls {
            display: none !important;
          }
          .rhap_volume-controls {
            padding-right: 10px !important;
          }
          .rhap_button-clear {
            color: ${controlColor} !important;
          }
          .rhap_time {
            color: ${controlColor} !important;
          }
          .rhap_button-clear:hover {
            color: ${controlHoverColor} !important;
          }
          .rhap_progress-bar {
            background: ${progressBarBg} !important;
          }
          .rhap_progress-filled {
            background: ${progressFilledBg} !important;
          }
        `}
      />

      <Flex
        direction={'column'}
        p={8}
        bg={bgColor}
        color={textColor}
        borderRadius="md"
        w="full"
      >
        {/* // Section Step // */}
        <Stack direction={stackDirection} align={'center'} mb={2}>
          <Text fontSize={{ base: 'lg', md: '2xl' }} textAlign={textAlign}>
            Step 3
          </Text>
          <Icon as={FaArrowRight} w={6} h={6} color={iconColor} mx={4} />
          <Text fontSize={{ base: 'lg', md: '2xl' }} textAlign={textAlign}>
            Select a Voice
          </Text>
        </Stack>
        <Text mb={8} textAlign={textAlign}>
          Choose your voice for your ad.
        </Text>

        <SegmentedProgressBar currentStep={3} totalSteps={totalSteps} />

        {/* // Filter Options // */}
        <Stack
          direction={{ base: 'column', md: 'row' }}
          spacing={2}
          mb={8}
          flexWrap="wrap"
          justifyContent={{ base: 'center', md: 'flex-start' }}
        >
          {filterOptions.map(filter => (
            <Select
              key={filter.name}
              placeholder={filter.placeholder}
              name={filter.name}
              maxW={{ base: 'full', md: '150px' }}
              onChange={handleFilterChange}
            >
              {filter.options.map(option => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </Select>
          ))}
        </Stack>

        {showAll ? null : (
          <Text fontSize="xl" fontWeight="bold" textAlign={textAlign}>
            Suggested for you
          </Text>
        )}

        <VStack
          spacing={4}
          align="stretch"
          mt={8}
          bg={rowBgColor}
        >
          {loading
            ? Array.from({ length: 6 }).map((_, index) => (
                <HStack
                  key={index}
                  spacing={4}
                  bg={cardBgColor}
                  borderColor={borderColor}
                  borderWidth={1}
                  borderRadius={'md'}
                  p={4}
                  flexDirection={stackDirection}
                >
                  <Skeleton
                    height={imageSize}
                    width={imageSize}
                    borderRadius="full"
                  />
                  <VStack align="stretch" spacing={0} p={4} maxW={'300px'}>
                    <Skeleton height="20px" width="100px" />
                    <Skeleton height="40px" width="100%" />
                    <Skeleton height="20px" width="100px" />
                    <Skeleton height="60px" width="100%" />
                  </VStack>
                  <VStack>
                    <Skeleton height="50px" width="200px" />
                    <HStack mt={4} spacing={2}>
                      <Skeleton
                        height="40px"
                        width="80px"
                        borderRadius="full"
                      />
                      <Skeleton
                        height="40px"
                        width="80px"
                        borderRadius="full"
                      />
                      <Skeleton
                        height="40px"
                        width="80px"
                        borderRadius="full"
                      />
                    </HStack>
                  </VStack>
                </HStack>
              ))
            : (showAll ? filteredActors : suggestedActors).map(
                (actor, index) => (
                  <Stack
                    direction={{ base: 'column', md: 'row' }}
                    align={'center'}
                    justify={'space-between'}
                    key={index}
                    spacing={4}
                    bg={cardBgColor}
                    borderRadius={'md'}
                    borderColor={borderColor}
                    borderWidth={1}
                    p={4}
                    _hover={{ bg: cardBgHoverColor }}
                    flexDirection={stackDirection}
                    flex={1}
                  >
                    {/* // Image // */}
                    <Image
                      src={actor.image}
                      alt={actor.name}
                   
                      h={imageSize}
                      maxH={'100%'}
                      w={imageSize}
                      borderRadius={'full'}
                      aspectRatio={'1 / 1'}
                    />

                    {/* // Description // */}
                    <VStack align={'stretch'} spacing={0} p={4} maxW={'300px'}>
                      <Text
                        fontSize={'md'}
                        textTransform={'uppercase'}
                        color={cardHeadingColor}
                      >
                        Name
                      </Text>
                      <Text fontSize={'lg'} mb={4} fontWeight={'bold'}>
                        {actor.name}
                      </Text>

                      <Text
                        fontSize={'md'}
                        textTransform={'uppercase'}
                        color={cardHeadingColor}
                      >
                        Description
                      </Text>
                      <Text fontSize={'md'}>{actor.description}</Text>
                    </VStack>

                    <VStack align={'stretch'} flex={1}>
                      {/* // Audio Player // */}
                      <Flex>
                        <AudioPlayer
                          src={actor.sampleAudio}
                          autoPlayAfterSrcChange={false}
                          showJumpControls={false}
                          customAdditionalControls={[
                            ...RHAP_UI.ADDITIONAL_CONTROLS,
                          ]}
                          layout="horizontal-reverse"
                          onPlay={(e) => {
                            if (
                              currentAudioRef.current &&
                              currentAudioRef.current !== e.target
                            ) {
                              currentAudioRef.current.pause();
                            }
                            currentAudioRef.current = e.target;
                          }}
                        />
                      </Flex>

                      <HStack
                        my={4}
                        spacing={2}
                        justify={'space-between'}
                        w={'full'}
                      >
                        {/* // Descriptor Badge // */}
                        <Flex
                          bg={badgeBgColor}
                          flex={1}
                          color={badgeTextColor}
                          px={4}
                          py={2}
                          borderRadius={'full'}
                          align={'center'}
                          justify={'center'}
                          _hover={{ bg: badgeHoverBgColor }}
                          _active={{ bg: badgeHoverBgColor }}
                          _focus={{ boxShadow: 'none' }}
                        >
                          <Text fontSize={'md'} fontWeight={'700'}>
                            {actor.deliveryEmotion}
                          </Text>
                        </Flex>

                        {/* // Descriptor Badge // */}
                        <Flex
                          bg={badgeBgColor}
                          flex={1}
                          color={badgeTextColor}
                          px={4}
                          py={2}
                          borderRadius={'full'}
                          align={'center'}
                          justify={'center'}
                          _hover={{ bg: badgeHoverBgColor }}
                          _active={{ bg: badgeHoverBgColor }}
                          _focus={{ boxShadow: 'none' }}
                        >
                          <Text fontSize={'md'} fontWeight={'700'}>
                            {actor.deliveryTone}
                          </Text>
                        </Flex>

                        {/* // Descriptor Badge // */}
                        <Flex
                          bg={badgeBgColor}
                          flex={1}
                          color={badgeTextColor}
                          px={4}
                          py={2}
                          borderRadius={'full'}
                          align={'center'}
                          justify={'center'}
                          _hover={{ bg: badgeHoverBgColor }}
                          _active={{ bg: badgeHoverBgColor }}
                          _focus={{ boxShadow: 'none' }}
                        >
                          <Text fontSize={'md'} fontWeight={'700'}>
                            {actor.deliveryStyle}
                          </Text>
                        </Flex>
                      </HStack>

                      <Button
                        variant={
                          voiceActor?.name === actor.name ? 'solid' : 'outline'
                        }
                        borderRadius={'full'}
                        w={'100%'}
                        py={6}
                        bg={
                          voiceActor?.name === actor.name
                            ? selectedButtonBgColor
                            : selectButtonBgColor
                        }
                        borderColor={
                          voiceActor?.name === actor.name
                            ? selectedButtonBorderColor
                            : selectButtonBorderColor
                        }
                        color={
                          voiceActor?.name === actor.name
                            ? selectedButtonTextColor
                            : selectButtonTextColor
                        }
                        _hover={{
                          bg:
                            voiceActor?.name === actor.name
                              ? selectedButtonHoverBgColor
                              : selectButtonHoverBgColor,
                          color:
                            voiceActor?.name === actor.name
                              ? selectedButtonHoverTextColor
                              : selectButtonHoverTextColor,
                        }}
                        onClick={() => handleSelectActor(actor)}
                      >
                        {voiceActor?.name === actor.name
                          ? 'Selected'
                          : 'Select'}
                      </Button>
                    </VStack>
                  </Stack>
                )
              )}

          {!showAll && (
            <Button
              onClick={handleLoadMore}
              mt={4}
              bg={loadMoreBgColor}
              _hover={{ bg: loadMoreHoverBgColor }}
              color={loadMoreTextColor}
            >
              Load More
            </Button>
          )}
        </VStack>

        <ButtonGroup
          size={'lg'}
          spacing="6"
          isAttached
          borderRadius={'md'}
          mt={10}
          justifyContent="center"
        >
          <Button
            bg={backButtonBgColor}
            _hover={{ bg: backButtonHoverBgColor }}
            onClick={handleBack}
          >
            Back
          </Button>
          <Button
            bg={nextButtonBgColor}
            _hover={{ bg: nextButtonHoverBgColor }}
            onClick={handleNext}
            isDisabled={!voiceActor}
          >
            Next
          </Button>
        </ButtonGroup>
      </Flex>
    </>
  );
};

export default AudioSelectAVoice;
