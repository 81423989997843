import React, { useState, useCallback, useEffect } from 'react';
import {
  Heading,
  Container,
  Flex,
  Button,
  Input,
  Box,
  FormControl,
  FormLabel,
} from '@chakra-ui/react';
import { useUserContext } from '../providers/UserProvider';
import { AnimatePresence } from 'framer-motion';
import { useNavigate, useLocation } from 'react-router-dom';
import { pageTransition, pageVariants, MotionFlex } from '../config/config';
import useInvite from '../hooks/useInvite';

const ProfileSetup = () => {
  const { logout, token } = useUserContext();
  const navigate = useNavigate();
  const location = useLocation();
  const { validateCode, isValidating, error } = useInvite();

  const inviteCodeFromParams = new URLSearchParams(location.search).get(
    'inviteCode'
  );
  const [inviteCode, setInviteCode] = useState(inviteCodeFromParams || '');

  useEffect(() => {
    const queryParams = sessionStorage.getItem('queryParams');
    if (queryParams) {
      const params = new URLSearchParams(queryParams);
      const inviteCode = params.get('inviteCode');
      if (inviteCode) {
        setInviteCode(inviteCode);
      }
    }
  }, []);

  const handleInviteCodeSubmit = useCallback(
    async e => {
      try {
        const result = await validateCode(inviteCode, token);
        if (result) {
          sessionStorage.setItem('inviteCodeValidated', 'true');
          navigate('/subscription-pricing');
        }
      } catch (error) {
        console.error('Error with invite code:', error);
      }
    },
    [inviteCode, token, validateCode, navigate]
  );

  return (
    <Flex minHeight="100vh" align="center" justify="center">
      <Container maxW="container.xl" p={4} overflowX={'hidden'}>
        <AnimatePresence mode="wait">
          <MotionFlex
            key="profile-setup"
            initial="initial"
            animate="in"
            exit="out"
            variants={pageVariants}
            transition={pageTransition}
            flexDir={'column'}
            p={10}
            bg={'gunmetal.550'}
            rounded={'xl'}
            shadow={'2xl'}
            justifyContent={'center'}
          >
            <Heading mb="5" textAlign={'center'}>
              Profile Setup
            </Heading>
            <Box mb={5}>
              <FormControl>
                <FormLabel htmlFor="invite-code">Invite Code</FormLabel>
                <Input
                  id="invite-code"
                  type="text"
                  value={inviteCode}
                  onChange={e => setInviteCode(e.target.value)}
                  mb={3}
                  isInvalid={error !== null}
                />
              </FormControl>
              {error && (
                <Box color="red.200" mb={3}>
                  {error}
                </Box>
              )}
              <Button
                onClick={handleInviteCodeSubmit}
                colorScheme="teal"
                _hover={{
                  bg: 'gunmetal.400',
                }}
                isDisabled={isValidating}
                loadingText="Validating..."
                isLoading={isValidating}
              >
                Submit Invite Code
              </Button>
            </Box>
            <Button
              onClick={() => logout({ returnTo: window.location.origin })}
              colorScheme={'blue'}
              mt={5}
              _hover={{
                bg: 'gunmetal.400',
              }}
              isDisabled={isValidating}
              isLoading={isValidating}
            >
              Logout
            </Button>
          </MotionFlex>
        </AnimatePresence>
      </Container>
    </Flex>
  );
};

export default ProfileSetup;
