import api from '../utils/api.utils';
import { handleResponse } from '../utils/app.utils';

export const getVoiceActors = async token => {
  const response = await api.get(`/voice-actors`, { customHeaders: { token } });
  return handleResponse(response, 'Error getting scripts');
};

export const getVoiceActorById = async (id, token) => {
  const response = await api.get(`/voice-actors/${id}`, {
    customHeaders: { token },
  });
  return handleResponse(response, 'Error getting voice actor by ID');
};

