import React, { useState } from 'react';
import { Icon, Stack, useBreakpointValue } from '@chakra-ui/react';
import { FaStar, FaRegStar } from 'react-icons/fa';
import { motion } from 'framer-motion';
import { pulseAnimation } from '../../config/config';

const AirBNBRating = ({
  readOnly = false,
  totalStars = 5,
  initialRating = 0,
  onRatingChange = null,
}) => {
  const [rating, setRating] = useState(initialRating);
  const [hover, setHover] = useState(0);
  const starSize = useBreakpointValue({ base: '6', md: '8', lg: '10' });

  const handleRating = newRating => {
    if (!readOnly) {
      setRating(newRating);
      if (onRatingChange) onRatingChange(newRating);
    }
  };

  return (
    <Stack
      direction="row"
      justify={'space-between'}
      spacing={2}
      onMouseLeave={() => setHover(0)}
    >
      {[...Array(totalStars)].map((_, index) => {
        const ratingValue = index + 1;

        const shouldPulse = ratingValue <= (hover || rating);

        return (
          <motion.div
            whileHover={{ scale: 1.2 }}
            whileTap={{ scale: 0.9 }}
            onClick={() => handleRating(ratingValue)}
            onMouseEnter={() => !readOnly && setHover(ratingValue)}
            style={{ cursor: readOnly ? 'default' : 'pointer' }}
            animate={shouldPulse ? pulseAnimation : {}}
            display="flex"
            key={index}
          >
            <Icon
              as={ratingValue <= (hover || rating) ? FaStar : FaRegStar}
              boxSize={starSize}
              color={ratingValue <= (hover || rating) ? 'teal.500' : 'gray.300'}
            />
          </motion.div>
        );
      })}
    </Stack>
  );
};

export default AirBNBRating;
