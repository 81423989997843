import React from 'react';
import AdStart from '../components/AdStart/AdStart';
import WelcomeModal from '../components/WelcomeModal/WelcomeModal';

const GenerateAd = () => {

  return (
    <>
      <WelcomeModal />
      <AdStart />
    </>
  );
};

export default GenerateAd;
