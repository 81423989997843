import React from 'react';
import { Checkbox, Flex, Icon, Stack, Text } from '@chakra-ui/react';
import { FaArrowRight } from 'react-icons/fa';
import { motion } from 'framer-motion';
import { useSignupFormContext } from '../../providers/SignupFormProvider';
import { useEffect } from 'react';

const MotionFlex = motion(Flex);

const variants = {
  initial: { opacity: 0, x: 50 },
  animate: { opacity: 1, x: 0 },
  exit: { opacity: 0, x: -50 },
};

const ProfileSetupTypeOfContent = ({ step }) => {
  const { contentTypes, setContentTypes } = useSignupFormContext();

  const handleCheckboxChange = (index) => {
    // Create a new array with updated checked state
    const updatedContentTypes = contentTypes.map((item, i) =>
      i === index ? { ...item, isChecked: !item.isChecked } : item
    );

    setContentTypes(updatedContentTypes);
  };

  return (
    <MotionFlex
      flexDir={'column'}
      justify={'center'}
      align={'stretch'}
      key="step1"
      variants={variants}
      initial="initial"
      animate="animate"
      exit="exit"
      transition={{ duration: 0.5 }}
      flex={1}
    >
      {/* Section Step */}
      <Stack direction={{ base: 'column', md: 'row' }} align={'center'}>
        <Text fontSize={{ base: 'xl', md: '2xl' }}>{step - 1}</Text>
        <Icon as={FaArrowRight} w={{ base: 4, md: 6 }} h={{ base: 4, md: 6 }} color="gunmetal.300" mr={{ base: 2, md: 4 }} />
        <Text fontSize={{ base: 'lg', md: '2xl' }}>
          What types of content do you typically make?
        </Text>
      </Stack>

      <Text fontSize={{ base: 'sm', md: 'md' }} mb={8} color={'gray.300'}>
        Select any that may apply.
      </Text>

      {contentTypes.map((option, index) => (
        <Checkbox
          key={option.label}
          isChecked={option.isChecked}
          onChange={() => handleCheckboxChange(index)}
          size="lg"
          colorScheme="teal"
          iconColor="gunmetal.500"
          value={option.label}
        >
          <Flex
            rounded="xl"
            m={2}
            p={4}
            bg="whiteAlpha.100"
            _hover={{ bg: 'whiteAlpha.200' }}
            width="100%"
          >
            <Text>{option.label}</Text>
          </Flex>
        </Checkbox>
      ))}
    </MotionFlex>
  );
}

export default ProfileSetupTypeOfContent;
