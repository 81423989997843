import React, { useState } from 'react';
import {
  Heading,
  Text,
  Container,
  Divider,
  Stack,
  Link as ChakraLink,
  useColorModeValue,
  Skeleton,
  HStack,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  MenuDivider,
  Tooltip,
  useDisclosure,
} from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import { AnimatePresence } from 'framer-motion';
import { DeleteIcon, EditIcon } from '@chakra-ui/icons';
import { FaFileExport, FaEllipsis } from 'react-icons/fa6';
import { FaAd } from 'react-icons/fa';
import { MotionBox, containerVariants, itemVariants } from '../config/config';
import useGetProjects from '../hooks/useGetProjects';
import useGetAllScripts from '../hooks/useGetAllScripts';
import useDownloadScript from '../hooks/useDownloadScript';
import useDeleteScript from '../hooks/useDeleteScript';
import DeleteItemModal from '../components/DeleteItemModal/DeleteItemModal';
import ExportScriptModal from '../components/ExportScriptModal/ExportScriptModal';

const Scripts = () => {
  const bgColor = useColorModeValue('beige.200', 'gunmetal.700');
  const borderColor = useColorModeValue('beige.100', 'gunmetal.550');
  const cardBgColor = useColorModeValue('beige.300', 'gunmetal.600');
  const headingColor = useColorModeValue('gray.800', 'white');
  const headingHoverColor = useColorModeValue('gray.600', 'beige.200');
  const textColor = useColorModeValue('gray.600', 'gray.400');
  const linkHoverColor = useColorModeValue('gray.600', 'beige.100');
  const projectLinkColor = useColorModeValue('gray.600', 'beige.200');
  const buttonBgColor = useColorModeValue('beige.300', 'beige.300');
  const buttonHoverColor = useColorModeValue('beige.400', 'beige.400');
  const buttonTextColor = useColorModeValue('gray.800', 'white');
  const iconColor = useColorModeValue('gray.600', 'white');

  const { projects, isLoading: projectsLoading } = useGetProjects();
  const { scripts, loading: scriptsLoading } = useGetAllScripts(projects);
  const { newDownloadData } = useDownloadScript();
  const deleteScript = useDeleteScript();

  const [itemToDelete, setItemToDelete] = useState(null);
  const [selectedScriptId, setSelectedScriptId] = useState(null);
  const [selectedScriptTitle, setSelectedScriptTitle] = useState(null);

  const {
    isOpen: isDeleteOpen,
    onOpen: onDeleteOpen,
    onClose: onDeleteClose,
  } = useDisclosure();

  const {
    isOpen: isExportOpen,
    onOpen: onExportOpen,
    onClose: onExportClose,
  } = useDisclosure();

  const handleDeleteClick = (script) => {
    setItemToDelete(script);
    onDeleteOpen();
  };

  const handleDeleteConfirm = async () => {
    if (itemToDelete) {
      await deleteScript(itemToDelete._id);
      // Refresh scripts after deletion
      // You might need to implement a refetch function for scripts
    }
    onDeleteClose();
  };

  const handleDownloadScript = (scriptId, scriptTitle) => {
    setSelectedScriptId(scriptId);
    setSelectedScriptTitle(scriptTitle);
    onExportOpen();
  };

  const handleExport = async (type) => {
    if (selectedScriptId && selectedScriptTitle) {
      await newDownloadData(selectedScriptId, type, selectedScriptTitle);
    }
  };

  if (projectsLoading || scriptsLoading) {
    return (
      <Container maxW="container.xl" p={8}>
        <Stack spacing={8}>
          {[...Array(3)].map((_, index) => (
            <Skeleton key={index} height="200px" />
          ))}
        </Stack>
      </Container>
    );
  }

  const flattenedScripts = Object.values(scripts).map((script) => ({
    ...script,
    projectName: projects.find((project) => project._id === script.project).title,
  }));

  return (
    <Container
      maxW="container.xl"
      p={8}
      bg={bgColor}
      borderRadius="lg"
      boxShadow="xl"
      border={'1px solid'}
      borderColor={borderColor}
    >
      <Heading as="h1" mb={4} color={headingColor}>
        Scripts
      </Heading>
      <Divider borderColor={borderColor} mb={8} />

      <AnimatePresence>
        <MotionBox
          variants={containerVariants}
          initial="hidden"
          animate="show"
        >
          <Stack spacing={6}>
            {flattenedScripts.map((script, index) => (
              <MotionBox
                key={script._id}
                variants={itemVariants}
                custom={index}
                layout
              >
                <MotionBox
                  p={6}
                  borderRadius="md"
                  boxShadow="lg"
                  bg={cardBgColor}
                  border='1px solid'
                  borderColor={borderColor}
                  shadow={'md'}
                >
                  <HStack justify="space-between" align="flex-start">
                    <MotionBox flex={1}>
                      <ChakraLink 
                        as={Link} 
                        to={`/script/${script._id}/edit`}
                        _hover={{ textDecoration: 'none' }}
                      >
                        <Heading 
                          as="h2" 
                          size="md" 
                          mb={4} 
                          color={headingColor} 
                          transition="color 0.2s ease"
                          _hover={{ color: headingHoverColor }}
                        >
                          {`"${script.title}"`}
                        </Heading>
                      </ChakraLink>
                      
                      <Text 
                        fontSize={'sm'} 
                        color={textColor} 
                        textTransform={'uppercase'}
                      >
                        Project
                      </Text>

                      <ChakraLink 
                        as={Link} 
                        to={`/projects/${script.project}/edit`}
                        _hover={{ textDecoration: 'none' }}
                      >
                        <Heading
                          as="h3"
                          size="xs"
                          mt={1}
                          fontWeight="normal"
                          color={projectLinkColor}
                          transition="color 0.2s ease"
                          _hover={{ color: linkHoverColor }}
                        >
                          {script.projectName}
                        </Heading>
                      </ChakraLink>

                      <Text mt={4} color={textColor}>
                        {script.type}
                      </Text>
                    </MotionBox>
                    <HStack>
                      <Tooltip label="Export Script">
                        <IconButton
                          icon={<FaFileExport />}
                          variant={'ghost'}
                          borderRadius={'md'}
                          color={buttonTextColor}
                          transition="all 0.35s ease"
                          onClick={() => handleDownloadScript(script._id, script.title)}
                          _hover={{
                            bg: buttonHoverColor,
                            transform: 'scale(1.1)',
                          }}
                        />
                      </Tooltip>
                      <Tooltip label="Export Ad">
                        <IconButton
                          icon={<FaAd />}
                          variant={'ghost'}
                          borderRadius={'md'}
                          color={buttonTextColor}
                          transition="all 0.35s ease"
                          onClick={() => console.log('Export Ad')}
                          _hover={{
                            bg: buttonHoverColor,
                            transform: 'scale(1.1)',
                          }}
                        />
                      </Tooltip>
                      <Menu>
                        <MenuButton
                          as={IconButton}
                          icon={<FaEllipsis />}
                          variant={'ghost'}
                          borderRadius={'md'}
                          color={iconColor}
                          transition="all 0.35s ease"
                          _hover={{
                            bg: buttonHoverColor,
                            transform: 'scale(1.1)',
                          }}
                        />
                        <MenuList bg={cardBgColor}>
                          <MenuItem
                            as={Link}
                            to={`/script/${script._id}/edit`}
                            icon={<EditIcon />}
                            bg={cardBgColor}
                            _hover={{ bg: buttonHoverColor }}
                          >
                            Edit
                          </MenuItem>
                          <MenuDivider />
                          <MenuItem
                            icon={<DeleteIcon />}
                            onClick={() => handleDeleteClick(script)}
                            bg={cardBgColor}
                            _hover={{ bg: buttonHoverColor }}
                          >
                            Delete
                          </MenuItem>
                        </MenuList>
                      </Menu>
                    </HStack>
                  </HStack>
                </MotionBox>
              </MotionBox>
            ))}
          </Stack>
        </MotionBox>
      </AnimatePresence>

      <DeleteItemModal
        isOpen={isDeleteOpen}
        onClose={onDeleteClose}
        itemName={itemToDelete?.title || ''}
        itemType="script"
        onDelete={handleDeleteConfirm}
      />

      <ExportScriptModal
        isOpen={isExportOpen}
        onClose={onExportClose}
        onExport={handleExport}
        mediaType={'Script'}
      />
    </Container>
  );
};

export default Scripts;
