import { useState } from 'react';
import { useToast } from '@chakra-ui/react';
import { useUserContext } from '../providers/UserProvider';
import { addSongToScript as addSongToScriptService } from '../services/music.service';
import { removeSongFromScript as removeSongFromScriptService } from '../services/music.service';

const useManageMusic = () => {
  const { token } = useUserContext();
  const toast = useToast();

  const [musicLoading, setMusicLoading] = useState(false);

  const addSongToScript = async ( scriptId, musicId ) => {
    setMusicLoading(true);

    try {
      const response = await addSongToScriptService(
        scriptId,
        musicId,
        token,
      );
      return response;
    } catch (error) {
      console.error('Error adding audio to project:', error);
      toast({
        title: 'Error',
        description: 'Error adding audio to project',
        status: 'error',
        duration: 9000,
        isClosable: true,
      });
      throw error;
    } finally {
      setMusicLoading(false);
    }
  };

  const removeSongFromScript = async ( scriptId, musicId, deleteSong ) => {
    setMusicLoading(true);

    try {
      const response = await removeSongFromScriptService(
        scriptId,
        musicId,
        deleteSong,
        token,
      );
      return response;
    } catch (error) {
      console.error('Error removing audio from project:', error);
      throw error;
    } finally {
      setMusicLoading(false);
    }
  };

  return { addSongToScript, removeSongFromScript, musicLoading };
};

export default useManageMusic;
