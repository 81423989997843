import React from 'react';
import {
  Box,
  Heading,
  Grid,
  Tooltip,
  Icon,
  Text,
  Badge,
  useColorModeValue,
  Skeleton,
  SkeletonText,
  SkeletonCircle,
} from '@chakra-ui/react';
import { FaTrophy, FaLock } from 'react-icons/fa';
import useAchievements from '../hooks/useAchievements';

const Achievements = () => {
  const { achievements, userAchievements, loadingAchievements } = useAchievements();
  const cardBg = useColorModeValue('white', 'gray.700');
  const cardHoverBg = useColorModeValue('gray.50', 'gray.600');
  const bg = useColorModeValue('gunmetal.500', 'gunmetal.700');
  const borderColor = useColorModeValue('gunmetal.300', 'gunmetal.500');
  const lockedOpacity = 0.6;
  const skeletonCount = 6;
  const skeletonArray = Array(skeletonCount).fill(0);
  
  // Sort Achievements by unlocked
  const sortedAchievements = achievements.sort((a, b) => {
    const aUnlocked = userAchievements.includes(a._id);
    const bUnlocked = userAchievements.includes(b._id);
    return bUnlocked - aUnlocked;
  });

  return (
    <Box
      maxW="4xl"
      mx="auto"
      p={6}
      bg={bg}
      rounded="lg"
      shadow="lg"
      border="1px solid"
      borderColor={borderColor}
    >
      <Heading as="h2" size="xl" mb={6} textAlign="center" color={'white'}>
        Achievements
      </Heading>
      <Grid
        templateColumns={{
          base: '1fr',
          sm: 'repeat(2, 1fr)',
          md: 'repeat(3, 1fr)',
        }}
        gap={4}
      >
        {loadingAchievements ? (
          skeletonArray.map((_, index) => (
            <Box
              key={index}
              p={4}
              bg={cardBg}
              rounded="md"
              shadow="md"
              textAlign="center"
            >
              <SkeletonCircle size="10" mb={4} />
              <Skeleton height="20px" mb={2} />
              <SkeletonText mt="4" noOfLines={2} spacing="4" />
              <Skeleton height="20px" mt={4} width="50%" mx="auto" />
            </Box>
          ))
        ) : (
          sortedAchievements.map((achievement) => {
            const earned = userAchievements.includes(achievement._id);
            return (
              <Tooltip
                key={achievement._id}
                label={
                  earned
                    ? 'Achievement unlocked!'
                    : 'Keep working to unlock this achievement!'
                }
                placement="bottom"
                hasArrow
              >
                <Box
                  p={4}
                  bg={cardBg}
                  rounded="md"
                  shadow="md"
                  cursor="pointer"
                  transition="all 0.3s"
                  _hover={{ shadow: 'lg', bg: cardHoverBg }}
                  opacity={earned ? 1 : lockedOpacity}
                  textAlign="center"
                >
                  <Icon
                    as={earned ? FaTrophy : FaLock}
                    w={8}
                    h={8}
                    color={earned ? 'yellow.400' : 'gray.400'}
                    mb={2}
                  />
                  <Heading as="h3" size="md" mb={1}>
                    {achievement.name}
                  </Heading>
                  <Text fontSize="sm" color="gray.500" mb={2}>
                    {achievement.description}
                  </Text>
                  <Badge
                    colorScheme={earned ? 'green' : 'gray'}
                    variant={earned ? 'solid' : 'subtle'}
                    mt={2}
                  >
                    {earned ? 'Earned' : 'Locked'}
                  </Badge>
                </Box>
              </Tooltip>
            );
          })
        )}
      </Grid>
    </Box>
  );
};

export default Achievements;
