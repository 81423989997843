import React, { useState } from 'react';
import {
  Heading,
  Container,
  Stack,
  Button,
  useDisclosure,
  useColorModeValue,
  Skeleton,
  useToast,
} from '@chakra-ui/react';
import { AddIcon } from '@chakra-ui/icons';
import { AnimatePresence } from 'framer-motion';
import { MotionBox, containerVariants } from '../config/config';

import useGetProjects from '../hooks/useGetProjects';
import useDownloadScript from '../hooks/useDownloadScript';
import useDeleteProject from '../hooks/useDeleteProject';
import useDeleteScript from '../hooks/useDeleteScript';
import useCreateProject from '../hooks/useCreateProject';

import ProjectCard from '../components/ProjectCard/ProjectCard';
import DeleteItemModal from '../components/DeleteItemModal/DeleteItemModal';
import CreateProjectModal from '../components/CreateProjectModal/CreateProjectModal';
import ExportScriptModal from '../components/ExportScriptModal/ExportScriptModal';

const Projects = () => {
  const bgColor = useColorModeValue('beige.100', 'gunmetal.700');
  const borderColor = useColorModeValue('beige.400', 'gunmetal.550');
  const buttonBgColor = useColorModeValue('beige.300', 'beige.300');
  const buttonHoverColor = useColorModeValue('beige.400', 'beige.400');
  const buttonTextColor = useColorModeValue('gray.800', 'white');
  const iconColor = useColorModeValue('gray.600', 'white');

  const { projects, isLoading: projectsLoading, refetch } = useGetProjects();
  const { createProject, isLoading: isCreatingProject } = useCreateProject();
  const { deleteProject, loading: isDeletingProject } = useDeleteProject();
  const deleteScript = useDeleteScript();
  const { newDownloadData } = useDownloadScript();

  const [itemToDelete, setItemToDelete] = useState(null);
  const [deleteItemType, setDeleteItemType] = useState('');
  const [selectedScriptId, setSelectedScriptId] = useState(null);
  const [selectedScriptTitle, setSelectedScriptTitle] = useState(null);

  const {
    isOpen: isDeleteOpen,
    onOpen: onDeleteOpen,
    onClose: onDeleteClose,
  } = useDisclosure();
  const {
    isOpen: isCreateOpen,
    onOpen: onCreateOpen,
    onClose: onCreateClose,
  } = useDisclosure();
  const {
    isOpen: isExportOpen,
    onOpen: onExportOpen,
    onClose: onExportClose,
  } = useDisclosure();

  const toast = useToast();

  const handleDeleteClick = (item, type) => {
    setItemToDelete(item);
    setDeleteItemType(type);
    onDeleteOpen();
  };

  const handleDeleteConfirm = async () => {
    if (itemToDelete) {
      try {
        if (deleteItemType === 'project') {
          await deleteProject(itemToDelete._id);
        } else if (deleteItemType === 'script') {
          await deleteScript(itemToDelete._id);
        }
        refetch();
        onDeleteClose();
        toast({
          title: `${deleteItemType.charAt(0).toUpperCase() + deleteItemType.slice(1)} Deleted`,
          description: `The ${deleteItemType} "${itemToDelete.title}" was deleted successfully.`,
          status: 'success',
          duration: 5000,
          isClosable: true,
        });
      } catch (error) {
        toast({
          title: 'Deletion Failed',
          description: `An error occurred while deleting the ${deleteItemType}. Please try again.`,
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      }
    }
  };

  const handleDeleteScriptAction = (script) => {
    setItemToDelete(script);
    setDeleteItemType('script');
    onDeleteOpen();
  };

  const handleAddProject = () => {
    onCreateOpen();
  };

  const handleCreateProject = async (projectName) => {
    await createProject(projectName);
    refetch();
    onCreateClose();
    // Scroll to the section of the new project
    const newProjectElement = document.getElementById(`project-${projectName}`);
    if (newProjectElement) {
      newProjectElement.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const handleDownloadScript = (scriptId, scriptTitle) => {
    setSelectedScriptId(scriptId);
    setSelectedScriptTitle(scriptTitle);
    onExportOpen();
  };

  const handleExport = async (type) => {
    if (selectedScriptId && selectedScriptTitle) {
      await newDownloadData(selectedScriptId, type, selectedScriptTitle);
    }
  };

  if (projectsLoading) {
    return (
      <Container maxW="container.xl" p={8}>
        <Stack spacing={8}>
          <Skeleton height="200px" />
        </Stack>
      </Container>
    );
  }

  return (
    <Container
      maxW="container.xl"
      p={8}
      bg={bgColor}
      borderRadius="lg"
      border="1px solid"
      borderColor={borderColor}
    >
      <Heading as="h1" mb={4}>
        Projects
      </Heading>
      <AnimatePresence>
        <MotionBox variants={containerVariants} initial="hidden" animate="show">
          <Stack spacing={6}>
            <Button
              leftIcon={<AddIcon />}
              onClick={handleAddProject}
              bg={buttonBgColor}
              color={buttonTextColor}
              transition="all 0.35s ease"
              _hover={{ bg: buttonHoverColor }}
            >
              Add Project
            </Button>

            {projects.map((project) => (
              <ProjectCard
                key={project._id}
                project={project}
                scripts={project.scripts || []}
                handleDeleteClick={handleDeleteClick}
                handleDownloadScript={handleDownloadScript}
                setSelectedScriptId={setSelectedScriptId}
                setSelectedScriptTitle={setSelectedScriptTitle}
                handleDeleteScript={handleDeleteScriptAction}
              />
            ))}
          </Stack>
        </MotionBox>
      </AnimatePresence>

      {/* Modals */}
      <DeleteItemModal
        isOpen={isDeleteOpen}
        onClose={onDeleteClose}
        itemName={itemToDelete?.title || ''}
        itemType={deleteItemType}
        onDelete={handleDeleteConfirm}
      />

      <CreateProjectModal
        isOpen={isCreateOpen}
        onClose={onCreateClose}
        onCreateProject={handleCreateProject}
        isLoading={isCreatingProject}
      />

      <ExportScriptModal
        isOpen={isExportOpen}
        onClose={onExportClose}
        onExport={handleExport}
        mediaType="Script"
      />
    </Container>
  );
};

export default Projects;
