import {
  Box,
  SimpleGrid,
  FormControl,
  FormLabel,
  Input,
  useColorModeValue,
} from '@chakra-ui/react';

const UserProfileDetails = ({ userState }) => {
  const bg = useColorModeValue('white', 'gunmetal.700');
  const borderColor = useColorModeValue('gunmetal.400', 'gunmetal.500');

  return (
    <SimpleGrid
      columns={{ base: '1', md: '2' }}
      spacing={10}
      mb={10}
      bg={bg}
      p={4}
      rounded="lg"
      shadow="lg"
      border="1px solid"
      borderColor={borderColor}
    >
      <Box>
        <FormControl py={2}>
          <FormLabel>User ID</FormLabel>
          <Input 
            placeholder={userState._id}
            readOnly
            key={userState._id}
            value={userState._id || ''}
            isDisabled
          />
        </FormControl>
        
        <FormControl py={2}>
          <FormLabel>Stripe Customer ID</FormLabel>
          <Input
            placeholder={userState.stripeCustomerId}
            readOnly
            value={userState.stripeCustomerId || ''}
            isDisabled
          />
        </FormControl>
      </Box>
      <Box>
      <FormControl py={2}>
          <FormLabel>Username</FormLabel>
          <Input
            key={userState.auth0Id}
            readOnly
            name="auth0Id"
            placeholder="Enter your username..."
            value={userState.auth0Id || ''}
            isDisabled
          />
        </FormControl>

        <FormControl py={2}>
          <FormLabel>Email Address</FormLabel>
          <Input
            value={userState.email || ''}
            name="email"
            placeholder="Enter your email..."
            readOnly
            isDisabled
          />
        </FormControl>
      </Box>
    </SimpleGrid>
  );
};

export default UserProfileDetails;
