import React from 'react';
import { Box, useBreakpointValue } from '@chakra-ui/react';
import { motion } from 'framer-motion';

const MotionBox = motion(Box);

const ProgressBar = ({ currentStep, progressValue, position }) => {
  const barHeight = useBreakpointValue({ base: '10px', md: '20px' });
  const barBg = useBreakpointValue({ base: 'gray.500', md: 'gray.700' });
  const progressBg = useBreakpointValue({ base: 'moonstone.300', md: 'moonstone.400' });

  return (
    <MotionBox
      height={barHeight}
      bg={barBg}
      w="100%"
      position={position}
      left={position === 'fixed' && 0}
      bottom={position === 'fixed' && 12}
    >
      <MotionBox
        height={barHeight}
        bg={progressBg}
        initial={{ width: '0%' }}
        animate={{ width: currentStep > 1 ? `${progressValue}%` : '0%' }}
        transition={{ duration: 0.8, ease: [0.25, 0.1, 0.25, 1] }}
        sx={
          currentStep >= 4
            ? { borderRadius: 'none' }
            : {
                borderTopRightRadius: 'xl',
                borderBottomRightRadius: 'xl',
              }
        }
      />
    </MotionBox>
  );
};

export default ProgressBar;
