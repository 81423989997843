import React, { useEffect, useState } from 'react';
import { Box, HStack } from '@chakra-ui/react';
import { motion, AnimatePresence } from 'framer-motion';

const MotionBox = motion(Box);

const digitVariants = {
  initial: ([direction, index]) => ({
    y: direction === 'up' ? '100%' : '-100%',
    opacity: 0,
    position: 'absolute',
    transition: {
      delay: index * 0.1,
    },
  }),
  animate: ([, index]) => ({
    y: '0%',
    opacity: 1,
    position: 'relative',
    transition: {
      delay: index * 0.1,
      type: 'spring',
      stiffness: 300,
      damping: 20,
    },
  }),
  exit: ([direction, index]) => ({
    y: direction === 'up' ? '-100%' : '100%',
    opacity: 0,
    position: 'absolute',
    transition: {
      delay: index * 0.1,
      type: 'spring',
      stiffness: 300,
      damping: 20,
    },
  }),
};

const AnimatedDigit = ({ digit, size, index, direction }) => (
  <MotionBox
    custom={[direction, index]}
    variants={digitVariants}
    initial="initial"
    animate="animate"
    exit="exit"
    position="relative"
    width="1ch"
    height="2rem"
    overflow="hidden"
    display="flex"
    alignItems="center"
    justifyContent="center"
    fontSize={size === 'md' ? '1em' : size}
    lineHeight="1.2em"
  >
    {digit}
  </MotionBox>
);

const Odometer = ({ value, size = 'md' }) => {
  const digits = String(value).split('');

  const [displayDigits, setDisplayDigits] = useState(digits.map(() => '0'));
  const [direction, setDirection] = useState('down'); // 'down' for initial animation

  useEffect(() => {
    // Animate zeros out and actual digits in after a delay
    const timeout = setTimeout(() => {
      setDisplayDigits(digits);
      setDirection('up'); // Change direction for exit animation
    }, 500); // Adjust delay as needed

    return () => clearTimeout(timeout);
  }, [digits, value]);

  return (
    <HStack
      spacing={'0.1em'}
      overflow="hidden"
      height="2rem"
      position="relative"
      px={1}
    >
      {displayDigits.map((digit, index) => (
        <Box key={index} position="relative" width="1ch" height="2rem">
          <AnimatePresence custom={[direction, index]} initial={false}>
            <AnimatedDigit
              key={`${digit}-${index}`}
              digit={digit}
              size={size}
              index={index}
              direction={direction}
            />
          </AnimatePresence>
        </Box>
      ))}
    </HStack>
  );
};

export default Odometer;
