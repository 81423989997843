import React, { useEffect, useState, useRef } from 'react';
import {
  Box,
  Container,
  Divider,
  VStack,
  HStack,
  useColorModeValue,
  Icon,
  IconButton,
  Text,
  Image,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Heading,
  Button,
  SimpleGrid,
  Flex,
  Spinner,
  useDisclosure,
  Tooltip,
} from '@chakra-ui/react';
import {
  FaBuildingUser,
  FaBagShopping,
  FaIndustry,
  FaCalendar,
  FaClock,
  FaFileExport,
  FaVideo,
  FaMicrophone,
  FaMusic,
  FaPepperHot,
  FaPenToSquare,
  FaChevronLeft,
  FaImage,  
  FaTrash,
  FaClone
} from 'react-icons/fa6';
import EditableControlsInput from '../components/EditableControls/EditableControlsInput';
import useGetProjectById from '../hooks/useGetProjectById';
import useUpdateProject from '../hooks/useUpdateProject';
import defaultProjectHeader from '../assets/default-project-header.png';
import ExportScriptModal from '../components/ExportScriptModal/ExportScriptModal';
import UploadMediaModal from '../components/UploadMediaModal/UploadMediaModal';
import useUpload from '../hooks/useUpload';
import useDownloadScript from '../hooks/useDownloadScript';
import useDeleteScript from '../hooks/useDeleteScript';
import { useParams, useNavigate } from 'react-router-dom';
import { formatDateFromUTC } from '../utils/app.utils';
import AudioPlayer, { RHAP_UI } from 'react-h5-audio-player';
import { Global } from '@emotion/react';
import 'react-h5-audio-player/lib/styles.css'; 

const Project = () => {
  const cardBg = useColorModeValue('white', 'gunmetal.550');
  const cardHoverBg = useColorModeValue('gunmetal.400', 'gunmetal.500');
  const cardBorderColor = useColorModeValue('gray.200', 'gunmetal.400');
  const innerCardBg = useColorModeValue('beige.100', 'gunmetal.600');
  const textColor = useColorModeValue('gray.700', 'white');
  const iconBgColor = useColorModeValue('gray.100', 'gunmetal.500');
  const iconHoverBgColor = useColorModeValue('gray.200', 'gunmetal.550');
  const iconColor = useColorModeValue('gray.500', 'beige.200');
  const cardTextColor = useColorModeValue('gray.700', 'white');
  const cardHoverTextColor = useColorModeValue('white', 'white');
  const bgColor = useColorModeValue('beige.100', 'gunmetal.700');
  const borderColor = useColorModeValue('beige.400', 'gunmetal.550');
  const playerBgColor = useColorModeValue('gray.300', '#232C33');
  const controlColor = useColorModeValue('gray.800', 'white');
  const controlHoverColor = useColorModeValue('gray.600', 'gray.300');
  const progressBarBg = useColorModeValue('gray.300', 'gray.600');
  const progressFilledBg = useColorModeValue('blue.500', 'blue.300');
  const boxBorderColor = useColorModeValue('gray.200', '#2F4957');

  const navigate = useNavigate();
  const currentAudioRef = useRef(null);
  const { projectId } = useParams();
  const { project, loading, refetch } = useGetProjectById(projectId);
  const { updateProjectData } = useUpdateProject();
  const [projectTitle, setProjectTitle] = useState('');
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [imageUrl, setImageUrl] = useState('');
  const [selectedScriptId, setSelectedScriptId] = useState('');
  const [selectedScriptTitle, setSelectedScriptTitle] = useState('');
  const deleteScript = useDeleteScript();

  const { handleUpload, loading: uploadLoading, progress } = useUpload(projectId, updateProjectData);
  const { newDownloadData } = useDownloadScript();
  const {
    isOpen: isExportOpen,
    onOpen: onExportOpen,
    onClose: onExportClose,
  } = useDisclosure();

  useEffect(() => {
    if (project) {
      if (project.title) {
        setProjectTitle(project.title);
      }
      if (project.imageUrl) {
        setImageUrl(project.imageUrl);
      }

    }
  }, [project]);

  const handleProjectTitleChange = async (value) => {
    try {
      const projectData = {
        _id: projectId,
        title: value,
      };
      await updateProjectData(projectData);
      setProjectTitle(value);
    } catch (error) {
      console.error('Failed to update project title:', error);
    }
  };

  const handleEdit = (ad) => {
    navigate(`/script/${ad._id}/edit`);
  };

  const handleBack = () => {
    navigate(-1);
  };

  const handleDownloadScript = (scriptId, scriptTitle) => {
    setSelectedScriptId(scriptId);
    setSelectedScriptTitle(scriptTitle);
    onExportOpen();
  };

  const handleExport = async (type) => {
    if (selectedScriptId && selectedScriptTitle) {
      await newDownloadData(selectedScriptId, type, selectedScriptTitle);
    }
  };

  const handleFileUpload = async (file, setCompleted) => {
    const uploadedImageUrl = await handleUpload(file);
    
    const projectData = {
      _id: projectId,
      imageUrl: uploadedImageUrl,
    };

    await updateProjectData(projectData);

    setImageUrl(uploadedImageUrl);
    
    setCompleted(true);
    onClose();
    return uploadedImageUrl;
  };

  const handleDeleteScript = async (scriptId) => {
    await deleteScript(scriptId);
    refetch();
  };

  if (loading || !project) {
    return (
      <Flex justify="center" align="center" h="100vh">
        <Spinner />
      </Flex>
    );
  }

  return (
    <>
    <Global
        styles={`
          .rhap_container {
            background-color: ${playerBgColor} !important;
            border-radius: 8px;
            border: 1px solid ${boxBorderColor};
            color: ${controlColor};
          }
          .rhap_additional-controls {
            display: none !important;
          }
          .rhap_volume-controls {
            padding-right: 10px !important;
          }
          .rhap_button-clear {
            color: ${controlColor} !important;
          }
          .rhap_time {
            color: ${controlColor} !important;
          }
          .rhap_button-clear:hover {
            color: ${controlHoverColor} !important;
          }
          .rhap_progress-bar {
            background: ${progressBarBg} !important;
          }
          .rhap_progress-filled {
            background: ${progressFilledBg} !important;
          }
        `}
      />
    <Container
      maxW="container.xl"
      p={8}
      bg={bgColor}
      borderRadius="lg"
      border="1px solid"
      borderColor={borderColor}
    >
      <VStack align="start" spacing={8}>
        {/* Back Button Section */}
        <HStack w="full" justify={'flex-start'} gap={4} align={'center'}>
          {/* Back Button */}
          <IconButton
            aria-label="Back"
            icon={<FaChevronLeft />}
            onClick={handleBack}
            bg={iconBgColor}
            size={'sm'}
            _hover={{ bg: iconHoverBgColor }}
          />

          <Text fontSize={'sm'} color={textColor}>
            Back to Projects
          </Text>
        </HStack>
       
        <VStack
          w="full"
          align={'stretch'}
          spacing={0}
          p={4}
          borderWidth={'1px solid'}
          borderColor={borderColor}
          bg={innerCardBg}
          borderRadius={'lg'}
        >
          {/* Header Section */}
          <Box
            w="full"
            h={{ base: '200px', md: '300px' }}
            position="relative"
            bgImage={`url(${imageUrl || defaultProjectHeader})`}
            bgPosition="center"
            bgRepeat="no-repeat"
            bgSize="cover"
            borderRadius="lg"
            overflow="hidden"
            mb={4}
            zIndex={2}
          >
            {/* Overlay */}
            <Box
              position="absolute"
              top={0}
              left={0}
              right={0}
              bottom={0}
              zIndex={2}
              bg="rgba(0, 0, 0, 0.7)" 
            />

            <IconButton
              aria-label="Edit Project Header Image"
              icon={<FaImage />}
              size={'sm'}
              position="absolute"
              top={2}
              right={2}
              zIndex={2}
              bg={iconBgColor}
              _hover={{ bg: iconHoverBgColor }}
              onClick={onOpen}
            />

            <VStack
              position="absolute"
              top="50%"
              left="50%"
              transform="translate(-50%, -50%)"
              spacing={2}
              align="center"
              color="white"
              zIndex={2}
            >
              <EditableControlsInput
                inputValue={projectTitle}
                setInputValue={setProjectTitle}
                handleSubmit={handleProjectTitleChange}
              />
              <HStack spacing={2} align={'center'}>
                <Icon as={FaCalendar} w={4} h={4} color="white" />
                <Text fontSize="sm" color="white">
                  Date Created: {formatDateFromUTC(project.createdAt)}
                </Text>
              </HStack>
            </VStack>
          </Box>

          <Accordion allowToggle w="full">
            {project?.scripts?.map((ad) => (
              <AccordionItem key={ad._id} border="none">
                <AccordionButton
                  _expanded={{ bg: cardHoverBg, color: cardHoverTextColor }}
                  bg={cardBg}
                  border="1px"
                  borderColor={cardBorderColor}
                  color={cardTextColor}
                  rounded="md"
                  mb={2}
                  transition="all 0.2s ease-in-out"
                  p={4}
                  _hover={{
                    bg: cardHoverBg,
                    color: cardHoverTextColor,
                  }}
                >
                  <Box flex="1" textAlign="left" fontWeight="bold">
                    {ad.title}
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
                <AccordionPanel
                  pb={4}
                  bg={cardBg}
                  border="1px"
                  borderColor={cardBorderColor}
                  rounded="md"
                >
                  <SimpleGrid columns={{ base: 1, md: 2 }} spacing={6} p={4}>
                    <VStack align="start" spacing={4}>
                    <HStack>
                      <Tooltip label="Download Script" aria-label="Download Script tooltip" placement="top">
                        <IconButton
                          icon={<FaFileExport />}
                          aria-label="Download Script"
                          onClick={() => handleDownloadScript(ad._id, ad.title)}
                        />
                      </Tooltip>

                      <Tooltip label="Delete Script" aria-label="Delete Script tooltip" placement="top">
                        <IconButton
                          icon={<FaTrash />}
                          aria-label="Delete Script"
                          onClick={() => handleDeleteScript(ad._id)}
                        />
                      </Tooltip>

                      <Tooltip label="Clone Script" aria-label="Clone Script tooltip" placement="top">
                        <IconButton
                          icon={<FaClone />}
                          aria-label="Clone Script"
                          // onClick={() => handleCloneScript(ad._id)}
                        />
                      </Tooltip>
                    </HStack>
                      <HStack>
                        <Icon as={FaCalendar} w={4} h={4} color={iconColor} />
                        <Text fontSize="md" fontWeight={'bold'}>
                          Created:
                        </Text>
                        <Text fontSize="md">
                          {formatDateFromUTC(ad.createdAt)}
                        </Text>
                      </HStack>
                      <HStack>
                        <Icon as={FaClock} w={4} h={4} color={iconColor} />
                        <Text fontSize="md" fontWeight={'bold'}>
                          Duration:
                        </Text>
                        <Text fontSize="md">{ad.duration}</Text>
                      </HStack>

                      <HStack>
                        <Icon as={FaVideo} w={4} h={4} color={iconColor} />
                        <Text fontSize="md" fontWeight={'bold'}>
                          Type:
                        </Text>
                        <Text fontSize="md">{ad.adType}</Text>
                      </HStack>

                      <Divider />

                      {ad.brandName && (
                        <HStack>
                          <Icon
                            as={FaBuildingUser}
                            w={4}
                            h={4}
                            color={iconColor}
                          />
                          <Text fontSize="md" fontWeight={'bold'}>
                            Brand Name:
                          </Text>
                          <Text fontSize="md">{ad.brandName}</Text>
                        </HStack>
                      )}

                      {ad.productName && (
                        <HStack>
                          <Icon
                            as={FaBagShopping}
                            w={4}
                            h={4}
                            color={iconColor}
                          />
                          <Text fontSize="md" fontWeight={'bold'}>
                            Product Name:
                          </Text>
                          <Text fontSize="md">{ad.productName}</Text>
                        </HStack>
                      )}

                      {ad.callToAction && (
                        <HStack>
                          <Icon
                            as={FaPepperHot}
                            w={4}
                            h={4}
                            color={iconColor}
                          />
                          <Text fontSize="md" fontWeight={'bold'}>
                            Call to Action:
                          </Text>
                          <Text fontSize="md">{ad.callToAction}</Text>
                        </HStack>
                      )}

                      <HStack>
                        <Icon as={FaIndustry} w={4} h={4} color={iconColor} />
                        <Text fontSize="md" fontWeight={'bold'}>
                          Industry:
                        </Text>
                        <Text fontSize="md">{ad.industry}</Text>
                      </HStack>


                      <Button
                        onClick={() => handleEdit(ad)}
                        leftIcon={<Icon as={FaPenToSquare} w={4} h={4} />}
                        borderRadius={'md'}
                        variant="solid"
                      >
                        Edit
                      </Button>
                    </VStack>

                    <VStack align="start" spacing={8}>
                      
                      {ad.voiceover && (
                      <Box w="full">
                        <Heading
                          as="h4"
                          size="sm"
                          mb={2}
                          display="flex"
                          alignItems="center"
                        >
                          <Icon
                            as={FaMicrophone}
                            w={4}
                            h={4}
                            mr={2}
                            color={iconColor}
                          />
                          Voiceover
                        </Heading>
                        <AudioPlayer
                          src={ad.voiceover.audioPath}
                          autoPlayAfterSrcChange={false}
                          showJumpControls={false}
                          customAdditionalControls={[...RHAP_UI.ADDITIONAL_CONTROLS]}
                          layout="horizontal-reverse"
                          onPlay={(e) => {
                            if (
                              currentAudioRef.current &&
                              currentAudioRef.current !== e.target
                            ) {
                              currentAudioRef.current.pause();
                            }
                            currentAudioRef.current = e.target;
                          }}
                        />
                        
                      </Box>
                      )}

                      {ad.music && (
                      <Box w="full">
                        <Heading
                          as="h4"
                          size="sm"
                          mb={2}
                          display="flex"
                          alignItems="center"
                        >
                          <Icon
                            as={FaMusic}
                            w={4}
                            h={4}
                            mr={2}
                            color={iconColor}
                          />
                          Background Music
                        </Heading>
                        
                        <AudioPlayer
                          src={ad.music.url}
                          autoPlayAfterSrcChange={false}
                          showJumpControls={false}
                          customAdditionalControls={[...RHAP_UI.ADDITIONAL_CONTROLS]}
                          layout="horizontal-reverse"
                          onPlay={(e) => {
                            if (
                              currentAudioRef.current &&
                              currentAudioRef.current !== e.target
                            ) {
                              currentAudioRef.current.pause();
                            }
                            currentAudioRef.current = e.target;
                          }}
                        />
                      </Box>
                      )}

                      {ad.video && (
                      <Box w="full">
                        <Heading
                          as="h4"
                          size="sm"
                          mb={2}
                          display="flex"
                          alignItems="center"
                        >
                          <Icon
                            as={FaVideo}
                            w={4}
                            h={4}
                            mr={2}
                            color={iconColor}
                          />
                          Video Preview
                        </Heading>
                        <video
                          src={ad.video.url}
                          controls
                          style={{ width: '100%' }}
                        />
                      </Box>
                      )}
                    </VStack>
                  </SimpleGrid>
                </AccordionPanel>
              </AccordionItem>
            ))}
          </Accordion>
        </VStack>

        {/* Upload Media Modal */}
        <UploadMediaModal
          isOpen={isOpen}
          onClose={onClose}
          onFileUpload={handleFileUpload}
          loading={uploadLoading}
          progress={progress}
        />

        <ExportScriptModal
          isOpen={isExportOpen}
          onClose={onExportClose}
          onExport={handleExport}
          mediaType="Script"
        />
      </VStack>
    </Container>
    </>
  );
};

export default Project;
