import { tagAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(tagAnatomy.keys);

const baseStyle = definePartsStyle({
  container: {
    borderRadius: 'full',
    px: 4,
    py: 2,
  },
  label: {
    fontWeight: 'bold',
  },
  closeButton: {
    bg: 'transparent',
    borderRadius: 'full',
    _hover: {
      bg: 'gray.400',
    },
  },
});

const variants = {
  solid: props => ({
    container: {
      bg: 'gunmetal.450',
      color: 'white',
    },
  }),
  outline: props => ({
    container: {
      borderColor: 'beige.300',
      borderWidth: '1px',
      bg: 'transparent',
      color: 'white',
      _hover: {
        bg: 'beige.300',
        color: 'white',
      },
    },
  }),
};

const tagTheme = defineMultiStyleConfig({ baseStyle, variants });

export default tagTheme;
