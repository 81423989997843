import React from 'react';
import { Flex, Text, VStack, Image, Button, Box } from '@chakra-ui/react';

const TriviaCategories = ({ categories, onSelect }) => {
  return (
    <Flex wrap="wrap" justifyContent="center" gap={6} p={4}>
      {categories.map((category) => (
        <Flex
          key={category._id}
          borderWidth="1px"
          borderRadius="lg"
          overflow="hidden"
          p={4}
          bg="gunmetal.600"
          color="white"
          _hover={{ bg: 'blackAlpha.400' }}
          cursor="pointer"
          onClick={() => onSelect(category._id)}
          w="100%"
          maxW="300px"
          flexDirection="column"
          alignItems="center"
          m={2}
        >
          <VStack spacing={4} w="100%" flex="1" align="center">
            <Image src={category.imageUrl} alt={category.name} boxSize="100px" />
            <Text fontSize="xl" fontWeight="bold">{category.name}</Text>
            <Text textAlign="center">{category.description}</Text>
            <Box flex="1" />
            <Button w="full">Select</Button>
          </VStack>
        </Flex>
      ))}
    </Flex>
  );
};

export default TriviaCategories;
