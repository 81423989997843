import React, { useEffect, useState } from 'react';
import {
  Box,
  Flex,
  Text,
  SimpleGrid,
  Spinner,
  Alert,
  AlertIcon,
  Divider,
  Heading,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
  Button,
  VStack,
  HStack,
  Icon,
  useToast,
  useDisclosure,
} from '@chakra-ui/react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useUserContext } from '../../providers/UserProvider';
import { PiCreditCardFill } from 'react-icons/pi';
import PurchaseModal from '../PurchaseModal/PurchaseModal';
import useStripeCheckout from '../../hooks/useStripeCheckout';
import useGetProducts from '../../hooks/useGetProducts';

const PurchaseCredits = () => {
  const { handleStripeCheckout, handleStripeSession, loading: sessionLoading } = useStripeCheckout();
  const { products, loadingProducts, errorProducts } = useGetProducts();
  const { user, refreshUserData } = useUserContext();
  const location = useLocation();
  const navigate = useNavigate();
  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [selectedProducts, setSelectedProducts] = useState([]);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [showCancelModal, setShowCancelModal] = useState(false);
  const [creditsPurchased, setCreditsPurchased] = useState(0);
  const [xpGained, setXpGained] = useState(0);

  // Handle quantity change
  const handleQuantityChange = (productId, value) => {
    const quantity = parseInt(value) || 0;
    setSelectedProducts((prev) => {
      const updatedProducts = prev.filter((item) => item.productId !== productId);
      if (quantity > 0) {
        updatedProducts.push({ productId, quantity });
      }
      return updatedProducts;
    });
  };

  // Calculate grand total price
  const displayGrandTotalPrice = () => {
    return selectedProducts.reduce((total, item) => {
      const product = products.find((p) => p._id === item.productId);
      const priceObj = product?.prices[0];
      const price = priceObj?.cost || 0;
      return total + price * item.quantity;
    }, 0);
  };

  // Handle purchase
  const handlePurchase = async () => {
    const returnUrl = `${window.location.origin}${location.pathname}`;

    const url = await handleStripeCheckout({
      products: selectedProducts,
      returnUrl,
    });

    if (url) {
      window.location.href = url;
    } else {
      toast({
        title: 'Error initiating checkout',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  // Handle success and cancel redirections
  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const sessionId = params.get('session_id');

    if (sessionId) {
      // Retrieve the session from Stripe
      const fetchSessionData = async () => {
        const sessionData = await handleStripeSession(sessionId);

        if (
          sessionData &&
          sessionData.session &&
          sessionData.session.payment_status === 'paid'
        ) {
          // Payment was successful
          setShowSuccessModal(true);
          setCreditsPurchased(sessionData.totalCreditsPurchased);
          setXpGained(sessionData.totalXpGained);

          // Fetch user data after updating credits
          try {
            await refreshUserData();
          } catch (error) {
            console.error('Error fetching user data:', error);
          }
        } else {
          // Payment was canceled or failed
          setShowCancelModal(true);
        }

        // Remove the session_id parameter from the URL
        navigate(location.pathname, { replace: true });
      };

      fetchSessionData();
    }
  }, [handleStripeSession, refreshUserData, location.pathname, navigate]);

  if (loadingProducts) {
    return (
      <Flex justify="center" align="center" minH="200px">
        <Spinner size="xl" />
      </Flex>
    );
  }

  if (errorProducts) {
    return (
      <Alert status="error">
        <AlertIcon />
        There was an error fetching the products.
      </Alert>
    );
  }

  return (
    <Box p={8}>
      <SimpleGrid columns={{ base: 1, sm: 2, md: 3 }} spacing={5}>
        {products.map((product) => {
          // Determine the correct price based on user's subscription status
          let priceObj;

          if (user && user.subscriptionTier === 'Professional') {
            // Look for a discounted price
            priceObj = product.prices.find(
              (price) =>
                price.metadata && price.metadata.discount === 'professional'
            );
          }

          // If not found or user is not professional, use the standard price
          if (!priceObj) {
            priceObj = product.prices.find(
              (price) => !price.metadata || !price.metadata.discount
            );
          }

          const price = priceObj ? `$${priceObj.cost.toFixed(2)}` : 'N/A';

          // Get selected quantity
          const selectedProduct = selectedProducts.find(
            (p) => p.productId === product._id
          );
          const quantity = selectedProduct?.quantity || 0;

          return (
            <Flex
              key={product._id}
              direction="column"
              alignItems="center"
              justifyContent="center"
              p={5}
              gap={2}
              bg="gunmetal.550"
              rounded="md"
              boxShadow="base"
              _hover={{ bg: 'gunmetal.450', borderColor: 'gunmetal.400' }}
              color="white"
              border="1px solid"
              borderColor="gunmetal.400"
              transition="all 0.2s ease-in-out"
            >
              <Heading
                as="h2"
                fontSize={{ base: 'md', md: '4xl' }}
                fontWeight="bold"
                textAlign="center"
              >
                {price}
              </Heading>
              <Divider />

              <Heading
                as="h3"
                fontSize={{ base: 'md', md: 'xl' }}
                fontWeight="bold"
                textAlign="center"
              >
                {product.name}
              </Heading>
              <Text fontSize={{ base: 'sm', md: 'md' }} textAlign="center" mb={2}>
                {product.description}
              </Text>

              <NumberInput
                min={0}
                max={100}
                step={1}
                value={quantity}
                onChange={(valueString) =>
                  handleQuantityChange(product._id, valueString)
                }
              >
                <NumberInputField />
                <NumberInputStepper>
                  <NumberIncrementStepper />
                  <NumberDecrementStepper />
                </NumberInputStepper>
              </NumberInput>
            </Flex>
          );
        })}
      </SimpleGrid>

      <VStack mt={8} spacing={4} align="stretch">
        <HStack justify="space-between" px={4}>
          <Text fontSize="lg" fontWeight="bold" color="white">
            Grand Total:
          </Text>
          <Text fontSize="lg" fontWeight="bold" color="white">
            ${displayGrandTotalPrice().toFixed(2)}
          </Text>
        </HStack>

        <Button
          w="100%"
          bg={'gunmetal.550'}
          color={'white'}
          _hover={{
            bg: 'gunmetal.500',
          }}
          onClick={handlePurchase}
          isDisabled={selectedProducts.length === 0}
        >
          <Icon as={PiCreditCardFill} mr={2} />
          <Text>Purchase</Text>
        </Button>
      </VStack>

      {/* Success Modal */}
      <PurchaseModal
        isOpen={showSuccessModal}
        onClose={() => setShowSuccessModal(false)}
        title="Purchase Successful"
        message="Your purchase was successful!"
        credits={creditsPurchased}
        xp={xpGained}
        loading={sessionLoading}
      />

      {/* Cancellation Modal */}
      <PurchaseModal
        isOpen={showCancelModal}
        onClose={() => setShowCancelModal(false)}
        title="Purchase Canceled"
        message="Your purchase was canceled. Please try again if you wish to buy credits."
      />
    </Box>
  );
};

export default PurchaseCredits;
