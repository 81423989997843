import { useState } from 'react';
import AnimatedIconWithNumber from '../components/AnimatedNumberWithIcon/AnimatedNumberWithIcon';

const useAnimatedFeedback = () => {
  const [feedbacks, setFeedbacks] = useState([]);

  const showFeedback = (iconType, amount, options = {}) => {
    const id = Date.now() + Math.random(); // Unique identifier
    const newFeedback = {
      id,
      icon: iconType,
      number: amount,
      isPositive: amount > 0,
      position: options.position || 'fixed',
      size: options.size || 'md',
      mainColor: options.mainColor || 'yellow.400',
      sparklesColor: options.sparklesColor || '#F6E05E',
      bottom: options.bottom,
      right: options.right,
      left: options.left,
      top: options.top,
    };

    setFeedbacks(prevFeedbacks => [...prevFeedbacks, newFeedback]);
  };

  return {
    showFeedback,
    FeedbackComponent: () => (
      <>
        {feedbacks.map(feedback => (
          <AnimatedIconWithNumber
            key={feedback.id}
            icon={feedback.isPositive ? 'xp' : 'credit'}
            number={Math.abs(feedback.number)}
            onAnimationComplete={() => {
              setFeedbacks(prevFeedbacks =>
                prevFeedbacks.filter(fb => fb.id !== feedback.id)
              );
            }}
            position={feedback.position}
            size={feedback.size}
            mainColor={feedback.mainColor}
            sparklesColor={feedback.sparklesColor}
            bottom={feedback.bottom}
            right={feedback.right}
            left={feedback.left}
            top={feedback.top}
          />
        ))}
      </>
    ),
  };
};

export default useAnimatedFeedback;
