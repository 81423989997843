import React from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  HStack,
  Text,
  Flex,
  useColorModeValue,
  Button,
} from '@chakra-ui/react';
import { FaMusic } from 'react-icons/fa';
import { useUserContext } from '../../providers/UserProvider';

const ExportAdModal = ({ isOpen, onClose, onExport, mediaType }) => {
  const bgColor = useColorModeValue('beige.100', '#232C33');
  const headerBgColor = useColorModeValue('beige.200', '#314048');
  const borderColor = useColorModeValue('gray.200', '#3E4E57');
  const textColor = useColorModeValue('gray.800', 'white');
  const buttonBgColor = useColorModeValue('gray.50', '#1C242A');
  const buttonBorderColor = useColorModeValue('gray.200', '#3E4E57');
  const buttonHoverBgColor = useColorModeValue('gray.100', '#314048');
  const buttonHoverBorderColor = useColorModeValue('gray.300', 'beige.400');
  const iconColor = useColorModeValue('yellow.500', 'yellow.400');

  const { subscription } = useUserContext();
  const planName = subscription?.subscriptionPlan?.name || 'Free';

  const exportOptions = [
    { format: 'mp3', label: 'Export to MP3', disabled: false },
    {
      format: 'wav',
      label: 'Export to WAV',
      disabled: !['Standard', 'Professional'].includes(planName),
    },
  ];

  const ExportButton = ({ format, label, disabled }) => (
    <Flex
      direction="column"
      alignItems="center"
      justifyContent="center"
      bg={buttonBgColor}
      _hover={!disabled && { bg: buttonHoverBgColor }}
      border="1px solid"
      borderColor={borderColor}
      borderRadius="md"
      p={4}
      cursor={disabled ? 'not-allowed' : 'pointer'}
      opacity={disabled ? 0.6 : 1}
      onClick={() => !disabled && onExport(format)}
    >
      <FaMusic size="24px" />
      <Text mt={2}>{label}</Text>
    </Flex>
  );

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="3xl" isCentered>
      <ModalOverlay />
      <ModalContent
        borderRadius={10}
        bg={bgColor}
        color={textColor}
        overflow="hidden"
        borderColor={borderColor}
        borderWidth="1px"
      >
        <ModalHeader
          bg={headerBgColor}
          borderBottomWidth={1}
          borderColor={borderColor}
        >
          Export {mediaType}
        </ModalHeader>
        <ModalCloseButton color={textColor} />
        <ModalBody>
          <HStack spacing={4} justifyContent="center" align="stretch" p={4} w="100%">
            {exportOptions.map(({ format, label, disabled }) => (
              <ExportButton
                key={format}
                format={format}
                label={label}
                disabled={disabled}
              />
            ))}
          </HStack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default ExportAdModal;