import React from 'react';
import LoginForm from '../components/LoginForm/LoginForm';
import LoginImage from '../assets/login.jpg';
import { Box, Flex, Image } from '@chakra-ui/react';
import { useUserContext } from '../providers/UserProvider';

const Login = () => {

  const { login } = useUserContext();

  return (
    <Flex
      direction={{ base: 'column', md: 'row' }}
      justify="stretch"
      align="center"
      h="100vh"
    >
      <Box width="450px" height="100vh">
        <Image
          src={LoginImage}
          alt="Side panel"
          objectFit="cover"
          width="100%"
          height="100%"
        />
      </Box>
      <Box flex="1">
        <LoginForm login={login} />
      </Box>
    </Flex>
  );
};

export default Login;
