import { useState, useEffect } from 'react';
import {
  getUserNotifications,
  toggleNotificationReadStatus,
} from '../services/notification.service';
import { useUserContext } from '../providers/UserProvider';

const useNotification = userId => {
  const { token } = useUserContext();
  const [notifications, setNotifications] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchNotifications = async () => {
      setIsLoading(true);
      try {
        const response = await getUserNotifications({ token, userId });
        setNotifications(response);
        setError(null);
      } catch (err) {
        setError(err.message);
      } finally {
        setIsLoading(false);
      }
    };

    fetchNotifications();
  }, [token, userId]);

  const toggleReadStatus = async notificationId => {
    try {
      const updatedNotification = await toggleNotificationReadStatus({
        token,
        notificationId,
      });
      setNotifications(prev =>
        prev.map(notif =>
          notif._id === notificationId
            ? { ...notif, read: updatedNotification.read }
            : notif
        )
      );
    } catch (err) {
      setError(err.message);
    }
  };

  return {
    notifications,
    toggleReadStatus,
    isLoading,
    error,
  };
};

export default useNotification;
