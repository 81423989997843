import React, { useState, useEffect, useCallback } from 'react';
import {
  Box,
  Input,
  Button,
  VStack,
  Text,
  HStack,
  Divider,
  Heading,
  Tooltip,
  Icon,
  Stack,
  ButtonGroup,
  FormControl,
  FormHelperText,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  ModalBody,
  useToast,
  useColorModeValue,
  useDisclosure,
  Flex,
} from '@chakra-ui/react';
import {
  AutoComplete,
  AutoCompleteInput,
  AutoCompleteItem,
  AutoCompleteList,
} from '@choc-ui/chakra-autocomplete';
import { QuestionOutlineIcon } from '@chakra-ui/icons';
import { FaArrowRight } from 'react-icons/fa';
import { useAdGeneration } from '../../providers/AdGenerationProvider';
import { useNavigate } from 'react-router-dom';
import TargetAudience from '../TargetAudience/TargetAudience';
import SegmentedProgressBar from '../SegmentedProgressBar/SegmentedProgressBar';
import ScriptIdeas from '../ScriptIdeas/ScriptIdeas';
import DOMPurify from 'dompurify';
import useAnimatedFeedback from '../../hooks/useAnimatedFeedback';
import useScriptTopicSuggestions from '../../hooks/useScriptTopicSuggestions';
import useGetIndustries from '../../hooks/useGetIndustries';

const ScriptDetails = () => {
  const bgColor = useColorModeValue('beige.200', 'gunmetal.700');
  const textColor = useColorModeValue('gray.800', 'white');
  const iconColor = useColorModeValue('gray.500', 'gunmetal.400');
  const inputBgColor = useColorModeValue('beige.100', 'whiteAlpha.100');
  const inputBorderColor = useColorModeValue('gray.300', 'gray.600');
  const buttonBgColor = useColorModeValue('gradBeige', 'gradBlack');
  const buttonHoverBgColor = useColorModeValue('gradBeigeHover', 'gradBlackHover');
  const button2BgColor = useColorModeValue('gradGunmetal', 'gradGunmetal');
  const button2HoverBgColor = useColorModeValue('gradGunmetalHover', 'gradGunmetalHover');
  const buttonTextColor = useColorModeValue('white', 'white');
  const modalBgColor = useColorModeValue('beige.100', 'gunmetal.700');

  const navigate = useNavigate();
  const toast = useToast();
  const { onOpen } = useDisclosure();

  const { getSuggestions, loading: loadingSuggestions } = useScriptTopicSuggestions();
  const { showFeedback, FeedbackComponent } = useAnimatedFeedback();
  const { industries, loading: loadingIndustries } = useGetIndustries();
  const {
    projectDetails,
    scriptDetails,
    setScriptDetails,
    adType,
    totalSteps,
    setScriptIdea,
    scriptIdea,
  } = useAdGeneration();

  const [suggestions, setSuggestions] = useState([]);
  const [showIdeas, setShowIdeas] = useState(false);
  const [localScriptDetails, setLocalScriptDetails] = useState({
    ...scriptDetails,
    targetAudience: scriptDetails.targetAudience || []
  });

  const updateLocalScriptDetails = useCallback((key, value) => {
    setLocalScriptDetails(prevDetails => ({
      ...prevDetails,
      [key]: DOMPurify.sanitize(value),
    }));
  }, []);

  const updateContextScriptDetails = useCallback(() => {
    setScriptDetails(prevDetails => ({
      ...prevDetails,
      ...localScriptDetails,
    }));
  }, [localScriptDetails, setScriptDetails]);

  const handleSetSelectedAudience = useCallback((newSelectedAudience) => {
    setLocalScriptDetails(prevDetails => ({
      ...prevDetails,
      targetAudience: newSelectedAudience
    }));
  }, []);

  useEffect(() => {
    setLocalScriptDetails(prevLocal => ({
      ...prevLocal,
      ...scriptDetails,
      targetAudience: scriptDetails.targetAudience || prevLocal.targetAudience
    }));
  }, [scriptDetails]);

  const fetchSuggestions = async (isRegenerate = false) => {
    if (!adType) {
      toast({
        title: 'Error',
        description: 'Please go back and select the type of ad on Step 1.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    if (!localScriptDetails.brandName || !localScriptDetails.industry || !localScriptDetails.duration) {
      toast({
        title: 'Error',
        description: 'Please fill out all required fields.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    const data = {
      adType,
      product: localScriptDetails.productName,
      brand: localScriptDetails.brandName,
      duration: localScriptDetails.duration,
      industry: localScriptDetails.industry,
      targetAudience: localScriptDetails.targetAudience.map(tag => tag.label).join(', '),
      isRegenerate,
    };

    try {
      const { suggestions, xpEarned, creditAmount } = await getSuggestions(data);

      if (xpEarned && creditAmount) {
        showFeedback('xp', xpEarned, {
          mainColor: 'green.400',
          sparklesColor: 'yellow.400',
          size: 'sm',
          position: 'fixed',
          bottom: '10%',
          right: '5%',
        });

        showFeedback('credit', creditAmount, {
          mainColor: 'red.400',
          sparklesColor: 'orange.300',
          size: 'sm',
          position: 'fixed',
          bottom: '15%',
          right: '5%',
        });
      }

      setSuggestions(suggestions);
      setShowIdeas(true);
      updateContextScriptDetails();
      onOpen();
      return suggestions;
    } catch (error) {
      toast({
        title: 'Error',
        description: 'Failed to fetch script ideas. Please try again.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const handleGenerateSuggestions = async () => {
    await fetchSuggestions();
  };

  const handleRegeneration = async () => {
    await fetchSuggestions(true);
  };

  const handleOpenSuggestions = () => {
    setShowIdeas(true);
  };

  const handleBack = () => {
    navigate(-1);
  };

  const handleNext = () => {
    updateContextScriptDetails();
    if (projectDetails.mediaElements.includes('Voiceover')) {
      navigate('/select-voice');
    } else {
      navigate('/script-generating');
    }
  };

  const handleSelectIdea = idea => {
    setScriptIdea(idea);
    setShowIdeas(false);
  };

  return (
    <Box
      p={8}
      bg={bgColor}
      color={textColor}
      borderRadius="md"
      maxW="1200px"
      mx="auto"
      w="full"
      position="relative"
    >
      {/* Section Step */}
      <Stack direction={'row'} align={'center'} mb={2}>
        <Text fontSize={{ base: 'lg', md: '2xl' }}>Step 2</Text>
        <Icon as={FaArrowRight} w={6} h={6} color={iconColor} mx={4} />
        <Text fontSize={{ base: 'lg', md: '2xl' }}>Script Details</Text>
      </Stack>
      <Text mb={8}>Fill out details for your script.</Text>

      <SegmentedProgressBar currentStep={'2'} totalSteps={totalSteps} />

      <Stack direction={{base: 'column', md: 'row'}} spacing={10} align="stretch" mt={8}>
        
        <VStack flex={1} align="stretch" spacing={8}>

          <Flex direction={'column'} justify={'stretch'} align={'stretch'}>
            <Heading as={'h2'} mb={4} size={'md'}>
              Brand Name{' '}
              <Text as="span" color="red.500">
                *
              </Text>
              <Tooltip
                label="This helps us personalize the script for your brand."
                aria-label="Brand Name Tooltip"
              >
                <Icon
                  as={QuestionOutlineIcon}
                  ml={2}
                  cursor="pointer"
                  color={iconColor}
                />
              </Tooltip>
            </Heading>
            <Input
              aria-label="Brand Name"
              placeholder="Type your answer here..."
              value={localScriptDetails.brandName || ''}
              onChange={e => updateLocalScriptDetails('brandName', e.target.value)}
              required
              bg={inputBgColor}
              borderColor={inputBorderColor}
            />
          </Flex>

          <Box w="full">
            <Heading as={'h2'} mb={4} size={'md'}>
              Product Name / Services{' '}
              <Text as="span" fontSize="sm" fontWeight="normal">
                (optional)
              </Text>
              <Tooltip
                label="This helps us understand your product better."
                aria-label="Product Name Tooltip"
              >
                <Icon
                  as={QuestionOutlineIcon}
                  ml={2}
                  cursor="pointer"
                  color={iconColor}
                />
              </Tooltip>
            </Heading>
            <Input
              aria-label="Product Name"
              placeholder="Type your answer here..."
              value={localScriptDetails.productName || ''}
              onChange={e => updateLocalScriptDetails('productName', e.target.value)}
              bg={inputBgColor}
              borderColor={inputBorderColor}
            />
          </Box>

          <Box w="full">
            <Heading as={'h2'} mb={4} size={'md'}>
              Call To Action{' '}
              <Text as="span" fontSize="sm" fontWeight="normal">
                (optional)
              </Text>
              <Tooltip
                label="What action do you want your audience to take?"
                aria-label="Call To Action Tooltip"
              >
                <Icon
                  as={QuestionOutlineIcon}
                  ml={2}
                  cursor="pointer"
                  color={iconColor}
                />
              </Tooltip>
            </Heading>
            <Input
              aria-label="Call To Action"
              placeholder="Type your answer here..."
              value={localScriptDetails.callToAction || ''}
              onChange={e => updateLocalScriptDetails('callToAction', e.target.value)}
              bg={inputBgColor}
              borderColor={inputBorderColor}
            />
          </Box>

          <Box w="full">
            <Heading as={'h2'} mb={4} size={'md'}>
              Duration{' '}
              <Text as="span" color="red.500">
                *
              </Text>
              <Tooltip
                label="Select the duration of your script."
                aria-label="Duration Tooltip"
              >
                <Icon
                  as={QuestionOutlineIcon}
                  ml={2}
                  cursor="pointer"
                  color={iconColor}
                />
              </Tooltip>
            </Heading>
            <HStack spacing={{base: 2, md: 4}}>
              {['Any', '15s', '30s', '60s', '120s'].map(time => (
                <Button
                  key={time}
                  onClick={() => updateLocalScriptDetails('duration', time)}
                  variant={
                    localScriptDetails.duration === time ? 'bulletChecked' : 'bulletUnchecked'
                  }
                >
                  {time}
                </Button>
              ))}
            </HStack>
          </Box>
        </VStack>
        
        <Divider orientation={{base: 'horizontal', md: 'vertical'}} bg='gray.600' borderColor="gray.600" height={{base: '1px', md: '400px'}} />

        <VStack flex={1} align="start" spacing={8}>
          <Box w="full">
            <Heading as={'h2'} mb={4} size={'md'}>
              Industry{' '}
              <Text as="span" color="red.500">
                *
              </Text>
              <Tooltip
                label="Select the industry relevant to your script."
                aria-label="Industry Tooltip"
              >
                <Icon
                  as={QuestionOutlineIcon}
                  ml={2}
                  cursor="pointer"
                  color={iconColor}
                />
              </Tooltip>
            </Heading>

            <FormControl>
              <AutoComplete
                openOnFocus
                onSelectOption={option => {
                  updateLocalScriptDetails('industry', option.item.label);
                }}
                isLoading={loadingIndustries}
              >
                <AutoCompleteInput
                  variant="filled"
                  value={localScriptDetails.industry || ''}
                  onChange={(e) => updateLocalScriptDetails('industry', e.target.value)}
                  bg={inputBgColor}
                  borderColor={inputBorderColor}
                  borderWidth={1}
                  _hover={{ borderColor: inputBorderColor }}
                />
                <AutoCompleteList>
                  {industries.map((vertical, cid) => (
                    <AutoCompleteItem
                      key={`option-${cid}`}
                      value={vertical.label}
                      textTransform="capitalize"
                    >
                      {vertical.label}
                    </AutoCompleteItem>
                  ))}
                </AutoCompleteList>
              </AutoComplete>
              <FormHelperText>
                Type in the industry for your target audience.
              </FormHelperText>
            </FormControl>
          </Box>

          <Box w="full">
            <Heading as={'h2'} mb={4} size={'md'}>
              Target Audience{' '}
              <Text as="span" fontSize="sm" fontWeight="normal">
                (optional)
              </Text>
              <Tooltip
                label="Who is your ideal customer?"
                aria-label="Target Audience Tooltip"
              >
                <Icon
                  as={QuestionOutlineIcon}
                  ml={2}
                  cursor="pointer"
                  color={iconColor}
                />
              </Tooltip>
            </Heading>
            <TargetAudience
              selectedAudience={localScriptDetails.targetAudience}
              setSelectedAudience={handleSetSelectedAudience}
            />
          </Box>
        </VStack>
      </Stack>

      <Button
        onClick={suggestions.length > 0 ? handleOpenSuggestions : handleGenerateSuggestions}
        isLoading={loadingSuggestions}
        width="100%"
        mb={4}
        mt={8}
        bg={'gunmetal.400'}
        _hover={{ bg: 'gunmetal.500' }}
        color={buttonTextColor}
      >
        {suggestions.length > 0 ? `Select a Different Concept` : 'Generate Script Ideas'}
      </Button>

      {scriptIdea && (
        <Box mt={8}>
          <Text fontSize="xl" fontWeight="bold" mb={4}>
            Selected Concept:
          </Text>
          <Box bg={inputBgColor} p={4} borderRadius="md">
          <Text>{scriptIdea.title}</Text>
          <Text mt={2}>{scriptIdea.description}</Text>
          </Box>
        </Box>
      )}

      <ButtonGroup
          size={'lg'}
          spacing="6"
          isAttached
          borderRadius={'md'}
          mt={10}
        >
          <Button
            bg={buttonBgColor}
            _hover={{ bg: buttonHoverBgColor }}
            onClick={handleBack}
            color={buttonTextColor}
          >
            Back
          </Button>
          <Button
            bg={button2BgColor}
            _hover={{ bg: button2HoverBgColor }}
            onClick={handleNext}
            isDisabled={!scriptIdea}
            color={buttonTextColor}
          >
            Next
          </Button>
        </ButtonGroup>

      <Modal isOpen={showIdeas} onClose={() => setShowIdeas(false)} size="xl">
        <ModalOverlay />
        <ModalContent bg={modalBgColor}>
          <ModalCloseButton />
          <ModalBody>
            <ScriptIdeas
              isOpen={showIdeas}
              onClose={() => setShowIdeas(false)}
              suggestions={suggestions}
              onSelectIdea={handleSelectIdea}
              onRegenerate={handleRegeneration}
              loading={loadingSuggestions}
      />
      </ModalBody>
      </ModalContent>
      </Modal>
      <FeedbackComponent />
    </Box>
  );
};

export default ScriptDetails;
