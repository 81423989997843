import api from '../utils/api.utils';
import { handleResponse } from '../utils/app.utils';

export const startTriviaSession = async (quizId, userId, token) => {
  const response = await api.post(
    '/trivia/start',
    { quizId, userId },
    {
      customHeaders: { token },
    }
  );
  return handleResponse(response, 'Error starting trivia session');
};

export const answerTriviaQuestion = async (
  sessionId,
  questionId,
  answer,
  token
) => {
  const response = await api.post(
    '/trivia/answer',
    { sessionId, questionId, answer },
    {
      customHeaders: { token },
    }
  );
  return handleResponse(response, 'Error answering trivia question');
};

export const completeTriviaSession = async (sessionId, token) => {
  const response = await api.post(
    '/trivia/complete',
    { sessionId },
    {
      customHeaders: { token },
    }
  );
  return handleResponse(response, 'Error completing trivia session');
};

export const getCategories = async token => {
  const response = await api.get('/trivia/categories', {
    customHeaders: { token },
  });
  return handleResponse(response, 'Error fetching categories');
};

export const getQuizzes = async token => {
  const response = await api.get('/trivia/quizzes', {
    customHeaders: { token },
  });
  return handleResponse(response, 'Error fetching quizzes');
};

export const getQuizById = async (quizId, token) => {
  const response = await api.get(`/trivia/quizzes/${quizId}`, {
    customHeaders: { token },
  });
  return handleResponse(response, 'Error fetching quiz');
};
