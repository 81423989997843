import React, { useRef, useEffect } from 'react';
import {
  Box,
  Heading,
  Text,
  VStack,
  HStack,
  Icon,
  Link,
  Button,
  Image,
  Link as ChakraLink,
  useColorModeValue,
  Flex,
  IconButton,
} from '@chakra-ui/react';
import {
  FaInstagram,
  FaChevronRight,
  FaTiktok,
  FaYoutube,
  FaLinkedin,
  FaArrowLeft,
} from 'react-icons/fa';
import { Link as RouterLink } from 'react-router-dom';
import {
  motion,
  useScroll,
  useTransform,
  useSpring,
  useInView,
} from 'framer-motion';
import LearnMoreImage from '../assets/learn-more.jpg';
import LogoAnimation from '../components/LogoAnimation/LogoAnimation';

const MotionBox = motion(Box);
const MotionHeading = motion(Heading);
const MotionText = motion(Text);

const LearnMore = () => {
  const scrollRef = useRef(null);

  useEffect(() => {
    if (scrollRef.current) {
      scrollRef.current.style.scrollSnapType = 'y mandatory';
    }
  }, []);

  return (
    <Box width="100%" minHeight="100vh">
      <Flex direction={{ base: 'column', lg: 'row' }}>
        {/* Fixed Left Image on Large Screens */}
        <Box
          position={{ base: 'static', lg: 'fixed' }}
          width={{ base: '100%', lg: '35%' }}
          height={{ base: '300px', lg: '100vh' }}
          left={0}
          top={0}
          zIndex={-1}
        >
          <Image
            src={LearnMoreImage}
            alt="Background"
            objectFit="cover"
            width="100%"
            height="100%"
            priority
          />
          <Box
            position="absolute"
            top={0}
            left={0}
            width="100%"
            height="100%"
            bg="blackAlpha.600"
          />
        </Box>

        {/* Scrollable Right Content */}
        <Box
          width={{ base: '100%', lg: '65%' }}
          ml={{ base: 0, lg: '35%' }}
          minHeight="100vh"
          overflowY="auto"
          ref={scrollRef}
          px={{ base: 4, lg: 8 }}
          py={{ base: 8, lg: 16 }}
          bg={useColorModeValue('white', 'gunmetal.700')}
        >
          {/* Hero Section */}
          <VStack
            spacing={4}
            textAlign="left"
            align="left"
            color={useColorModeValue('black', 'white')}
            mb={8}
            ml={8}
          >
            <Box ml={-4}>
              <Link href="/">
                <LogoAnimation />
              </Link>
            </Box>
            <MotionHeading
              size="2xl"
              initial={{ opacity: 0, y: -50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5 }}
            >
              Learn More
            </MotionHeading>

            <MotionText
              fontSize="lg"
              color={useColorModeValue('gray.700', 'gray.200')}
              mb={4}
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: 0.2 }}
            >
              An AI-powered platform designed to create exceptional ads.
            </MotionText>
            <HStack mb={4}>
              <IconButton
                icon={<FaInstagram />}
                as={ChakraLink}
                href="https://www.instagram.com/epiclyai"
                transition="all 0.3s ease-out"
                target="_blank"
                colorScheme="teal"
                _hover={{
                  bg: useColorModeValue('gray.200', 'gunmetal.400'),
                }}
              />
              <IconButton
                icon={<FaTiktok />}
                as={ChakraLink}
                href="https://www.tiktok.com/@epicly.ai"
                transition="all 0.3s ease-out"
                target="_blank"
                colorScheme="teal"
                _hover={{
                  bg: useColorModeValue('gray.200', 'gunmetal.400'),
                }}
              />
              <IconButton
                icon={<FaYoutube />}
                as={ChakraLink}
                href="https://www.youtube.com/@epiclyai"
                target="_blank"
                transition="all 0.3s ease-out"
                colorScheme="teal"
                _hover={{
                  bg: useColorModeValue('gray.200', 'gunmetal.400'),
                }}
              />
              <IconButton
                icon={<FaLinkedin />}
                as={ChakraLink}
                href="https://www.linkedin.com/company/epicly-ai"
                target="_blank"
                transition="all 0.3s ease-out"
                colorScheme="teal"
                _hover={{
                  bg: useColorModeValue('gray.200', 'gunmetal.400'),
                }}
              />
            </HStack>
            <Button
              as={Link}
              href="/"
              variant="solid"
              leftIcon={<FaArrowLeft />}
              size="md"
              rounded="full"
              maxWidth="250px"
              bg="gunmetal.500"
              color="white"
              borderRadius="md"
              _hover={{ bg: 'gunmetal.600' }}
              transition="all 0.3s ease-out"
            >
              Back to Home
            </Button>
          </VStack>

          {/* Information Sections */}
          <VStack spacing={8} align="stretch">
            <StepSection
              stepNumber={1}
              title="Setup Your Project"
              content={[
                'The first step in creating exceptional ads is setting up your project. Start by identifying the type of Project you’re working on (Audio, Video). Then proceed to select the elements needed for your Project, such as Voiceover, Music and Video, while creating a name for your Project.',
                'Next, fill out your script details. This is where you’ll select the duration of the ad, as well as add the brand and product/service name. Additionally, you can select your Industry and Target Audience to cater the ad directly to your customers. These inputs are critical to crafting a message that resonates.',
                'You’ll also decide the tone and theme of your project during this step. Whether your aim is to educate, inspire, entertain, or persuade, these details will guide the creative direction. For branded content, input specific elements like keywords, slogans, or stylistic preferences to maintain consistency with your identity.',
                'Lastly, refine your objectives. Whether it’s boosting engagement, driving sales, or conveying information, clarifying your goals helps shape every subsequent step. This setup ensures the project is aligned and sets a clear path for crafting effective content.',
              ]}
            />
            <StepSection
              stepNumber={2}
              title="Craft Your Script"
              content={[
                'With your project details defined, the next step is to create your script—the core of your ad. A strong script is essential for delivering your message effectively, balancing clarity and creativity. Our platform simplifies this process, generating an initial draft based on your inputs from Step 1.',
                'The AI-generated draft gives you a solid starting point, but the real power lies in customization. Need more flexibility? Use the editing tools to tweak, expand, or refine the script. Want a specific tone or style? Adjust the phrasing to add humor, formality, or empathy. If you’re unsure about changes, built-in suggestions help enhance flow, engagement, and impact.',
                'Once the script captures your vision, it’s ready to move forward in the creative process, laying the groundwork for a polished final product.',
              ]}
            />
            <StepSection
              stepNumber={3}
              title="Generate Your Voiceover (Optional)"
              content={[
                'A voiceover can bring your script to life, adding warmth, authority, or relatability. This step lets you choose from 12 realistic voices, each designed for different tones and styles. Whether you need a conversational delivery or a professional narrative, there’s a voice to match your project.',
                'Preview each option to find the right voice for your ad. Voiceovers add personality, making your content engaging and memorable.',
                'Once you finalize your selection, the platform generates a professional-quality voiceover in minutes. This seamless process integrates smoothly with your script, setting the stage for a compelling presentation.',
              ]}
            />
            <StepSection
              stepNumber={4}
              title="Select Your Music (Optional)"
              content={[
                "Music enhances the emotional impact of your project, setting the tone and drawing viewers in. In this step, you can select from pre-made songs, or generate a custom track using the platform's AI capabilities. Choose from a variety of moods, such as energetic, reflective, or cinematic, to match the message.",
                'Ensure the song complements your script and voiceover perfectly. Background music ties your project together, creating a polished and professional finish. Whether you want subtle ambiance or a bold score, this step amplifies your content’s impact.',
              ]}
            />
            <StepSection
              stepNumber={5}
              title="Export"
              content={[
                'With your script, voiceover, and music complete, it’s time to export your project. Select from multiple formats optimized for your needs, whether for social media, presentations, or further editing. The platform ensures high-quality output, preserving the professionalism of your content.',
                'This final step is simple and efficient, enabling you to download a polished file that’s ready to share with your audience. Your project is now complete—creative, impactful, and tailored to achieve your goals.',
              ]}
            />
          </VStack>

          {/* Contact Support */}
          <Box mt={16} textAlign="center">
            <Text color={useColorModeValue('gray.500', 'gray.400')}>
              Need more help? Contact our support team.
            </Text>
            <ChakraLink
              as={RouterLink}
              to="mailto:support@epicly.ai"
              color={useColorModeValue('teal.500', 'teal.300')}
              fontWeight="semibold"
              _hover={{ textDecoration: 'underline' }}
              mt={2}
              display="inline-flex"
              alignItems="center"
            >
              Contact Support
              <Icon as={FaChevronRight} ml={1} width={4} height={4} />
            </ChakraLink>
          </Box>
        </Box>
      </Flex>
    </Box>
  );
};

// Reusable StepSection Component
const StepSection = ({ stepNumber, title, content }) => {
  const ref = useRef(null);
  const isInView = useInView(ref, { margin: '-50% 0px -50% 0px', once: true });

  const { scrollYProgress } = useScroll({
    target: ref,
    offset: ['start end', 'end start'],
  });

  const springConfig = { stiffness: 300, damping: 30, restDelta: 0.001 };
  const opacity = useSpring(
    useTransform(scrollYProgress, [0, 0.3, 0.7, 1], [0.3, 1, 1, 0.3]),
    springConfig
  );
  const scale = useSpring(
    useTransform(scrollYProgress, [0, 0.3, 0.7, 1], [0.8, 1, 1, 0.8]),
    springConfig
  );
  const y = useSpring(
    useTransform(scrollYProgress, [0, 0.3, 0.7, 1], [50, 0, 0, -50]),
    springConfig
  );

  // Move useColorModeValue to the top level of the component
  const bgColor = useColorModeValue('white', 'gunmetal.700');
  const iconBgColor = useColorModeValue('teal.500', 'beige.400');
  const textColor = useColorModeValue('gray.600', 'gray.300');

  return (
    <MotionBox
      ref={ref}
      initial={{ opacity: 0, y: 50 }}
      animate={{ opacity: isInView ? 1 : 0, y: isInView ? 0 : 50 }}
      transition={{ duration: 0.5 }}
      style={{ opacity, scale, y }}
      p={6}
      bg={bgColor}
      borderRadius="lg"
      boxShadow="lg"
      mt={8}
      scrollSnapAlign="start"
      maxWidth="750px"
    >
      <HStack spacing={4} mb={4}>
        <Box
          bg={iconBgColor}
          rounded="full"
          width={12}
          height={12}
          p={3}
          color="white"
          display="flex"
          alignItems="center"
          justifyContent="center"
          fontSize="lg"
          fontWeight="bold"
        >
          {stepNumber}
        </Box>
        <VStack align="start" spacing={1}>
          <Heading size="md">{title}</Heading>
        </VStack>
      </HStack>
      <VStack align="start" spacing={4}>
        {content.map((paragraph, index) => (
          <Text key={index} color={textColor} lineHeight="tall">
            {paragraph}
          </Text>
        ))}
      </VStack>
    </MotionBox>
  );
};

export default LearnMore;
