import { useState, useEffect, useCallback } from 'react';
import { getProjectById } from '../services/project.service';
import { useUserContext } from '../providers/UserProvider';

const useGetProjectById = projectId => {
  const [project, setProject] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const { user, token } = useUserContext();

  const fetchProject = useCallback(async () => {
    setIsLoading(true);
    setError(null);
    try {
      if (user && token && projectId) {
        const fetchedProject = await getProjectById(projectId, token);
        setProject(fetchedProject);
      }
    } catch (err) {
      console.error('Error fetching projects:', err);
      setError(err);
    } finally {
      setIsLoading(false);
    }
  }, [user, token, projectId]);

  useEffect(() => {
    fetchProject();
  }, [fetchProject]);

  return {
    project,
    isLoading,
    error,
    refetch: fetchProject,
  };
};

export default useGetProjectById;
