// src/pages/PrivacyPolicy.js

import React from 'react';
import { Box, Heading, Text, Container } from '@chakra-ui/react';

const PrivacyPolicy = () => {
  return (
    <Container maxW="container.lg" py={8}>
      <Heading as="h1" mb={4} textAlign="center">
        Privacy Policy
      </Heading>
      <Box bg="gray.50" p={6} borderRadius="md" boxShadow="md">
        <Text mb={4}>
          <strong>Last Updated: [Date]</strong>
        </Text>

        <Text mb={4}>
          Epicly AI, Inc. ("we", "us", "our") is committed to protecting your
          privacy. This Privacy Policy explains how we collect, use, disclose,
          and safeguard your information when you use our application and
          services. Please read this policy carefully to understand our views
          and practices regarding your personal data and how we will treat it.
        </Text>

        <Heading as="h2" size="lg" mt={8} mb={4}>
          1. Information We Collect
        </Heading>
        <Text mb={4}>
          We may collect and process the following data about you:
        </Text>
        <Text mb={4}>
          - <strong>Email Addresses:</strong> Provided during the signup
          process.
        </Text>
        <Text mb={4}>
          - <strong>Google Public Information:</strong> Information available
          from your Google account if you choose to sign up or log in using
          Google.
        </Text>
        <Text mb={4}>
          - <strong>Names and Addresses:</strong> If available and provided by
          the user.
        </Text>
        <Text mb={4}>
          - <strong>Payment Information:</strong> Payment details are securely
          handled by Stripe and are not stored on our application.
        </Text>

        <Heading as="h2" size="lg" mt={8} mb={4}>
          2. Use of Your Information
        </Heading>
        <Text mb={4}>
          We use the information we collect in the following ways:
        </Text>
        <Text mb={4}>
          - <strong>Account Management:</strong> To manage your account and
          provide you with customer support.
        </Text>
        <Text mb={4}>
          - <strong>Marketing:</strong> To send marketing communications to
          users and opted-in subscribers.
        </Text>

        <Heading as="h2" size="lg" mt={8} mb={4}>
          3. Data Sharing
        </Heading>
        <Text mb={4}>
          We do not sell your personal data. We may share information with:
        </Text>
        <Text mb={4}>
          - <strong>Stripe:</strong> For payment processing.
        </Text>
        <Text mb={4}>
          - <strong>Mailchimp:</strong> For managing our marketing
          communications.
        </Text>

        <Heading as="h2" size="lg" mt={8} mb={4}>
          4. Data Security
        </Heading>
        <Text mb={4}>
          We use administrative, technical, and physical security measures to
          protect your personal information. Access to user data is protected
          through authentication mechanisms.
        </Text>

        <Heading as="h2" size="lg" mt={8} mb={4}>
          5. User Rights
        </Heading>
        <Text mb={4}>You have the right to:</Text>
        <Text mb={4}>
          - <strong>Access:</strong> Request access to your personal data.
        </Text>
        <Text mb={4}>
          - <strong>Correction:</strong> Request correction of any inaccurate
          data.
        </Text>
        <Text mb={4}>
          - <strong>Deletion:</strong> Request deletion of your data.
        </Text>
        <Text mb={4}>
          These rights can be exercised through the account settings page on our
          application.
        </Text>

        <Heading as="h2" size="lg" mt={8} mb={4}>
          6. Cookies and Tracking
        </Heading>
        <Text mb={4}>
          We do not use cookies or other tracking technologies on our
          application.
        </Text>

        <Heading as="h2" size="lg" mt={8} mb={4}>
          7. Marketing Communications
        </Heading>
        <Text mb={4}>
          We may send you marketing communications if you have opted in. You can
          opt out at any time through the settings page on our application.
        </Text>

        <Heading as="h2" size="lg" mt={8} mb={4}>
          8. Children's Privacy
        </Heading>
        <Text mb={4}>
          Our services are intended for users aged 18 and older. We do not
          knowingly collect data from children under 18.
        </Text>

        <Heading as="h2" size="lg" mt={8} mb={4}>
          9. Changes to This Policy
        </Heading>
        <Text mb={4}>
          We may update this Privacy Policy from time to time. If we make
          significant changes, we will notify you through a popup modal window
          that you will need to accept to continue using our services.
        </Text>

        <Heading as="h2" size="lg" mt={8} mb={4}>
          10. Third-Party Services
        </Heading>
        <Text mb={4}>
          We integrate with the following third-party services:
        </Text>
        <Text mb={4}>
          - <strong>Stripe:</strong> For secure payment processing.
        </Text>

        <Heading as="h2" size="lg" mt={8} mb={4}>
          11. Contact Information
        </Heading>
        <Text mb={4}>
          If you have any questions or concerns about this Privacy Policy,
          please contact us at:
        </Text>
        <Text mb={4}>
          <strong>Epicly AI, Inc.</strong>
        </Text>
        <Text mb={4}>
          <strong>Email:</strong> contact@epicly.ai
        </Text>
      </Box>
    </Container>
  );
};

export default PrivacyPolicy;
