import React, { useState, useEffect } from 'react';
import {
  Box,
  Heading,
  Text,
  VStack,
  Spinner,
  Flex,
  IconButton,
} from '@chakra-ui/react';
import { CheckCircleIcon } from '@chakra-ui/icons';
import { motion } from 'framer-motion';
import useNotification from '../../hooks/useNotification';
import { useUserContext } from '../../providers/UserProvider';

const MotionIconButton = motion(IconButton);

const formatDateTime = dateString => {
  const options = {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
  };
  return new Date(dateString).toLocaleDateString(undefined, options);
};

const Notifications = ({ menuItemBgColor, menuItemTextColor }) => {
  const { user } = useUserContext();

  const { notifications, toggleReadStatus, isLoading, error } =
    useNotification(user?._id);
  
  const [clickedId, setClickedId] = useState(null);

  const handleClick = id => {
    setClickedId(id);
    toggleReadStatus(id);
    setTimeout(() => setClickedId(null), 300);
  };

  return (
    <VStack spacing={4} align="stretch">
      <Heading size="md" color={menuItemTextColor}>
        Notifications
      </Heading>
      {isLoading ? (
        <Spinner />
      ) : error ? (
        <Text color={menuItemTextColor}>
          Error fetching notifications: {error}
        </Text>
      ) : notifications.length > 0 ? (
        notifications.map(notification => (
          <Box
            key={notification._id}
            p={4}
            borderWidth="1px"
            borderRadius="md"
            // bg={notification.read ? menuItemBgColor : 'gunmetal.600'}
          >
            <Flex align="center" justify="space-between">
              <Box>
                <Text
                  color={notification.read ? menuItemTextColor : 'beige.100'}
                >
                  {notification.message}
                </Text>
                <Text fontSize="sm" color="gray.400">
                  {formatDateTime(notification.createdAt)}
                </Text>
              </Box>
              <MotionIconButton
                aria-label={
                  notification.read ? 'Mark as Unread' : 'Mark as Read'
                }
                icon={<CheckCircleIcon />}
                onClick={() => handleClick(notification._id)}
                size="md"
                variant="ghost"
                animate={
                  clickedId === notification._id ? { scale: [1, 0.7, 1] } : {}
                }
                transition={{ duration: 0.2 }}
                ml={4}
                bg={notification.read ? 'blackAlpha.300' : 'transparent'}
                color={notification.read ? 'green.300' : 'gray.500'}
                _hover={{ bg: 'blackAlpha.400', color: 'green.300' }}
                borderRadius="full"
              />
            </Flex>
          </Box>
        ))
      ) : (
        <Box p={4} borderWidth="1px" borderRadius="md" bg={menuItemBgColor}>
          <Text>No notifications.</Text>
        </Box>
      )}
    </VStack>
  );
};

export default Notifications;
