import React from 'react';
import { Flex, IconButton, Button, ButtonGroup } from '@chakra-ui/react';
import {
  FaChevronLeft,
  FaChevronRight,
  FaAngleDoubleLeft,
  FaAngleDoubleRight,
} from 'react-icons/fa';

const Pagination = ({
  currentPage,
  totalPages,
  onPageChange,
}) => {
  const renderPageButtons = () => {
    const pageNumbers = [];
    const showEllipsisStart = currentPage > 4;
    const showEllipsisEnd = currentPage < totalPages - 3;

    if (totalPages <= 7) {
      for (let i = 1; i <= totalPages; i++) {
        pageNumbers.push(i);
      }
    } else {
      pageNumbers.push(1);
      if (showEllipsisStart) pageNumbers.push('ellipsis');
      
      const startPage = Math.max(2, currentPage - 1);
      const endPage = Math.min(totalPages - 1, currentPage + 1);
      
      for (let i = startPage; i <= endPage; i++) {
        pageNumbers.push(i);
      }
      
      if (showEllipsisEnd) pageNumbers.push('ellipsis');
      pageNumbers.push(totalPages);
    }

    return pageNumbers.map((pageNumber, index) => 
      pageNumber === 'ellipsis' ? (
        <Button key={`ellipsis-${index}`} variant="ghost" isDisabled>
          ...
        </Button>
      ) : (
        <Button
          key={pageNumber}
          onClick={() => onPageChange(pageNumber)}
          variant={currentPage === pageNumber ? 'solid' : 'outline'}
          borderRadius="full"
          w="40px"
          h="40px"
        >
          {pageNumber}
        </Button>
      )
    );
  };

  return (
    <Flex justifyContent="space-between" alignItems="center" w="full" mt={4}>
      <ButtonGroup spacing={2}>
        <IconButton
          icon={<FaAngleDoubleLeft />}
          onClick={() => onPageChange(1)}
          isDisabled={currentPage === 1}
          aria-label="First page"
        />
        <IconButton
          icon={<FaChevronLeft />}
          onClick={() => onPageChange(currentPage - 1)}
          isDisabled={currentPage === 1}
          aria-label="Previous page"
        />
      </ButtonGroup>
      <Flex
        justifyContent="stretch"
        alignItems="stretch"
        flexWrap="wrap"
        gap={2}
      >
        {renderPageButtons()}
      </Flex>
      <ButtonGroup spacing={2}>
        <IconButton
          icon={<FaChevronRight />}
          onClick={() => onPageChange(currentPage + 1)}
          isDisabled={currentPage === totalPages}
          aria-label="Next page"
        />
        <IconButton
          icon={<FaAngleDoubleRight />}
          onClick={() => onPageChange(totalPages)}
          isDisabled={currentPage === totalPages}
          aria-label="Last page"
        />
      </ButtonGroup>
    </Flex>
  );
};

export default Pagination;
