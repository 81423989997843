import React, { useState, useMemo } from 'react';
import {
  Heading,
  Text,
  VStack,
  Spinner,
  Flex,
  Badge,
  HStack,
  IconButton,
  Tooltip,
  Switch,
  FormControl,
  FormLabel,
} from '@chakra-ui/react';
import { AnimatePresence } from 'framer-motion';
import { config, MotionFlex } from '../../config/config';
import PizzaProgress from '../PizzaProgress/PizzaProgress';
import { CloseIcon, CheckIcon } from '@chakra-ui/icons';
import { FaEnvelopeOpen } from 'react-icons/fa';

const UserReferrals = ({ referralHook }) => {
  const { referrals, isLoading, error, deleteExistingReferral } = referralHook;
  const [showOnlyPending, setShowOnlyPending] = useState(false);

  const referralLink =
    config.FRONTEND_URL + '/signup?referral=' + referralHook.referralCode;

  const handleDelete = referralId => {
    deleteExistingReferral(referralId);
  };

  const handleMailReferral = (referralEmail, referralLink) => {
    const subject = encodeURIComponent(
      'Join me on this new AI platform for digital ads'
    );
    const body = encodeURIComponent(`Hey there!

I've been using this great app called Epicly AI and I thought you could benefit from it too.  It makes it super easy to create digital ads. 
You can sign up using my referral link: ${referralLink}
    `);

    const mailtoLink = `mailto:${referralEmail}?subject=${subject}&body=${body}`;
    window.location.href = mailtoLink;
  };

  const handleSwitchChange = () => {
    setShowOnlyPending(!showOnlyPending);
  };

  const filteredReferrals = useMemo(() => {
    if (showOnlyPending) {
      return referrals.filter(referral => referral.status === 'pending');
    }
    return referrals.sort((a, b) => b.createdAt - a.createdAt);
  }, [referrals, showOnlyPending]);

  return (
    <VStack spacing={4} align="stretch" width="100%">
      <Heading size="md">Your Referrals</Heading>

      <FormControl display="flex" alignItems="center">
        <Switch
          id="show-pending"
          isChecked={showOnlyPending}
          onChange={handleSwitchChange}
          mr={3}
          size="lg"
          variant="muted"
        />
        <FormLabel htmlFor="show-pending" mb="0">
          Hide Completed Referrals
        </FormLabel>
      </FormControl>

      {isLoading ? (
        <Spinner />
      ) : error ? (
        <Text>Error fetching referrals: {error}</Text>
      ) : filteredReferrals.length > 0 ? (
        <AnimatePresence>
          {filteredReferrals.map((referral, index) => (
            <MotionFlex
              key={referral._id}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -20 }}
              transition={{ duration: 0.35, delay: index * 0.1 }}
              p={4}
              borderWidth="1px"
              borderRadius="md"
              justifyContent="stretch"
              alignItems="center"
              gap={4}
              mb={4}
            >
              <Flex flex={1} alignItems="center" justify={'center'} gap={8}>
                <PizzaProgress status={referral.status} />
                <VStack align="stretch" spacing={1} flex={1}>
                  <Text fontWeight="bold">{referral.referredUser.email}</Text>
                  <HStack>
                    {referral.status === 'completed' ? (
                      <>
                        <Badge colorScheme="green">Completed</Badge>
                        <Badge colorScheme="green">+100 Credits</Badge>
                        <Badge colorScheme="green">+250XP</Badge>
                      </>
                    ) : (
                      referral.status === 'pending' && (
                        <>
                          <Badge colorScheme="yellow">Pending</Badge>
                          <Badge colorScheme="green">+25XP</Badge>
                        </>
                      )
                    )}
                  </HStack>
                </VStack>
              </Flex>

              {referral.status === 'pending' && (
                <Tooltip label="Mail Referral">
                  <IconButton
                    size={'sm'}
                    icon={<FaEnvelopeOpen />}
                    aria-label="Mail Referral"
                    variant="ghost"
                    colorScheme="gray"
                    borderRadius="md"
                    onClick={() =>
                      handleMailReferral(
                        referral.referredUser.email,
                        referralLink
                      )
                    }
                  />
                </Tooltip>
              )}

              {referral.status === 'pending' ? (
                <IconButton
                  size={'sm'}
                  icon={<CloseIcon />}
                  onClick={() => handleDelete(referral._id)}
                  _hover={{
                    bg: 'red.500',
                    color: 'white',
                    transform: 'scale(1.1)',
                    transition: 'all 0.3s ease',
                  }}
                />
              ) : (
                <IconButton
                  size={'sm'}
                  icon={<CheckIcon />}
                  isDisabled
                  bg="green.500"
                  color="white"
                  _hover={{
                    bg: 'green.400',
                    color: 'white',
                  }}
                />
              )}
            </MotionFlex>
          ))}
        </AnimatePresence>
      ) : (
        <Text>No referrals to display.</Text>
      )}
    </VStack>
  );
};

export default UserReferrals;
