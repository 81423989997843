import React, { useState, useEffect } from 'react';
import { Flex, Container, Heading, Box, Button } from '@chakra-ui/react';
import { pageVariants, pageTransition, MotionFlex } from '../config/config';
import { useNavigate } from 'react-router-dom';
import { AnimatePresence } from 'framer-motion';
import { useUserContext } from '../providers/UserProvider';
import PricingTable from '../components/PricingTable/PricingTable';
import FFBetaModal from '../components/FFBetaModal/FFBetaModal';

const SubscriptionPricing = () => {
  const navigate = useNavigate();
  const { user } = useUserContext();
  const [userReference, setUserReference] = useState('');
  const [userEmail, setUserEmail] = useState('');

  useEffect(() => {
    const inviteCodeValidated = sessionStorage.getItem('inviteCodeValidated');

    if (user && inviteCodeValidated === 'true') {
      setUserReference(user._id);
      setUserEmail(user.email);
    } else {
      // Redirect to ProfileSetup if invite code is not validated
      navigate('/profile-setup');
      sessionStorage.removeItem('queryParams');
    }
  }, [user, navigate]);

  return (
    <Flex minHeight="100vh" align="center" justify="center">
      <Container maxW="container.xl" p={4} overflowX={'hidden'}>
        <AnimatePresence mode="wait">
          <MotionFlex
            initial="initial"
            animate="in"
            exit="out"
            variants={pageVariants}
            transition={pageTransition}
            flexDir={'column'}
            p={10}
            bg={'gunmetal.550'}
            rounded={'xl'}
            shadow={'2xl'}
            justifyContent={'center'}
          >
            <Heading mb="5" textAlign={'center'}>
              Subscription Pricing
            </Heading>
            <Box mb={5}>
              <PricingTable />
              <Button onClick={() => navigate(-1)}>Back</Button>
            </Box>
          </MotionFlex>
        </AnimatePresence>

        <FFBetaModal />
      </Container>
    </Flex>
  );
};

export default SubscriptionPricing;
