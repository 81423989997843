import React from 'react';
import { HStack, IconButton, VStack, Text } from '@chakra-ui/react';
import { FaFacebook, FaLinkedin, FaTwitter, FaWhatsapp, FaTelegram, FaReddit } from 'react-icons/fa';

const ShareToSocialMedia = ({ referralLink, referralMessage }) => {

  const shareUrl = encodeURIComponent(referralLink);
  const shareMessage = encodeURIComponent(referralMessage || `Check out this new AI platform for digital ads! Use my referral link to sign up:`);

  const shareFacebook = () => {
    window.open(`https://www.facebook.com/sharer/sharer.php?u=${shareUrl}`, '_blank');
  };

  const shareLinkedIn = () => {
    window.open(`https://www.linkedin.com/shareArticle?mini=true&url=${shareUrl}&title=${shareMessage}`, '_blank');
  };

  const shareTwitter = () => {
    window.open(`https://twitter.com/intent/tweet?text=${shareMessage}&url=${shareUrl}`, '_blank');
  };

  const shareWhatsApp = () => {
    window.open(`https://wa.me/?text=${shareMessage}%20${shareUrl}`, '_blank');
  };

  const shareTelegram = () => {
    window.open(`https://t.me/share/url?url=${shareUrl}&text=${shareMessage}`, '_blank');
  };

  const shareReddit = () => {
    window.open(`https://reddit.com/submit?url=${shareUrl}&title=${shareMessage}`, '_blank');
  };

  return (
    <VStack justifyContent="center" alignItems="flex-start" spacing={2}>
    <Text>Share to Social Media</Text>
      <HStack spacing={2}>
        <IconButton icon={<FaFacebook />} onClick={shareFacebook} aria-label="Share on Facebook" />
      <IconButton icon={<FaLinkedin />} onClick={shareLinkedIn} aria-label="Share on LinkedIn" />
      <IconButton icon={<FaTwitter />} onClick={shareTwitter} aria-label="Share on Twitter" />
      <IconButton icon={<FaWhatsapp />} onClick={shareWhatsApp} aria-label="Share on WhatsApp" />
      <IconButton icon={<FaTelegram />} onClick={shareTelegram} aria-label="Share on Telegram" />
      <IconButton icon={<FaReddit />} onClick={shareReddit} aria-label="Share on Reddit" />
    </HStack>
    </VStack>
  );
};

export default ShareToSocialMedia;
