import React, { useState } from 'react';
import {
  Container,
  Heading,
  Stack,
  useColorModeValue,
  Skeleton,
  useDisclosure,
} from '@chakra-ui/react';
import { AnimatePresence } from 'framer-motion';
import { MotionBox, containerVariants } from '../config/config';

import useRecentProjects from '../hooks/useRecentProjects';
import useDownloadScript from '../hooks/useDownloadScript';
import useDeleteProject from '../hooks/useDeleteProject';
import useDeleteScript from '../hooks/useDeleteScript';

import ProjectCard from '../components/ProjectCard/ProjectCard';
import DeleteItemModal from '../components/DeleteItemModal/DeleteItemModal';
import ExportScriptModal from '../components/ExportScriptModal/ExportScriptModal';

const RecentProjects = () => {
  const bgColor = useColorModeValue('beige.100', 'gunmetal.700');
  const borderColor = useColorModeValue('beige.400', 'gunmetal.550');

  const { recentProjects, loading: projectsLoading, refetch } = useRecentProjects();
  const { newDownloadData } = useDownloadScript();

  const [itemToDelete, setItemToDelete] = useState(null);
  const [deleteItemType, setDeleteItemType] = useState('');
  const [selectedScriptId, setSelectedScriptId] = useState(null);
  const [selectedScriptTitle, setSelectedScriptTitle] = useState(null);

  const deleteProject = useDeleteProject();
  const deleteScript = useDeleteScript();

  const {
    isOpen: isDeleteOpen,
    onOpen: onDeleteOpen,
    onClose: onDeleteClose,
  } = useDisclosure();
  const {
    isOpen: isExportOpen,
    onOpen: onExportOpen,
    onClose: onExportClose,
  } = useDisclosure();

  const handleDeleteClick = (item, type) => {
    setItemToDelete(item);
    setDeleteItemType(type);
    onDeleteOpen();
  };

  const handleDeleteConfirm = async () => {
    if (itemToDelete) {
      if (deleteItemType === 'project') {
        await deleteProject(itemToDelete._id);
      } else if (deleteItemType === 'script') {
        await deleteScript(itemToDelete._id);
      }
      refetch();
      onDeleteClose();
    }
  };

  const handleDeleteScript = async (scriptId) => {
    await deleteScript(scriptId);
    refetch();
  };

  const handleDownloadScript = (scriptId, scriptTitle) => {
    setSelectedScriptId(scriptId);
    setSelectedScriptTitle(scriptTitle);
    onExportOpen();
  };

  const handleExport = async (type) => {
    if (selectedScriptId && selectedScriptTitle) {
      await newDownloadData(selectedScriptId, type, selectedScriptTitle);
    }
  };

  if (projectsLoading) {
    return (
      <Container maxW="container.xl" p={8}>
        <Stack spacing={8}>
          <Skeleton height="200px" />
        </Stack>
      </Container>
    );
  }

  return (
    <Container
      maxW="container.xl"
      p={8}
      bg={bgColor}
      borderRadius="lg"
      border="1px solid"
      borderColor={borderColor}
    >
      <Heading as="h1" mb={4}>
        Recent Projects
      </Heading>
      <AnimatePresence>
        <MotionBox variants={containerVariants} initial="hidden" animate="show">
          <Stack spacing={6}>
            {recentProjects.map((project) => (
              <ProjectCard
                key={project._id}
                project={project}
                scripts={project.scripts || []}
                handleDeleteClick={handleDeleteClick}
                handleDownloadScript={handleDownloadScript}
                setSelectedScriptId={setSelectedScriptId}
                setSelectedScriptTitle={setSelectedScriptTitle}
                handleDeleteScript={handleDeleteScript}
              />
            ))}
          </Stack>
        </MotionBox>
      </AnimatePresence>

      {/* Modals */}
      <DeleteItemModal
        isOpen={isDeleteOpen}
        onClose={onDeleteClose}
        itemName={itemToDelete?.title || ''}
        itemType={deleteItemType}
        onDelete={handleDeleteConfirm}
      />

      <ExportScriptModal
        isOpen={isExportOpen}
        onClose={onExportClose}
        onExport={handleExport}
        mediaType="Script"
      />
    </Container>
  );
};

export default RecentProjects;
