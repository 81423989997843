import { useCallback } from 'react';
import { useUserContext } from '../providers/UserProvider';
import { useToast } from '@chakra-ui/react';
import { downloadScript } from '../services/script.service';
import { formatDate, formatFileName } from '../utils/app.utils';

const useDownloadScript = () => {
  const { token } = useUserContext();
  const toast = useToast();

  const newDownloadData = useCallback(
    async (scriptId, type, scriptTitle) => {
      try {
        const response = await downloadScript(scriptId, type, token);

        const blob = new Blob([response.data], {
          type: response.headers['content-type'],
        });
        const url = window.URL.createObjectURL(blob);

        const currentDate = formatDate(new Date());
        const fileName = formatFileName(
          scriptTitle || 'script',
          type,
          currentDate
        );

        // Create a temporary link element
        const a = document.createElement('a');
        a.href = url;
        a.download = fileName;
        document.body.appendChild(a);
        a.click();

        // Cleanup
        window.URL.revokeObjectURL(url);
        document.body.removeChild(a);

        toast({
          title: 'Download initiated.',
          description: `Your ${type.toUpperCase()} file is downloading.`,
          status: 'success',
          duration: 9000,
          isClosable: true,
        });
      } catch (error) {
        console.error('Download error:', error);
        toast({
          title: 'An error occurred.',
          description: `Unable to download script. ${error.message}`,
          status: 'error',
          duration: 9000,
          isClosable: true,
        });
      }
    },
    [token, toast]
  );

  return { newDownloadData };
};

export default useDownloadScript;
