import { motion } from 'framer-motion';
import {
  Button,
  Flex,
  Box,
  IconButton,
  HStack,
  VStack,
} from '@chakra-ui/react';

export const INVITE_CODE_ENABLED = true;

const isProduction = process.env.NODE_ENV === 'production';

export const PROVIDER_CONFIG = {
  domain: isProduction
    ? process.env.REACT_APP_AUTH0_DOMAIN_PROD
    : process.env.REACT_APP_AUTH0_DOMAIN_DEV,
  clientId: isProduction
    ? process.env.REACT_APP_AUTH0_CLIENT_ID_PROD
    : process.env.REACT_APP_AUTH0_CLIENT_ID_DEV,
  authorizationParams: {
    redirect_uri: window.location.origin + '/callback',
    audience: isProduction
      ? process.env.REACT_APP_AUTH0_AUDIENCE_PROD
      : process.env.REACT_APP_AUTH0_AUDIENCE_DEV,
    scope: 'openid profile email offline_access',
  },
};

export const config = {
  FRONTEND_URL: process.env.REACT_APP_FRONTEND_URL,

  BACKEND_URL:
    process.env.NODE_ENV === 'production'
      ? process.env.REACT_APP_BACKEND_URL_PROD
      : process.env.REACT_APP_BACKEND_URL_DEV,

  STRIPE_PUBLIC_KEY: process.env.REACT_APP_STRIPE_PUBLIC_KEY,

  STRIPE_RETURN_URL:
    process.env.NODE_ENV === 'production'
      ? process.env.REACT_APP_STRIPE_RETURN_URL_PROD
      : process.env.REACT_APP_STRIPE_RETURN_URL_DEV,

  AUTH0_DOMAIN:
    process.env.NODE_ENV === 'production'
      ? process.env.REACT_APP_AUTH0_DOMAIN_PROD
      : process.env.REACT_APP_AUTH0_DOMAIN_DEV,

  AUTH0_CLIENT_ID:
    process.env.NODE_ENV === 'production'
      ? process.env.REACT_APP_AUTH0_CLIENT_ID_PROD
      : process.env.REACT_APP_AUTH0_CLIENT_ID_DEV,

  AUTH0_AUDIENCE:
    process.env.NODE_ENV === 'production'
      ? process.env.REACT_APP_AUTH0_AUDIENCE_PROD
      : process.env.REACT_APP_AUTH0_AUDIENCE_DEV,

  STRIPE_CUSTOMER_PORTAL_URL:
    process.env.NODE_ENV === 'production'
      ? process.env.REACT_APP_STRIPE_CUSTOMER_PORTAL_URL_PROD
      : process.env.REACT_APP_STRIPE_CUSTOMER_PORTAL_URL_DEV,
};

export const STRIPE_ENABLED = process.env.REACT_APP_STRIPE_ENABLED === 'true';
export const INTERCOM_APP_ID = process.env.REACT_APP_INTERCOM_APP_ID;

export const FPS = 24;
export const SPEED = 135;

export const LEVEL_REWARDS = [
  { level: 5, reward: 'Off to a good start', creditReward: 50 },
  { level: 10, reward: 'Exclusive Emote', creditReward: 100 },
  { level: 15, reward: 'Profile Background', creditReward: 150 },
  { level: 25, reward: 'OG Badge', creditReward: 200 },
  { level: 50, reward: 'OG Frame', creditReward: 500 },
  { level: 75, reward: 'OG Emote', creditReward: 750 },
  { level: 100, reward: 'OG Title', creditReward: 1000 },
];

export const animationSlideAndFade = {
  initial: { opacity: 0, x: 100 },
  animate: { opacity: 1, x: 0 },
  exit: { opacity: 0, x: -100 },
  transition: { duration: 0.5, ease: [0.68, -0.55, 0.27, 1.55] },
};

export const pageVariants = {
  initial: { opacity: 0, x: '100vw' },
  in: { opacity: 1, x: 0 },
  out: { opacity: 0, x: '-100vw' },
};

export const pageTransition = {
  type: 'tween',
  ease: [0.23, 1, 0.32, 1],
  duration: 0.5,
};

export const pulseAnimation = {
  scale: [1, 1.1, 1],
  transition: { repeat: Infinity, duration: 0.8, repeatType: 'reverse' },
};

export const saveChangesVariants = {
  initial: {
    boxShadow: '0 0 0px rgba(90, 199, 217, 0)',
    scale: 1,
  },
  animate: {
    boxShadow: '0 0 8px 2px rgba(90, 199, 217, .5)',
    scale: 1.05,
  },
};

// export const containerVariants = {
//   hidden: { opacity: 0 },
//   visible: {
//     opacity: 1,
//     transition: {
//       staggerChildren: 0.1
//     }
//   }
// };

// export const itemVariants = {
//   hidden: { y: 20, opacity: 0 },
//   visible: {
//     y: 0,
//     opacity: 1,
//     transition: {
//       type: 'spring',
//       stiffness: 100
//     }
//   }
// };

export const containerVariants = {
  hidden: { opacity: 0 },
  show: {
    opacity: 1,
    transition: {
      staggerChildren: 0.1,
      delayChildren: 0.3,
    },
  },
};

export const itemVariants = {
  hidden: { opacity: 0, y: 20 },
  show: i => ({
    opacity: 1,
    y: 0,
    transition: {
      delay: i * 0.1,
      type: 'spring',
      stiffness: 100,
      damping: 12,
    },
  }),
};

export const isOverCredits = (totalCredits, planCredits) => {
  return totalCredits > planCredits;
};
export const hasLowCredits = totalCredits => {
  return totalCredits < 50;
};

export const MotionButton = motion(Button);
export const MotionFlex = motion(Flex);
export const MotionBox = motion(Box);
export const MotionIconButton = motion(IconButton);
export const MotionHStack = motion(HStack);
export const MotionVStack = motion(VStack);

export const MUSIC_GENRES = [
  {
    value: 'soft rock',
    label: 'Soft Rock',
  },
  {
    value: 'ambient',
    label: 'Ambient',
  },
  {
    value: 'funk',
    label: 'Funk',
  },
  {
    value: 'punk',
    label: 'Punk',
  },
  {
    value: 'salsa',
    label: 'Salsa',
  },
  {
    value: 'jazz',
    label: 'Jazz',
  },
  {
    value: 'electronica',
    label: 'Electronica',
  },
];

export const MUSIC_MOODS = [
  {
    value: 'groovy',
    label: 'Groovy',
  },
  {
    value: 'corporate',
    label: 'Corporate',
  },
  {
    value: 'youthful',
    label: 'Youthful',
  },
  {
    value: 'fun',
    label: 'Fun',
  },
  {
    value: 'calm',
    label: 'Calm',
  },
  {
    value: 'modern',
    label: 'Modern',
  },
  {
    value: 'acoustic',
    label: 'Acoustic',
  },
  {
    value: 'motivational',
    label: 'Motivational',
  },
  {
    value: 'tranquil',
    label: 'Tranquil',
  },
  {
    value: 'uplifting',
    label: 'Uplifting',
  },
  {
    value: 'urban',
    label: 'Urban',
  },
];

export const MUSIC_TONES = [
  {
    value: 'upbeat',
    label: 'Upbeat',
  },
  {
    value: 'professional',
    label: 'Professional',
  },
  {
    value: 'acoustic',
    label: 'Acoustic',
  },
  {
    value: 'ambient',
    label: 'Ambient',
  },
  {
    value: 'corporate',
    label: 'Corporate',
  },
];
