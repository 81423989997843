import { useState } from 'react';
import {
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverArrow,
  PopoverHeader,
  PopoverCloseButton,
  PopoverBody,
  Button,
  Icon,
  Flex,
  useColorModeValue,
} from '@chakra-ui/react';
import { CheckIcon } from '@chakra-ui/icons';

const durations = [
  { label: '15 seconds', value: '15s' },
  { label: '30 seconds', value: '30s' },
  { label: '45 seconds', value: '45s' },
  { label: '60 seconds', value: '60s' },
];

const DurationPopover = ({ targetDuration, onDurationChange }) => {
  const [selectedDuration, setSelectedDuration] = useState(targetDuration);

  const popoverBgColor = useColorModeValue('beige.300', 'gunmetal.600');
  const popoverBorderColor = useColorModeValue('gray.200', 'gunmetal.500');

  const handleDurationChange = (duration) => {
    setSelectedDuration(duration);
    onDurationChange(duration);
  };

  return (
    <Popover>
      <PopoverTrigger>
        <Button
          variant="outline"
          borderRadius="full"
          height="40px"
          width="40px"
        >
          <Flex direction="column" align="center" justify="center">
            <span fontSize="lg" fontWeight="medium">
              {selectedDuration}
            </span>
          </Flex>
        </Button>
      </PopoverTrigger>
      <PopoverContent width="250px" p={0} bg={popoverBgColor} borderColor={popoverBorderColor} shadow="dark-lg">
        <PopoverArrow bg={popoverBgColor} />
        <PopoverCloseButton />
        <PopoverHeader fontWeight="medium">Choose a new duration</PopoverHeader>
        <PopoverBody padding="0">
          <Flex direction="column" gap="2" padding="2">
            {durations.map(duration => (
              <Button
                key={duration.value}
                variant={
                  selectedDuration === duration.value ? 'solid' : 'ghost'
                }
                width="100%"
                justifyContent="space-between"
                onClick={() => handleDurationChange(duration.value)}
                rightIcon={
                  selectedDuration === duration.value ? (
                    <Icon as={CheckIcon} boxSize={4} />
                  ) : null
                }
              >
                {duration.label}
              </Button>
            ))}
          </Flex>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
};

export default DurationPopover;
